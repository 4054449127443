@font-face {
  font-family: 'CFNotche-Bold';
  src: url('../public/TTF/CFNotche-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

#root {
  height: 100vh;
}
html{
  height: -webkit-fill-available; /* For WebKit-based browsers */
  height: -moz-available;         /* For Firefox */
  height: fill-available; 
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'CFNotche-Bold' 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: -webkit-fill-available;
}

::-webkit-scrollbar{
  width: 0.3em;
}
::-webkit-scrollbar-track{
background-color: #ffffff;
scroll-behavior: smooth;
}
::-webkit-scrollbar-thumb{
  background-color: rgb(247, 134, 48);
  }
  ::-webkit-scrollbar-thumb:hover{
    background-color: #dd6312;
    }

code {
  font-family:'CFNotche-Bold', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.main {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  font-family: 'CFNotche-Bold','Source Sans 3', sans-serif;
}
a{
  text-decoration: none;
  color: #fff;
}
.backvideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.Navbarmaincontainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.5rem;
  z-index: 1600;
}
.footer-container{
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 14000;
}
.main-container{
  position: absolute;
  color: beige;
  width: 100%;
  left: 0;
  top: 0rem;
}
.boxtopmargin{
   margin-top: 2vh;
}
/*transition css*/
.Slidetransition-in{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0f0f0f;
  transform-origin: bottom;
}
.Slidetransition-out{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0f0f0f;
  transform-origin: top;
}

#homescrollerpara{
  width: 100%;
  height:13vmin;
  overflow: auto;
  margin-bottom: 2vh;
  margin-top: 1.2vh;
  outline: none;
}
#homescrollerpara::-webkit-scrollbar {
  width: 0em;
}

#homescrollerpara::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@keyframes logoSliderLeft {
  0% {
    transform: translateX(100%); /* Start off-screen to the right */
  }
  100% {
    transform: translateX(-100%); /* End off-screen to the left */
  }
}
/* .logo-Slider-container{
  background-color: #dd6312;
  overflow: hidden;
  padding: 20px 0;
  width: 100%;
} */
/* .logo-Slider-container {
  position: relative;
  width: 100%;
  height: 150px;
 overflow: hidden;
} */
/* .logo-Slider{
  display: flex;
  position: relative;
  width: fit-content;
} */
/* .logo-Slider{
  white-space: nowrap;
  animation: 5s logoSliderHome infinite linear;
} */
.logo-Slider-item{
  width: 200px;
  height: 100px;
  background-color: aqua;
  border-radius: 10px;
  animation: logoSliderLeft 40s infinite linear;
  margin: 0 10px;
}
.logo-Slider-item img{
  width: 100%;
  height: 100%;
}

.logo-Slider {
  margin-top: 2rem;
  width: 100%;
  max-width: 1536px;
  margin-inline: auto;
  height: 100px;
  position: relative;
  overflow: hidden;
}

@keyframes scrollLeft {
  to {
    left: -200px;
  }
}

@keyframes scrollRight {
  to {
    right: -200px;
  }
}

.itemLeft,
.itemRight {
  width: 200px;
  height: 50px;
  background-color: #e11d48;
  border-radius: 6px;
  position:absolute;
  animation-timing-function: linear;
  animation-duration: 40s;
  animation-iteration-count: infinite;
}

.itemLeft {
  left: 100%;
  animation-name: scrollLeft;
}
.itemLeft img{
  width: 100%;
  height: 50px;
}

.itemRight {
  right: max(calc(200px * 8), calc(100% + 200px));
  animation-name: scrollRight;
}

.item1{
  animation-delay: calc(40s / 14 * (14 - 1) * - 1);
}
.item2{
  animation-delay: calc(40s / 14 * (14 - 2) * - 1);
}
.item3{
  animation-delay: calc(40s / 14 * (14 - 3) * - 1);
}
.item4{
  animation-delay: calc(40s / 14 * (14 - 4) * - 1);
}
.item5{
  animation-delay: calc(40s / 14 * (14 - 5) * - 1);
}
.item6{
  animation-delay: calc(40s / 14 * (14 - 6) * - 1);
}
.item7 {
  animation-delay: calc(40s / 14 * (14 - 7) * - 1);
}
.item8{
  animation-delay: calc(40s / 14 * (14 - 8) * - 1);
}
.item9{
  animation-delay: calc(40s / 14 * (14 - 9) * - 1);
}
.item10{
  animation-delay: calc(40s / 14 * (14 - 10) * - 1);
}
.item11{
  animation-delay: calc(40s / 14 * (14 - 11) * - 1);
}
.item12{
  animation-delay: calc(40s / 14 * (14 - 12) * - 1);
}
.item13{
  animation-delay: calc(40s / 14 * (14 - 13) * - 1);
}
.item14{
  animation-delay: calc(40s / 14 * (14 - 14) * - 1);
}
/* .logo-Slider img{
  height: 50px;
  margin: 0 15px;
} */

@media screen and (max-width:700px) {
  .Navbarmaincontainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3.75rem;
  }
}

.desktopview{
  display: block;
}
.mobileview{
  display: none;
}

@media (max-width:930px) and (min-width:830px){
  /* .boxtopmargin {
    margin-top: 4rem;
} */
}

@media  (max-width:775px) and (min-width:676px) {
  .mobileview{
    display: none;
  }
  .boxtopmargin {
    margin-top: 4rem;
}
}
@media (max-width:1399px) and (min-height:716px){
  #homescrollerpara{
    width: 100%;
    height:15.5vmin;
    overflow: auto;
    margin-bottom: 2vh;
    margin-top: 2vh;
  }
}
@media (max-width:1399px) and (min-height:895px){
  #homescrollerpara{
    width: 100%;
    height:50vh;
    overflow: auto;
    margin-bottom: 2vh;
    margin-top: 2vh;
  }
  .largecimg{
    width: 10vw !important;
    height: 12.5vh !important;
  }
}

@media (max-width:475px) and (min-width:200px) {
  .main {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow:hidden;
    font-family: 'CFNotche-Bold','Source Sans 3', sans-serif;
  }
}
