.custom-context-menu {
  position: fixed;
  background: #333333;
  border-radius: 8px;
  /* padding: 8px 0; */
  min-width: 180px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  animation: fadeIn 0.2s ease;
  border: 1px solid rgba(255, 102, 0, 0.2);
  max-height: 80vh;
  /* overflow-y: auto; */
  overflow-x: visible;
}

.menu-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #fff;
  font-family: "CFNotche-Bold";
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  white-space: nowrap;
  position: relative;
  /* z-index: 9999; */
}

.menu-item:hover {
  background: rgba(255, 102, 0, 0.1);
  color: #ff6600;
  padding-left: 25px;
}

.menu-item:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* .menu-item::before {
  content: "";
  position: absolute;
  left: 0;
  width: 3px;
  height: 0;
  background: #ff6600;
  transition: height 0.2s ease;
} */

.menu-item:hover::before {
  /* height: 100%; */
}

/* Scrollbar styling */
.custom-context-menu::-webkit-scrollbar {
  width: 4px;
}

.custom-context-menu::-webkit-scrollbar-track {
  background: transparent;
}

.custom-context-menu::-webkit-scrollbar-thumb {
  background: #ff6600;
  border-radius: 2px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .custom-context-menu {
    min-width: 160px;
    max-height: 70vh;
  }

  .menu-item {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

.submenu {
  position: absolute;
  left: 100%;
  top: 0;
  background: #333333;
  border-radius: 8px;
  min-width: 200px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 102, 0, 0.2);
  display: none;
  animation: fadeIn 0.2s ease;
  z-index: 10000;

  height: 45vh;
    overflow-y: auto;
}

.menu-item-with-submenu {
  position: relative;
}

.menu-item-with-submenu:hover .submenu {
  display: block;
}

.submenu .menu-item {
  border-radius: 0;
}

.submenu .menu-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.submenu .menu-item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.menu-item-with-submenu::after {
  content: "›";
  margin-left: 8px;
  font-size: 1.2em;
}


@media screen and (max-width: 550px) {
  .submenu{
    height: 40vh;
  }

  .custom-context-menu{
    /* height: 65vh; */
  }
}
