.portfolioboxcontainer {
  padding: 0.5rem auto;
  width: 100%;
  height: 19.5rem;
  overflow-y: auto;
  outline: none;
}

.portfoliocontainer {
  width: 100%;
  height: 300px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1.5rem;
}

.portfolioboxcontainer::-webkit-scrollbar {
  width: 0em;
}

.portfolioboxcontainer::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.portfoliocard {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  margin: auto;
}
.portfoliocard h2 {
  font-size: 1.5rem;
  font-weight: 800;
  padding: 0.6rem 1rem;
  text-align: center;
}
.portfoliocardcontent {
  width: 100%;
  height: 300px;
}
.portfolioimagecard {
  width: 100%;
  height: 18.6rem;
  border-radius: 12px 12px 0px 0px;
  position: relative;
}
.portfolioimagecard img {
  width: 100%;
  height: 100%;
  object-position: center center; /* Adjust as needed */
}
.portfolio-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: none;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.portfolio-gallery-item-card .portfolio-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: none;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.portfolio-gallery-item-card:hover .portfolio-overlay {
  display: flex;
}

/* ----------- */
.portfolio-gallery-item-card {
  position: relative;
  display: inline-block;
}

.portfolio-extra-image {
  position: absolute;
  top: -10px !important;
  left: 10px;
  /* width: 90%;  */
  z-index: -1; /* Behind the main image */
  transform: rotate(3deg) !important;
  /* transform: translate3d(calc(7.25% - 240px), 0px, -100px) rotateZ(2deg) scale(1) !important; */
  opacity: 0.6; /* Slight transparency for better visual */
  /* pointer-events: none;  */

  width: 100%;
  height: 100%;
  object-fit: contain;
  /* cursor: pointer; */
}

.portfolio-extra-image:hover {
  background-color: black !important;
}

.portfolio-extra-image:nth-child(2) {
  top: 20px;
  left: 20px;
  /* transform: translate3d(calc(7.25% - 240px), 0px, -100px) rotateZ(2deg) scale(1) !important; */
  transform: rotate(3deg) !important;
}

.portfolio-extra-image:nth-child(2):hover {
  background-color: black !important;
}

/* ----------- */

.portfolioimagecard:hover .portfolio-overlay {
  display: flex;
}
.portfoliocardcontent:hover .portfolio-zoom-icon,
.portfolio-gallery-item-card:hover .portfolio-zoom-icon {
  display: block;
  background-color: rgb(104, 85, 85);
}
.portfolio-zoom-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
.portfoliocontainer .swiper {
  width: 240px;
  height: 270px;
}

.portfoliocontainer .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

#backbtnport {
  background-color: #3333334d;
  padding: 0.4rem;
  border-radius: 50%;
  transform: rotate(180deg);
}
.closebtn {
  border: none;
  color: rgb(255, 0, 0);
  background-color: transparent;
  font-size: 1.5rem;
  text-transform: capitalize;
}
.closebtn:hover {
  color: black;
}
/* .modal {
position: fixed;
top: 0% !important;
left: 0% !important;
/* transform: translate(-50%, -50%); */
/* z-index: 13480 !important;
display: none; /* Hide the modal by default */
/*  max-width: 100%; */
/* max-height: 95vh; /* Adjust as needed */
/* overflow-x: hidden;
overflow-y: hidden;
outline: 0;  */
/* } */
/* .modal .modal-dialog{
max-width: 100vw;
max-height: 100vh;
width: 100vw;
height: 100vh;
}

.modal .modal-content{
background-color: transparent;
}  */
/* Default background content */
.page-content {
  transition: filter 0.3s ease; /* Smooth transition for blur */
}

/* Blur effect when modal is open */
.blur-background {
  filter: blur(8px); /* Adjust blur intensity */
}

/* Ensure modal stays sharp */
.modal {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  z-index: 13480 !important;
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
}

.modal .modal-dialog {
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
}

.modal .modal-content {
  background-color: transparent;
}

/*portfolio detail*/
.portfolio-gallery {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(16rem, 1fr)
  ); /* Adjusts based on available space */
  gap: 16px;
  justify-content: center; /* Center items horizontally */
  width: 90%;
  margin-inline: auto;
}

.portfolio-gallery-item-card {
  width: 16rem; /* Fixed width */
  height: 16rem; /* Fixed height */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  position: relative;
}

.portfolio-gallery-item-card img {
  width: 100%; /* Full width of the card */
  height: 100%; /* Full height of the card */
  object-fit: contain; /* Keep image aspect ratio within the card */
  display: block;
  border-radius: 8px; /* Rounded corners */
}
.portfoliocard h2 a:hover {
  color: #ff6600;
}

#mobile-heading-potfolio-detail {
  display: none;
}

#desktop-heading-potfolio-detail {
  display: flex;
}

.mobileheading {
  display: none;
}
.desktopheading {
  display: block;
}

@media screen and (min-width: 4591px) {
  #mobile-heading-potfolio-detail {
    display: none;
  }
  .portfolioboxcontainer {
    height: 114rem;
  }
  .portfoliocontainer {
    width: 100%;
    height: 320px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 1.5rem;
  }
}
@media (max-width: 4590px) and (min-width: 2591px) {
  .portfolioboxcontainer {
    height: 54rem;
  }
}
@media (max-width: 2590px) and (min-width: 1989px) {
  .portfolioboxcontainer {
    height: 36rem;
  }
  .portfoliocontainer {
    grid-template-columns: auto auto auto auto;
  }
}
@media (max-width: 1990px) and (min-width: 1789px) {
  .portfolioboxcontainer {
    height: 30rem;
  }
  .portfoliocontainer {
    grid-template-columns: auto auto auto auto;
  }
}
@media (max-width: 1790px) and (min-width: 1589px) {
  .portfolioboxcontainer {
    height: 26.5rem;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) and (max-height: 759px) and (min-height: 560px) {
  .portfolioboxcontainer {
    height: 25rem !important;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) {
  .portfolioboxcontainer {
    height: 22rem;
  }
}
@media (max-width: 1300px) and (min-width: 1200px) {
  .portfolioboxcontainer {
    height: 14.5rem;
    padding-inline: 1rem;
  }
}
@media (max-width: 1199px) and (min-width: 1000px) {
  .portfolioboxcontainer {
    height: 12.5rem;
    padding-inline: 2rem;
  }
  #mobile-heading-potfolio-detail {
    display: none;
  }
}

@media (min-width: 479px) and (min-width: 1198px) {
  #mobile-heading-potfolio-detail {
    display: none;
  }
}

@media (max-width: 478px) and (min-width: 300px) {
  .cstm-dev {
    font-size: 1.5rem !important;
  }
  .paragraphmargin-portfolio {
    width: 100% !important;
    padding: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .portfolio-buttons-moile {
    margin-bottom: 2.3rem !important;
    gap: 1.3rem !important;
  }
  .modal-content {
    height: auto !important;
  }
  .modal-content .large-image {
    max-height: 100vh !important;
  }
  .mobileheading {
    display: block;
  }
  .desktopheading {
    display: none;
  }
  .portfoliocontainer {
    width: 100%;
    height: 320px;
    display: grid;
    grid-template-columns: auto;
    gap: 3rem;
  }
  .modal {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 13480 !important;
    display: none; /* Hide the modal by default */
    max-width: 100%; /* Adjust as needed */
    max-height: 100% !important; /* Adjust as needed */
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
}

@media (max-width: 475px) {
  .btns-portfolio{
    margin-bottom: 1rem !important; /* Adjust as needed */
    overflow-y:inherit !important;
  }
  .cstm-dev {
    font-size: 1.5rem !important;
  }
  .portfolio-buttons {
    margin-bottom: 1.5rem !important;
  }

  /* .subpagestitle{

    font-size: 1.7rem !important;
  } */
  .portfolio-gallery {
    display: grid;
    grid-template-columns: none !important;
    gap: 16px;
    justify-content: center; /* Center items horizontally */
    width: 90%;
    margin-inline: auto;
  }

  #mobile-heading-potfolio-detail {
    display: block;
  }

  #desktop-heading-potfolio-detail {
    display: none;
  }
}
@media (max-height: 1200px) and (min-height: 901px) and (max-width: 475px) and (min-width: 376px) {
  .cstm-dev {
    font-size: 1.5rem !important;
  }
  .portfolio-gallery {
    display: grid;
    grid-template-columns: none !important;
    gap: 16px;
    justify-content: center; /* Center items horizontally */
    width: 90%;
    margin-inline: auto;
  }
  .portfolioboxcontainer {
    width: 100%;
    height: 36rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem !important;
    line-height: 18px !important;
    margin-left: 8vw;
  }
}
@media (max-height: 800px) and (min-height: 601px) and (max-width: 475px) and (min-width: 376px) {
  .cstm-dev {
    font-size: 1.5rem !important;
  }
  .portfolioboxcontainer {
    width: 100%;
    height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem !important;
    line-height: 18px !important;
    margin-left: 8vw;
  }
}
@media (max-height: 900px) and (min-height: 801px) and (max-width: 475px) and (min-width: 376px) {
  .portfolioboxcontainer {
    width: 100%;
    height: 29rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem !important;
    line-height: 18px !important;
    margin-left: 8vw;
  }
}
@media (max-height: 600px) and (max-width: 475px) and (min-width: 376px) {
  .cstm-dev {
    font-size: 1.5rem !important;
  }
  .portfolioboxcontainer {
    width: 100%;
    height: 12rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem !important;
    line-height: 18px !important;
    margin-left: 8vw;
  }
}
@media (max-height: 1200px) and (min-height: 901px) and (max-width: 375px) and (min-width: 321px) {
  .portfolioboxcontainer {
    width: 100%;
    height: 36rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem !important;
    line-height: 18px !important;
    margin-left: 8vw;
  }
}
@media (max-height: 900px) and (min-height: 801px) and (max-width: 375px) and (min-width: 321px) {
  .cstm-dev {
    font-size: 1.5rem !important;
  }
  .portfolioboxcontainer {
    width: 100%;
    height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem !important;
    line-height: 18px !important;
    margin-left: 8vw;
  }
}
@media (max-height: 800px) and (min-height: 701px) and (max-width: 375px) and (min-width: 321px) {
  .cstm-dev {
    font-size: 1.5rem !important;
  }
  .portfolioboxcontainer {
    width: 100%;
    height: 24rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem !important;
    line-height: 18px !important;
    margin-left: 8vw;
  }
}
@media (max-height: 700px) and (min-height: 601px) and (max-width: 375px) and (min-width: 321px) {
  .cstm-dev {
    font-size: 1.5rem !important;
  }
  .portfolioboxcontainer {
    width: 100%;
    height: 22rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem !important;
    line-height: 18px !important;
    margin-left: 8vw;
  }
}
@media (max-height: 600px) and (max-width: 375px) and (min-width: 321px) {
  .cstm-dev {
    font-size: 1.5rem !important;
  }
  .portfolioboxcontainer {
    width: 100%;
    height: 12rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem !important;
    line-height: 18px !important;
    margin-left: 8vw;
  }
}
@media (max-height: 1200px) and (min-height: 901px) and (max-width: 320px) and (min-width: 200px) {
  .portfolioboxcontainer {
    width: 100%;
    height: 28rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.2rem;
    line-height: 34px;
  }
}
@media (max-height: 900px) and (min-height: 601px) and (max-width: 320px) and (min-width: 200px) {
  .portfolioboxcontainer {
    width: 100%;
    height: 17rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .subpagestitle1 {
    font-size: 1.1rem !important;
    line-height: 18px !important;
  }
}
@media (max-height: 600px) and (max-width: 320px) and (min-width: 200px) {
  .portfolioboxcontainer {
    width: 100%;
    height: 10rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

/* Add these styles to your existing CSS */

.extra-images-container {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 10px 15px;
  scrollbar-width: thin;
  scrollbar-color: #ff6600 transparent;
  backdrop-filter: blur(5px);
}

.extra-images-container::-webkit-scrollbar {
  height: 6px;
}

.extra-images-container::-webkit-scrollbar-track {
  background: transparent;
}

.extra-images-container::-webkit-scrollbar-thumb {
  background-color: #ff6600;
  border-radius: 3px;
}

.extra-image-thumbnail {
  transition: all 0.2s ease;
}

.extra-image-thumbnail:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(255, 102, 0, 0.5);
}

.extra-image-fullview {
  animation: fadeIn 0.3s ease;
  background-color: rgba(0, 0, 0, 0.8);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add responsive styles */
@media (max-width: 768px) {
  .extra-images-container {
    padding: 8px 10px;
  }

  .extra-images-container img {
    height: 50px;
    width: 75px;
  }

  .extra-image-fullview {
    height: calc(100vh - 120px);
  }
}
