.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 25px); /* Default height adjustment */
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



/* Small screens */
@media (max-width: 930px) {
    .loader-container {
        height: calc(100vh - 45px); /* Adjust as needed for mobile view */
    }
}

/* Medium screens */
@media (min-width: 931px) and (max-width: 1200px) {
    .loader-container {
        height: calc(100vh - 25px); /* Adjust as needed for tablet view */
    }
}

/* Large screens */
@media (min-width: 1200px) {
    .loader-container {
        height: calc(100vh - 25px); /* Original height adjustment for large screens */
    }
}
