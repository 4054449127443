.Testimonialscontainer{
    background-color:rgba(209, 210, 211, 0.39);
    width: 20rem;
    height: 14.2rem;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.Testimonialscontainer:hover{
    transform:scale(1.1);
}
.Testimonialscontainer p{
    font-size: 0.8rem;
    height: 4rem;
    margin-bottom:0rem;
    overflow: auto;
    outline: none;
}
#flagbox{
    width: 2rem;
    height: 2rem;
    border-radius: 1%;
    margin-left: 0.3rem;
    margin-top: 0.3rem;
}
#flagbox img{
    width: 100%;
    height: 70%;
    border-radius: 1%;
    object-fit:fill;
}
#flagbox:hover{
    transform: scale(1.1);
}
.Testimonialscontainer p::-webkit-scrollbar {
    width: 0em;
  }
  
  .Testimonialscontainer p::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
.premium-testimonial-upper-quote{
    transform: rotate(180deg);
}
.textup h4{
    color: #FF3500;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
}
#userpic{
    width: 10rem;
    height: 2.5rem;
    margin-inline: auto;
}
#userpic img{
    width: 100%;
    height: 100%;
    object-fit:contain;
    margin-left: 1.5rem;
    margin-top: 0.5rem;
}

.textup h5{
    color:rgba(254,136,0,255);
    font-weight: 500;
    line-height: 0.5;
}
.internalbox{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin: 0.5rem  -0.2rem;
    cursor: pointer;
    opacity: 0;
    animation: 1s ease 0s 1 normal forwards running jBcSpD;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.internalbox svg{
    transform: rotate(270deg);
    font-size: 1.8rem;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.internalbox:hover{
    transform: scale(1.2);
}
.comlogo{
    margin-top: -0.5rem;
}

.mobile-icon-testimonial{
   visibility: hidden;
}


.mobile-heading-about{
    display: none;
}

.desktop-heading-about{
    display: flex;
}
@keyframes jBcSpD {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  @media  screen and (min-width:4591px) {
    .faqboxcontainer2, .faqboxcontainer1{
        height: 74%;
    }
    .faqboxcontainer2 p,.faqboxcontainer1 p{
        font-size: 2.5rem;
    }
    .faqboxcontainer{
        height: 134rem;
    }
    #faqimageright {
        height: 90%;
    }
}
@media  (max-width:4590px) and (min-width:2591px) {
    .faqboxcontainer2{
        height: 42%;
    }
    .faqboxcontainer2 p,.faqboxcontainer1 p{
        font-size: 2rem;
    } 
    .faqboxcontainer{
        height: 62rem;
    }
    #faqimageright {
        height: 56rem;
    }
    .faqboxcontainer1{
        height: 58rem;
    }
}
@media  (max-width:2590px) and (min-width:1989px) {
    .faqboxcontainer2{
        height: 36rem;
    }
    .faqboxcontainer2 p,.faqboxcontainer1 p{
        font-size: 1.2rem;
    }
    .faqboxcontainer{
        height: 43rem;
    }
    #faqimageright {
        height: 39rem;
    }
    .faqboxcontainer1{
        height: 40rem;
    }
    .Testimonialscontainer{
        width: 31.5rem;
        height: 22.2rem;
    }
    .Testimonialscontainer p{
        font-size: 1rem;
        height: 6rem;
    }
    .textup h4{
        font-size: 1.3rem;
    }
    .internalbox{
        width: 3rem;
        height: 3rem;
    }
    .internalbox svg{
        font-size: 2.5rem;
    }
    #userpic{
        width: 12rem;
        height: 4.5rem;
    }
    .comlogo{
        margin-top: -0.5rem;
        width: 12rem;
        height: 6rem !important;
    }
}
@media  (max-width:1990px) and (min-width:1789px) {
    .mobile-icon-test{
        display: none !important;
    }
    .faqboxcontainer2{
        height: 31rem;
    }
    .faqboxcontainer2 p,.faqboxcontainer1 p{
        font-size: 1.2rem;
    }
    .faqboxcontainer{
        height: 36rem;
    }
    #faqimageright {
        height: 32rem;
    }
    .faqboxcontainer1{
        height: 34rem;
    }
    .Testimonialscontainer{
        width: 28.5rem;
        height: 20.2rem;
    }
    .Testimonialscontainer p{
        font-size: 1rem;
        height: 6rem;
    }
    .textup h4{
        font-size: 1.3rem;
    }
    .internalbox{
        width: 3rem;
        height: 3rem;
    }
    .internalbox svg{
        font-size: 2rem;
    }
    #userpic{
        width: 12rem;
        height: 4.5rem;
    }
    .comlogo{
        margin-top: -0.5rem;
        width: 12rem;
        height: 6rem !important;
    }
}
@media  (max-width:1790px) and (min-width:1589px) {
    .mobile-icon-test{
        display: none !important;
    }
    .Testimonialscontainer{
        width: 26.5rem;
        height: 20.2rem;
    }
    .Testimonialscontainer p{
        font-size: 1rem;
        height: 6rem;
    }
    .textup h4{
        font-size: 1.2rem;
    }
    .internalbox{
        width: 3rem;
        height: 3rem;
    }
    .internalbox svg{
        font-size: 2rem;
    }
    #userpic{
        width: 12rem;
        height: 4.5rem;
    }
    .comlogo{
        margin-top: -0.5rem;
        width: 10rem;
        height: 6rem !important;
    }
}

@media  (max-width:1590px) and (min-width:1300px){
    .mobile-icon-test{
        display: none !important;
    }
}
@media  (max-width:1590px) and (min-width:1400px) and (max-height: 859px) and (min-height: 700px)  {
    .mobile-icon-test{
        display: none !important;
    }
    .Testimonialscontainer{
         width: 24.5rem !important;
         height: 18.5rem !important;
     } 
     .comlogo {
         height: 6.4rem !important;
         width: 12rem !important;
         margin-top: 1rem;
         /* object-position: center center; */
         object-fit: contain;
     }
     #eightheight{
         line-height: 25px !important;
     }
 }
@media  (max-width:1590px) and (min-width:1400px) and (max-height: 659px) and (min-height: 560px)  {
   .Testimonialscontainer{
        width: 24.5rem !important;
        height: 18.5rem !important;
    } 
    .comlogo {
        height: 6.4rem !important;
        width: 12rem !important;
        margin-top: 1rem;
        /* object-position: center center; */
        object-fit: contain;
    }
    #eightheight{
        line-height: 25px !important;
    }
}
@media  (max-width:1590px) and (min-width:1400px) {
    .Testimonialscontainer{
        width: 24.5rem;
        height: 16rem;
    }
    .comlogo{
        height:3.4rem;
        margin-top: 0;
    }
}
@media  (max-width:1300px) and (min-width:1200px) {
    .Testimonialscontainer{
        width: 20.5rem;
        height: 11.7rem;
    }
    #userpic{
        width: 10rem;
        height: 2.5rem;
    }
    .Testimonialscontainer p{
        font-size: 0.65rem;
        height: 3rem;
    }
    .star{
       font-size: 0.5rem;
    }
    .textup h4{
        font-size: 0.9rem;
    }
}
@media  (max-width:1199px) and (min-width:1000px) {
    .Testimonialscontainer{
        width: 18.5rem;
        height: 11.4rem;
    }
    #userpic{
        width: 10rem;
        height: 2.2rem;
    }
    .Testimonialscontainer p{
        font-size: 0.55rem;
        height: 3rem;
    }
    .star{
       font-size: 0.5rem;
    }
    .internalbox{
        margin: 0.5rem  -0.5rem;
    }
    .internalbox svg{
        transform: rotate(270deg);
        font-size: 1.5rem;
    }
    .textup h4, .textup h5{
        font-size: 0.8rem;
    }
    .comlogo{
        width:10rem;
        height:1.3rem;
    }
}

@media  (max-width:775px) and (min-width:676px) {
    .Testimonialscontainer{
        width: 11.8rem;
    }
}

@media  (max-width:675px) and (min-width:476px) {
    .Testimonialscontainer{
        width: 18rem;
    }
}

/*mobile responsiveness*/

@media (min-width:476){
    .buttons-contact-mobile{
        margin-bottom: 1.8rem !important;
    }

    .mobile-heading-about{
        display: none;
    }

}

@media  (max-width:475px) and (min-width:376px) {

    .buttons-contact-mobile{
        margin-bottom: 2rem !important;
    }

    .mobile-heading-about{
        display: flex;
    }

    .desktop-heading-about{
        display: none;
    }



    .mobile-icon-testimonial{
        visibility: visible;
    }
    .desktop-icon-testimonial{
        display: none;
    }

    .icon-testimonial, .faqiconmobile-about{
        margin-left: 0rem !important;
    }

    .swiper-testimonial, .marginRightforhowtos {
        margin-bottom: 1rem !important;
    }
    .Testimonialscontainer{
        width: 22rem;
        height: 16.2rem;
        margin-inline: auto;
    }
    @media (max-height: 900px) and (min-height:801px){
        .Testimonialscontainer{
            width: 22rem;
            margin-inline: auto;
            height: 20.2rem;
        }
        .Testimonialscontainer p{
            font-size: 1rem;
            height: 7rem;
        }
        #userpic{
            width: 12rem;
            height: 4.5rem;
            margin-inline: auto;
        }
        #flagbox {
            width: 3rem;
            height: 3rem;
            border-radius: 1%;
            margin-left: 0.55rem;
            margin-top: 0.5rem;
        }
        .internalbox svg {
            font-size: 3rem;  
        }
        .internalbox{
            width: 3rem;
            height: 3rem;
            margin: 0.5rem  -0.6rem;
        }
        .comlogo{
            width: 14rem !important;
            height: 5rem !important;
            object-fit: contain;
        }
    }
}
@media  (max-width:375px) and (min-width:321px) {

    .buttons-contact-mobile{
        bottom: 9% !important;
    }
    .subpagestitle{
        font-size: 1.6rem !important;
    }
    .mobile-heading-about{
        display: flex;
    }

    .desktop-heading-about{
        display: none;
    }

    .mobile-icon-testimonial{
        visibility: visible;
    }
    .desktop-icon-testimonial{
        display: none;
    }

    .icon-testimonial, .faqiconmobile-about{
        margin-left: 0rem !important;
    }

    .Testimonialscontainer{
        width: 20rem;
        margin-inline: auto;
        height: 15.2rem;
    }
    .Testimonialscontainer p{
        font-size: 0.9rem;
        height: 5.5rem;
    }

}
@media  (max-width:320px) and (min-width:200px) {
    .subpagestitle{
        font-size: 1.6rem !important;
    }
    .mobile-heading-about{
        display: flex;
    }

    .desktop-heading-about{
        display: none;
    }

    .mobile-icon-testimonial{
        visibility: visible;
    }
    .desktop-icon-testimonial{
        display: none;
    }

    .icon-testimonial, .faqiconmobile-about{
        margin-left: 0rem !important;
    }

    .Testimonialscontainer{
        width: 17rem;
        margin-inline: auto;
    }
}
