@keyframes play-once {
  0%,
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0.99;
    animation-play-state: paused;
  }
}

.emp-data-div{
  margin-top: 2.4rem !important;
}

.gif-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gif-image.paused {
  opacity: 1;
  animation: none;
  pointer-events: auto;
}

.gif-image.playing {
  opacity: 1;
  animation: none;
  pointer-events: auto;
}

/* Create a pseudo-element to overlay and pause the GIF */
.gif-image.paused::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

.imgboxgif {
  position: relative;
  width: 100%;
  height: 70vh;
}

.imgboxgif img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: play-once 1s forwards;
  animation-iteration-count: 1;
  animation-play-state: running;
}

.imgboxgif img:hover {
  animation: play-once 1s forwards;
  animation-iteration-count: 1;
  animation-play-state: running;
}

@media (max-width: 1590px) and (min-width: 1400px) {
  .imgboxgif {
    height: 70vh !important;
  }

  .desktopview {
    margin-top: 0rem !important;
  }
  .emp-data-div{
    margin-top: 2.4rem !important;
  }
}

@media (max-width: 675px) {
  .desktopview-image {
    display: none;
  }
  .desktopview {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    display: block !important;
  }

  .mobile-employee-data {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}

@media (max-width: 476px) {
  .emp-heading {
    /* font-size: 1.5rem !important; */
    margin-top: 0rem !important;
  }
  .emp-verification-mob-heading {
    font-size: 1.6rem !important;
  }
}

@media (max-width: 475px) and (min-width: 376px) {
  .emp-heading {
    font-size: 1.5rem !important;
    margin-top: 0rem !important;
  }

  .emp-verification-mob-heading {
    font-size: 1.6rem !important;
  }
  .btnsergridcontainer {
    margin-inline: 0.5rem;
  }
  .e-text {
    margin-left: 0 !important;
    padding: 1rem;
  }
}

@media (max-width: 375px) and (min-width: 200px) {
  .e-text {
    margin-left: 0 !important;
    padding: 1rem;
  }
}
