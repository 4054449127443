.jobs-div {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  height: 68vh;
}

.job-card {
  /* background: #fff; */
  border-radius: 12px;
  padding: 24px;
  background-color: #333;
  box-shadow: 0 4px 20px -5px rgba(8, 52, 82, 0.12),
    0 20px 30px rgba(8, 52, 82, 0.08);
  transition: transform 0.2s ease;
  width: 100%;
  max-width: 800px;
}
.job-actions-mobile{
  display: none;
}
.job-card:hover {
  transform: translateY(-2px);
}

.heading-mobile-jobs{
  width: 100% !important;
}

.btnsgrid-jobs{
  margin-right: 0rem !important;
}
.job-header {
  /* margin-bottom: 16px; */
}

.job-header h3 {
  /* color: #333; */
  font-size: 1.5rem;
  margin-bottom: 12px;
  font-family: "CFNotche-Bold";
}

.job-meta {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.job-header-desk{
  display: flex;
  justify-content: space-between;
}

.job-meta span {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  background: #f5f5f5;
  color: #666;
}

.employment-type {
  color: #fd7e14 !important;
  background: rgba(255, 102, 0, 0.1) !important;
}

.location {
  color: #2c5282 !important;
  background: rgba(44, 82, 130, 0.1) !important;
}

.job-actions {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-right: 5px;
}

.toggle-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  background: transparent;
  border: none;
  color: #fd7e14;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 8px 0;
  transition: color 0.2s;
}

.toggle-btn:hover {
  color: #cc5200;
}

.apply-btn {
  background: #fd7e14;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
}

.apply-btn:hover {
  background: #cc5200;
}

.responsibilities {
  margin-top: 20px;
  padding: 16px;
  background: #484b4d6b;
  border-radius: 8px;
  font-size: 0.95rem;
  line-height: 1.6;
  animation: slideDown 0.3s ease-out;
  color: #fff;
}

.responsibilities ul {
  margin: 0;
  padding-left: 20px;
  list-style-type: none;
}

.responsibilities ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
}

.responsibilities ul li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #fd7e14;
}

.responsibilities p {
  margin: 0;
  white-space: pre-line;
}

.responsibilities a {
  color: #fd7e14;
  text-decoration: none;
  word-break: break-all;
}

.responsibilities a:hover {
  text-decoration: underline;
}

.responsibilities span[style*="font-family: Roboto"] {
  font-family: inherit !important;
  color: #fff !important;
}

.responsibilities span:contains("About") {
  display: block;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.responsibilities span:contains("❖"),
.responsibilities span:contains("➛") {
  color: #fd7e14;
  margin-right: 8px;
}

.responsibilities br {
  display: block;
  content: "";
  margin-top: 8px;
}

.responsibilities [style*="color"] {
  color: #fff !important;
}
.jobs-button button {
  width: 240px !important;
}

.button1 button {
  width: 350px !important;
}

.button2 button {

    width: 402px !important;

}

@media (max-width: 1500px) and (min-width: 1150px) {
  .paragraphmargin-jobs{
    margin-right: 3rem !important;
  
  }
}



@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .jobs-button-div {
        flex-wrap: wrap !important;
        gap: 0.9rem !important;
      }
  .job-card {
    padding: 16px;
  }

  /* .job-meta {
    flex-direction: column;
    gap: 8px;
  } */

  .job-meta span {
    display: inline-block;
  }

  .job-actions {
    flex-direction: column;
    gap: 12px;
  }

  .apply-btn {
    /* width: 100%; */
  }

  .responsibilities {
    padding: 12px;
    font-size: 0.9rem;
  }

  .responsibilities ul li {
    padding-left: 16px;
    margin-bottom: 10px;
  }
}


@media screen and (max-width: 550px) {
  .job-actions-desktop{
    display: none;
  }

  .job-actions-mobile{
    display: block;
    justify-self: center;
  }
  .jobs-div {
    height: 60vh;
  }

  .jobs-button button {
    width: 210px !important;
  }

  .button2 button {
    width: 350px !important;
  }

  .button1 button {
    width: 300px !important;
  }

  .jobs-button button .cbypVx {
    font-size: 1.1rem !important;
  }

  .jobs-button-div {
    flex-wrap: wrap !important;
    gap: 0.9rem !important;
  }
}

@media screen and (max-width: 550px) {
  #eightheight{
    justify-content: center !important;
  }
  .jobs-div {
    height: 55vh;
  }
}

.application-modal {
  color: #fff;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.application-modal h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
  font-family: "CFNotche-Bold";
}

.application-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  color: #fff;
  font-size: 0.9rem;
}

.form-control {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.95rem;
}

.form-control:focus {
  outline: none;
  border-color: #fd7e14;
}

textarea.form-control {
  resize: vertical;
  min-height: 100px;
}

input[type="file"].form-control {
  padding: 6px;
}

input[type="file"].form-control::-webkit-file-upload-button {
  background: #fd7e14;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin-right: 12px;
}

.submit-btn {
  background: #fd7e14;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 10px;
}

.submit-btn:hover {
  background: #cc5200;
}

.submit-btn:disabled {
  background-color: #666;
  cursor: not-allowed;
  opacity: 0.7;
}

.form-control.error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
.apply-btn-container{
  background: transparent !important;
  padding: 0rem !important;
}

/* Responsive styles */
@media (max-width: 768px) {
  .application-modal {
    padding: 15px;
  }

  .application-modal h2 {
    font-size: 1.5rem;
  }

  .form-control {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 476px) {
  .mobile-apply-btn {
    display: block !important;
    padding: 0rem !important;
    padding: 0.5rem 1rem !important;
  }

  .apply-btn-desktop {
    display: none !important;
  }

  .job-meta{
    flex-wrap: nowrap !important
    ;
  }

  .apply-btn-container{
    background: transparent !important;
    padding: 0rem !important;
  }


}
