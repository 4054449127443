/* Add this CSS in your App.css or another global styles file */
#backbtnport {
  background-color: #3333334d;
  padding: 0.4rem;
  border-radius: 50%;
  transform: rotate(180deg);
}
.full-screen-skeleton {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ) !important; /* Light dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above all content */
}

.skeleton-container {
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/*
canvas{
  width: 700px !important;
  height: 650px !important;
  border-radius: 50%;
  margin: auto;
  justify-content: center;
}
*/
.leftboxearthcon {
  display: flex;
}
.leftboxearth {
  width: 58%;
  position: relative;
  z-index: 1000;
}

.leftboxearth .swiper .swiper-slide {
  width: 120px;
  height: auto;
  margin-right: 0.5rem;
}
.leftboxearth .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.newearthbox {
  width: 38%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden;  */
  margin-top: -3%;
}

.core-expertise {
  font-family: "CFNotche-Bold", "Source Sans 3", "sans-serif" !important;
}

.newearthbox canvas {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1rem + 2vmin); /* Using viewport units for font size */
  color: white;
}

.App-link {
  color: #61dafb;
}
.servicesbtn {
  border: none;
  background-color: rgb(247, 134, 48);
  color: #fff;
  font-size: 1.3rem;
  border-radius: 5px;
  text-transform: uppercase;
  margin-left: 1vw; /* Using viewport width for margin */
  padding: 1vh 3vw; /* Using viewport height and width for padding */
}

.servicesbtn svg {
  font-size: 2vw; /* Using viewport width for font size */
  margin-right: 1vw; /* Using viewport width for margin */
}
.iconcontact {
  font-size: 2.1rem !important;
  margin-right: 0.5rem !important;
}
.servicesbtn:hover {
  background-color: rgb(247, 134, 48);
  color: #000000;
}
.paraverti {
  color: #ffffff;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-family: Bebas Neue;
  font-size: 4.6vmin; /* Using viewport min for font size */
  margin-top: 3vh; /* Using viewport height for margin */
  margin-bottom: 2.2vh; /* Using viewport height for margin */
  line-height: 0.1em;
}

.paraverti1 {
  color: #ffffff;
  writing-mode: vertical-rl;
  transform: rotate(0deg);
  font-family: "CFNotche-Bold", Bebas Neue;
  font-size: 4.6rem;
  margin-top: 1.8rem;
  margin-bottom: 1rem;
  line-height: 0em;
}

.rotate-90 {
  display: inline-block;
  transform: rotate(90deg);
  transform-origin: center center;
}
.hometitle {
  font-size: 4.9vw;
  font-weight: 800;
  letter-spacing: 0.1em;
  margin-left: 0;
  z-index: 999999;
}
.subpagestitle,
.subpagestitle1,
.blogs-title {
  font-size: 2.5vw; /* Using viewport width for font size */
  font-weight: 800;
  letter-spacing: 0.1em; /* Adjusted letter spacing */
  margin-left: 0vw; /* Using viewport width for margin */
}

.ml {
  margin-left: 1vw; /* Using viewport width for margin */
  font-size: 1.2rem;
}
.copyword {
  font-size: 1.3rem;
}
#rightimage {
  width: 100%;
  height: 70vh; /* Using viewport height for height */
}

#rightimage img {
  width: 100%;
  height: 100%;
}

.aboutcontentscrol {
  width: 100%;
  height: 32.8vh; /* Using viewport height for height */
  overflow-y: auto;
  margin-bottom: 0.5rem;
}
.aboutcontentscrol h4 {
  color: rgba(254, 136, 0, 255);
  font-weight: 700;
}

#rightimageabout {
  /* margin-top: 1em !important; */
  /* position: relative; */
  width: 36vw;
  height: 75vh;
  background-image: url("/src/Component/FILEJSON/services/about.png");
  background-repeat: no-repeat;
  background-size: 74% 90%;
  object-fit: contain;
  background-position: center right;
  
   /* background-position: top right; */
  margin: auto;
}

#rightimageabout img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-inline: 5rem;
}
#aboutrightimm {
  /* margin-top: 15rem; */
  margin-top: 20rem;
  margin-left: 7rem !important;
  /* position: absolute;
  top: -2.5rem; */
}

.verifyinput {
  height: 3vw; /* Using viewport width for height */
  background-color: transparent;
  border: 1px solid #fff;
  margin-inline: 0.8rem;
  padding: 1.2vw; /* Using viewport width for padding */
  outline: none;
  color: #fff;
  border-radius: 25px;
}

#profilepicround {
  width: 8vw; /* Using viewport width for width */
  height: 8vw; /* Using viewport width for height */
  background-color: rgb(247, 134, 48);
  border-radius: 50%;
  margin: auto; /* Center the element */
}

#profilepicround img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/*blogs*/

.blogcontainer {
  width: 100%;
  max-width: 1254px;
  margin: 1rem auto 0;
  padding: 20px 4vw;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(18rem, 1fr)
  ); /* Adjusting column size to 18rem */
  gap: 3vw;
  overflow-y: auto;
}

.blogcard {
  width: 18rem; /* Fixed width */
  height: 15.5rem; /* Fixed height */
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem auto;
  padding: 2vw;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.blogdetailText {
  margin-top: 1.5rem !important;
  font-size: 1.2rem;
  color: #fff !important;
}
.blogdetailText p {
  font-size: 1.2rem;
  color: #fff !important;
}
.blogcard .blogcardimg {
  width: 100%;
  height: 100%;
}

.blogcard img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.blogcard .blogcardimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blogcard .blogcardimg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.blogcard h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1rem; /* Added margin for better spacing */
}

.blogcard p {
  font-size: 0.8rem;
  margin-top: 0.5rem; /* Added margin for better spacing */
}
.blogcard:hover,
.relatedblogscard:hover {
  transform: scale(1.1);
}
.detailboxcontainer {
  width: 100%;
  height: 8rem;
}
.relatedblogscard {
  width: 260px;
  height: 5.5rem;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.relatedblogscard h4 {
  font-size: 0.9rem;
  font-weight: 900;
}
.blogcard:nth-child(1n),
.relatedblogscard:nth-child(1n) {
  background: linear-gradient(
    209.21deg,
    rgb(59, 55, 109) 13.57%,
    rgb(30, 21, 53) 98.38%
  );
}
.blogcard:nth-child(2n),
.relatedblogscard:nth-child(2n) {
  background: linear-gradient(
    209.21deg,
    rgb(55, 109, 82) 13.57%,
    rgb(31, 21, 53) 98.38%
  );
}
.blogcard:nth-child(3n),
.relatedblogscard:nth-child(3n) {
  background: linear-gradient(
    209.21deg,
    rgb(76, 55, 109) 13.57%,
    rgb(31, 21, 53) 98.38%
  );
}
.blogcard:nth-child(4n),
.relatedblogscard:nth-child(4n) {
  background: linear-gradient(
    209.21deg,
    rgb(109, 105, 55) 13.57%,
    rgb(31, 21, 53) 98.38%
  );
}
.blogcard:nth-child(5n),
.relatedblogscard:nth-child(5n) {
  background: linear-gradient(
    209.21deg,
    rgb(109, 63, 55) 13.57%,
    rgb(31, 21, 53) 98.38%
  );
}
.blogcard:nth-child(6n),
.relatedblogscard:nth-child(6n) {
  background: linear-gradient(
    209.21deg,
    rgb(204, 62, 43) 13.57%,
    rgb(53, 23, 21) 98.38%
  );
}
.blogcard:nth-child(7n),
.relatedblogscard:nth-child(7n) {
  background: linear-gradient(
    209.21deg,
    rgb(107, 55, 109) 13.57%,
    rgb(31, 21, 53) 98.38%
  );
}
.blogcard:nth-child(8n),
.relatedblogscard:nth-child(8n) {
  background: linear-gradient(
    209.21deg,
    rgb(109, 55, 62) 13.57%,
    rgb(31, 21, 53) 98.38%
  );
}
.blogcard:nth-child(9n),
.relatedblogscard:nth-child(9n) {
  background: linear-gradient(
    209.21deg,
    rgb(224, 14, 231) 13.57%,
    rgb(31, 21, 53) 98.38%
  );
}
.blogcard:nth-child(10n),
.relatedblogscard:nth-child(10n) {
  background: linear-gradient(
    209.21deg,
    rgb(201, 198, 37) 13.57%,
    rgb(48, 53, 21) 98.38%
  );
}
.blogcard:nth-child(11n),
.relatedblogscard:nth-child(11n) {
  background: linear-gradient(
    209.21deg,
    rgb(76, 55, 109) 13.57%,
    rgb(31, 21, 53) 98.38%
  );
}
.blogcard:nth-child(12n),
.relatedblogscard:nth-child(12n) {
  background: linear-gradient(
    209.21deg,
    rgb(23, 230, 16) 13.57%,
    rgb(6, 22, 6) 98.38%
  );
}
.blogcard:hover,
.relatedblogscard:hover {
  box-shadow: rgba(46, 42, 59, 0.3) 0px 20px 80px,
    rgba(0, 0, 0, 0.15) 0px 20px 40px;
}
.blogdetailscroller {
  width: 50%;
  height: 33rem;

  overflow: auto;
  /* height:calc(100vh - 39%); */
}

.Reviewscroller {
  width: 100%;
  height: 35%;
  overflow: auto;
}

.recentblogbox {
  width: 100%;
  height: 7.5rem;
  overflow: auto;
}
.aboutcontentscrol::-webkit-scrollbar,
.blogcontainer::-webkit-scrollbar,
.blogdetailscroller::-webkit-scrollbar,
.recentblogbox::-webkit-scrollbar,
.Reviewscroller::-webkit-scrollbar {
  width: 0em;
}

.aboutcontentscrol::-webkit-scrollbar-thumb,
.blogcontainer::-webkit-scrollbar-thumb,
.blogdetailscroller::-webkit-scrollbar-thumb,
.recentblogbox::-webkit-scrollbar-thumb,
.Reviewscroller::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.blogcategorybtn {
  padding: 0.2rem 1rem;
  border: none;
  outline: none;
  text-align: center;
  text-transform: capitalize;
  font-family: "CFNotche-Bold", "Gill Sans", "Gill Sans MT", Calibri,
    "Trebuchet MS", sans-serif;
  background-color: #282c34;
  color: #fff;
  border-radius: 5px;
}
.hometitlegrid {
  display: grid;
  grid-template-columns: auto;
}
.hometitlegridflex {
  display: flex;
}
.contactheadingbixflex {
  display: flex;
  justify-content: space-between;
}
.widthheighticon {
  width: 58px;
  height: 58px;
  margin-top: 0.5rem;
}

.fixedcontainer {
  position: fixed;
  bottom: 3%;
  left: 0;
  width: 60%;
  z-index: 1000; /* Adjust the z-index as needed */
  background-color: #ffffff00; /* Set the background color if necessary */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a subtle effect */
}
.fixedcontainer1 {
  position: fixed;
  bottom: 6%;
  left: 0;
  width: 100%;
  z-index: 1000; /* Adjust the z-index as needed */
  background-color: #ffffff00; /* Set the background color if necessary */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a subtle effect */
}
.line {
  text-align: center;
  display: flex;
}
.divider {
  display: inline-block;
  width: 7px;
  height: 65px;
  margin-inline: 29px;
  background-color: #ffffff;
}
.diviv {
  margin-inline: 42px;
}

.blur-container {
  position: relative;
  overflow: hidden;
}

.blur-effect {
  mask-image: linear-gradient(
    to right,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  /* Optional: add a blur to the content itself for a smoother transition */
}

.blur-effect .swiper-container {
  position: relative;
}

@media screen and (min-width: 4591px) {
  .hometitle {
    font-size: 12.8rem;
    font-weight: 800;
    letter-spacing: 8px;
    margin-bottom: 2rem;
    margin-left: 1.4rem;
    margin-top: 4rem;
  }
  .widthheighticon {
    width: 200px;
    height: 200px;
    margin-top: 4rem;
  }
  #homescrollerpara {
    height: 26rem;
    margin-bottom: 1.6rem;
  }
  .servicesbtn {
    padding: 1.8rem 3.8rem;
    margin-bottom: 2.5rem !important;
  }
  .paraverti {
    font-size: 8.5rem !important;
  }
  .ml {
    margin-left: 1.5rem;
    font-size: 5.5rem;
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .largecimg {
    width: 450px !important;
    height: 450px !important;
  }
  .aboutcontentscrol {
    height: 60.8rem;
  }
  .blogcontainer {
    height: 110rem;
  }
  .blogcard {
    height: 400px;
    padding: 1vw;
  }
  .blogdetailscroller {
    height: 135rem;
  }
  .blogdetailscroller p {
    font-size: 2.6rem;
  }
  #rightimageabout img {
    margin-inline: 7.6rem;
    width: 140%;
    height: 118%;
  }
  #rightimageabout {
    height: 90vh;
  }
  #aboutrightimm {
    margin-top: 24.2rem;
  }
  .divider {
    display: inline-block;
    width: 20px;
    height: 225px;
    margin: 50px 100px;
    background-color: #ffffff;
  }
  .diviv {
    margin-inline: 130px;
  }
}
@media (max-width: 4590px) and (min-width: 2591px) {
  .hometitle {
    font-size: 5.8rem;
    font-weight: 800;
    letter-spacing: 8px;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: 1.5rem;
  }
  .widthheighticon {
    width: 100px;
    height: 100px;
    margin-top: 1.2rem;
  }
  #homescrollerpara {
    height: 14rem;
    margin-bottom: 1.6rem;
  }
  .servicesbtn {
    padding: 1.8rem 3.5rem;
    margin-bottom: 2.5rem !important;
  }
  .paraverti {
    font-size: 4rem !important;
  }
  .ml {
    margin-left: 1rem;
    font-size: 2.8rem;
    margin-bottom: 2.8rem !important;
    margin-top: 2.8rem !important;
  }
  .largecimg {
    width: 200px !important;
    height: 200px !important;
  }
  .aboutcontentscrol {
    height: 33.8rem;
  }
  #rightimageabout img {
    margin-inline: 7.6rem;
    width: 140%;
    height: 102%;
  }
  #rightimageabout {
    height: 90vh;
  }
  #aboutrightimm {
    margin-top: 26.9rem;
  }
  .blogcontainer {
    height: 60rem;
  }
  .blogdetailscroller {
    height: 65rem;
  }
  .blogdetailscroller p {
    font-size: 1.8rem;
  }
  .divider {
    display: inline-block;
    width: 15px;
    height: 110px;
    margin: 10px 100px;
    background-color: #ffffff;
  }
  .diviv {
    margin-inline: 110px;
  }
}
@media (max-width: 2590px) and (min-width: 1989px) {
  .hometitle {
    font-size: 5.4rem;
    font-weight: 800;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  .widthheighticon {
    width: 80px;
    height: 80px;
  }
  #homescrollerpara {
    height: 10rem;
  }
  .servicesbtn {
    padding: 1.2rem 2.6rem;
    margin-bottom: 1.8rem !important;
  }
  .paraverti {
    font-size: 2.6rem !important;
  }
  .ml {
    margin-left: 1rem;
    font-size: 2rem;
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }
  .largecimg {
    width: 170px !important;
    height: 130px !important;
  }
  .aboutcontentscrol {
    height: 23.8rem;
  }
  .blogcontainer {
    height: 42rem;
  }
  .blogdetailscroller {
    height: 45rem;
  }
  .blogdetailscroller p {
    font-size: 1.4rem;
  }
  #rightimageabout img {
    margin-inline: 7.4rem;
  }
  #aboutrightimm {
    margin-top: 21.5rem;
  }
  .divider {
    display: inline-block;
    width: 8px;
    height: 88px;
    margin-inline: 49px;
    background-color: #ffffff;
  }
  .diviv {
    margin-inline: 60px;
  }
}
@media (max-width: 1990px) and (min-width: 1789px) {
  .hometitle {
    font-size: 4.8rem;
    font-weight: 800;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  .widthheighticon {
    width: 80px;
    height: 70px;
  }
  #homescrollerpara {
    height: 8.5rem;
  }
  .servicesbtn {
    padding: 0.8rem 2.4rem;
    margin-bottom: 1.5rem !important;
  }
  .paraverti {
    font-size: 2.5rem !important;
  }
  .ml {
    margin-left: 1rem;
    font-size: 1.7rem;
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }
  .largecimg {
    width: 150px !important;
    height: 120px !important;
  }
  .aboutcontentscrol {
    height: 17.8rem;
  }
  .blogcontainer {
    height: 35rem;
  }
  .blogdetailscroller {
    height: 40rem;
  }
  .blogdetailscroller p {
    font-size: 1.2rem;
  }
  #rightimageabout img {
    margin-inline: 7rem;
  }
  #aboutrightimm {
    margin-top: 19.5rem;
  }
  .divider {
    display: inline-block;
    width: 8px;
    height: 88px;
    margin-inline: 49px;
    background-color: #ffffff;
  }
  .diviv {
    margin-inline: 60px;
  }
  .newearthbox {
    margin-top: 0%;
  }
  @media (max-height: 900px) and (min-height: 800px) {
    .newearthbox {
      margin-top: 1% !important;
    }
    #homescrollerpara {
      height: 14.5rem !important;
    }
  }
  @media (max-height: 799px) and (min-height: 600px) {
    .newearthbox {
      margin-top: -1% !important;
    }
    #homescrollerpara {
      height: 9.5rem;
    }
  }
}
@media (max-width: 1790px) and (min-width: 1589px) {
  .hometitle {
    font-size: 4.8rem;
    font-weight: 800;
    letter-spacing: 5px;
    margin-bottom: 0rem;
    margin-left: 1rem;
    margin-top: 0.9rem;
  }
  .widthheighticon {
    width: 80px;
    height: 80px;
    margin-top: 1rem;
  }
  #homescrollerpara {
    height: 8.5rem;
  }
  .servicesbtn {
    padding: 0.6rem 2.4rem;
    margin-bottom: 1.5rem !important;
  }
  .ml {
    margin-left: 1rem;
    font-size: 1.7rem;
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }

  .aboutcontentscrol {
    height: 17.8rem;
  }
  .blogcontainer {
    height: 32rem;
  }
  .blogdetailscroller {
    height: 36rem;
  }
  #rightimageabout img {
    margin-inline: 5.8rem;
  }
  #aboutrightimm {
    margin-top: 18.4rem;
  }
  .divider {
    display: inline-block;
    width: 8px;
    height: 85px;
    margin-inline: 49px;
    background-color: #ffffff;
  }
  .diviv {
    margin-inline: 60px;
  }
  .newearthbox {
    margin-top: -1%;
  }
  @media (max-height: 759px) and (min-height: 560px) {
    .newearthbox {
      margin-top: 0% !important;
    }
  }
}
@media (max-width: 1590px) and (min-width: 1400px) and (max-height: 759px) and (min-height: 560px) {
  #aboutrightimm {
    margin-top: 20.4rem !important;
  }
  #rightimageabout {
    /* width: 40vw;
    height: 80vh; */
    background-image: url("/src/Component/FILEJSON/services/about.png");
    background-repeat: no-repeat;
    /* background-size: 70% 90%; */
    object-fit: contain;
    /* background-position: center right; */
    margin: auto;
  }
}
.moveleftcontent {
  margin-left: 2rem;
}
@media (max-width: 1590px) and (min-width: 1400px) {
  .hometitle {
    font-size: 4.5rem;
    font-weight: 800;
    letter-spacing: 5px;
    margin-left: 1rem;
    margin-bottom: 0rem;
  }

  #homescrollerpara {
    height: 21vh;
    margin-bottom: 1vh;
  }

  .servicesbtn {
    padding: 2% 5%; /* Adjust the percentages as needed */
    margin-bottom: 1.5rem !important;
  }

  .ml {
    margin-left: 1rem;
    font-size: 1.4rem;
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }
  .moveleftcontent p {
    font-size: 1.4rem !important;
  }
  .moveleftcontent h4 {
    font-size: 2rem !important;
  }
  .aboutcontentscrol {
    height: 14.5rem;
  }

  .blogcontainer {
    height: 28rem;
  }
  .blogcard {
    padding: 1vw;
  }
  .blogdetailscroller {
    height: 30rem;
  }
  .d-flexbtn {
    margin-bottom: 0.5vh;
  }
  #rightimageabout img {
    margin-inline: 5.4rem;
  }
  #aboutrightimm {
    margin-top: 16.4rem;
  }
  .divider {
    display: inline-block;
    width: 8px;
    height: 78px;
    margin-inline: 29px;
    background-color: #ffffff;
  }
  .diviv {
    margin-inline: 40px;
  }
}

@media (max-height: 900px) and (min-height: 601px) and (max-width: 1440px) and (min-width: 1025px) {
  #homescrollerpara {
    height: 26vh; /* Increase height for smaller screens */
  }
}

@media (max-width: 1300px) and (min-width: 1200px) {
  .hometitle {
    font-size: 8vh;
    font-weight: 800;
    letter-spacing: 5px;
    margin-bottom: 0rem;
    margin-left: 1rem;
  }
  .widthheighticon {
    width: 4vw;
    height: 7vh;
  }
  #homescrollerpara {
    height: 20vh;
    position: relative;
    z-index: 30001;
  }
  .servicesbtn {
    padding: 0.5rem 2.2rem;
    margin-bottom: 1.5rem !important;
  }
  .ml {
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-bottom: 0.8rem !important;
    margin-top: 0.8rem !important;
  }

  .mml {
    margin-top: 0rem !important;
  }

  .paraverti {
    font-size: 4vmin; /* Using viewport min for font size */
    margin-top: 3vh; /* Using viewport height for margin */
    margin-bottom: 2.2vh; /* Using viewport height for margin */
  }
  .aboutcontentscrol {
    height: 11.8rem;
  }
  .blogcontainer {
    height: 21rem;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.7rem;
  }
  .boxtopmargin {
    margin-top: 2vh;
  }
  .d-flexbtn {
    margin-bottom: 2vh;
  }
  #rightimageabout img {
    margin-inline: 5rem;
  }
  #aboutrightimm {
    /* margin-top: 13.6rem; */
  }
  .divider {
    display: inline-block;
    width: 6px;
    height: 55px;
    margin-inline: 29px;
    background-color: #ffffff;
  }
  .diviv {
    margin-inline: 37px;
  }
}



@media (max-width: 1199px) and (min-width: 1011px) {
  .hometitle {
    font-size: 2.6rem;
    font-weight: 800;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  /* .boxtopmargin {
    margin-top: 12.5vh;
  } */
  .widthheighticon {
    width: 40px;
    height: 40px;
    margin-top: 0.3rem;
  }
  #homescrollerpara {
    height: 48vh;
    margin-top: 0vh;
  }
  .servicesbtn {
    padding: 0.5rem 2.2rem;
    margin-bottom: 1.5rem !important;
  }
  .ml {
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-bottom: 0.8rem !important;
    margin-top: 0.8rem !important;
  }
  .mml {
    margin-top: 0rem !important;
  }
  .largecimg {
    width: 10vw !important;
    height: 10vh !important;
  }
  .paraverti {
    font-size: 3.2vmin; /* Using viewport min for font size */
    margin-top: 2vh; /* Using viewport height for margin */
    margin-bottom: 2.2vh;
  }
  .aboutcontentscrol {
    height: 15rem;
  }
  .blogcontainer {
    height: 18rem;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.4rem;
  }
  #rightimageabout img {
    margin-inline: 4.8rem;
  }
  #aboutrightimm {
    /* margin-top: 12.2rem; */
  }
  .diviv {
    margin-inline: 37px;
  }
  .divider {
    height: 42px;
  }
  .subpagestitle,
  .blogs-title {
    font-size: 2.5vw;
  }
}

@media (max-width: 1024px) and (min-width: 924px) {
  .hometitle {
    font-size: 2.8rem;
  }
  .largecimg {
    width: 100% !important;
    height: 12vh !important;
  }
  .widthheighticon {
    width: 8vh;
    height: 7vh;
    margin-top: 1vh;
  }
  .paraverti {
    font-size: 1.5rem;
    margin-top: 2.5vh !important;
  }
  #homescrollerpara {
    height: 25vh;
    margin-bottom: 4vh;
  }
  .diviv {
    margin-inline: 37px;
  }
  .divider {
    height: 40px;
  }
}
@media (max-height: 900px) and (min-height: 601px) and (max-width: 1024px) and (min-width: 777px) {
  #homescrollerpara {
    height: 30vh; /* Increase height for smaller screens */
  }
}
@media (max-width: 1000px) and (min-width: 800px) {
  canvas {
    width: 98% !important;
    height: 98% !important;
    border-radius: 50%;
  }
  .subpagestitle,
  .blogs-title {
    font-size: 2.6vw;
  }
}
@media (max-width: 1100px) and (min-width: 900px) {
  .newearthbox {
    height: 80vh;
    margin-top: 15vh;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.4rem;
    font-size: 1rem; /* Adjust the value as needed */
  }
}
@media (max-width: 1024px) and (min-width: 932px) and (max-height: 600px) and (min-height: 500px) {
  .boxtopmargin {
    margin-top: 1.5vh;
  }
  #homescrollerpara {
    height: 20vh;
    position: relative;
    z-index: 30001;
  }
  .newearthbox canvas {
    width: 1448px !important;
    height: 605px !important;
  }
}
@media (max-width: 931px) and (min-width: 854px) {
  .widthheighticon {
    width: 36px;
    height: 35px;
    margin-top: 0.2rem;
  }
  .hometitle {
    font-size: 2.3rem;
  }

  .paraverti {
    font-size: 1.1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 0.1em;
  }
  .mml img {
    width: 60px !important;
    height: 50px !important;
  }
  .ml {
    font-size: 1rem;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.2rem;
  }
  .diviv {
    margin-inline: 37px;
  }
  .divider {
    height: 40px;
  }
}

@media (max-width: 799px) and (min-width: 768px) {
  .blogcategorybtn {
    padding: 0.2rem 0.4rem;
    font-size: 0.6rem;
  }
  .btnsergridcontainer {
    justify-content: center;
    margin-inline: auto;
  }
  .diviv {
    margin-inline: 34px;
  }
  .divider {
    height: 35px;
  }
  .fixedcontainer {
    position: fixed;
    bottom: 8%;
  }
  .subpagestitle,
  .blogs-title {
    font-size: 3vw;
  }
}
@media (max-width: 499px) and (min-width: 320px) {
  canvas {
    width: 250px !important;
    height: 250px !important;
    border-radius: 50%;
    background-color: transparent !important;
  }
}
#removermob {
  display: block;
}
/*tablet responsiveness*/
@media (max-width: 1024px) and (min-width: 853px) and (max-height: 1380px) and (min-height: 1100px) {
  .leftboxearthcon {
    flex-wrap: wrap;
  }
  .leftboxearth {
    width: 100% !important;
    position: relative;
    z-index: 3001;
  }
  .newearthbox {
    height: 30vh;
    margin-top: 0vh;
    width: 100%;
  }
  canvas {
    width: 100% !important;
    height: 68% !important;
    border-radius: 50%;
  }
  .fixedcontainer {
    width: 100%;
  }
  .launchbtn {
    width: 208px !important;
  }
  .launchbtncus {
    width: 170px !important;
  }
  .paraverti {
    font-size: 3.1rem;
    margin-top: 3.5rem;
    margin-bottom: 0.5rem;
    line-height: 0.1em;
  }
  .ml {
    font-size: 1.5rem;
  }
  #homescrollerpara {
    min-height:48vh !important;
  }
  .hometitle {
    font-size: 3.5rem;
  }
  .widthheighticon {
    width: 67px;
    height: 60px;
    margin-top: 0.2rem;
  }
  .boxtopmargin {
    margin-top: 3.5vh;
  }
}
@media (max-width: 776px) and (min-width: 676px) and (max-height: 1024px) and (min-height: 900px) {
  .leftboxearthcon {
    flex-wrap: wrap;
  }
  .leftboxearth {
    width: 100% !important;
    position: relative;
    z-index: 30001;
  }
  .newearthbox {
    height: 55vh;
    margin-top: 0vh;
    width: 100%;
  }
  canvas {
    width: 100% !important;
    height: 68% !important;
    border-radius: 50%;
  }
  .fixedcontainer {
    width: 100%;
  }
  #homescrollerpara {
    min-height: 32vh !important;
  }
  .hometitle {
    font-size: 3.5rem;
  }
  .widthheighticon {
    width: 67px;
    height: 60px;
    margin-top: 0.2rem;
  }
  .boxtopmargin {
    margin-top: 3.5vh;
  }
}
@media (max-width: 775px) and (min-width: 676px) {
  .hometitle {
    font-size: 1.8rem;
  }
  .widthheighticon {
    width: 28px;
    height: 28px;
    margin-top: 0rem;
  }
  .ml {
    margin-left: 0.7rem;
    font-size: 1.2rem;
  }
  .largecimg {
    width: 80px !important;
    height: 60px !important;
  }
  .paraverti {
    margin-top: 2rem;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  #homescrollerpara {
    height: 7rem;
  }
  canvas {
    width: 86% !important;
    height: 60% !important;
    border-radius: 50%;
    background-color: transparent !important;
  }
  .aboutcontentscrol {
    height: 11rem;
  }
  #rightimageabout {
    width: 60%;
    height: 75%;
  }
  .mobilepara {
    font-size: 0.75rem;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.2rem;
    font-size: 0.6rem;
  }
  .fixedcontainer {
    position: fixed;
    bottom: 8%;
  }
  .subpagestitle,
  .blogs-title {
    font-size: 3vw;
  }
}
@media (max-height: 900px) and (min-height: 601px) and (max-width: 775px) and (min-width: 500px) {
  #homescrollerpara {
    height: 44vh; /* Increase height for smaller screens */
  }
}

@media (max-width: 540px) and (min-width: 476px) and (max-height: 720px) and (min-height: 600px) {
  .leftboxearth,
  .fixedcontainer {
    width: 100%;
  }
  #homescrollerpara {
    height: 12rem !important;
  }
  .d-flexbtn {
    justify-content: center;
  }
  .largecimg {
    width: 14vw !important;
    height: 10vh !important;
  }
}

@media (max-width: 675px) and (min-width: 476px) {
  .servicecard-mob {
    width: 23rem !important;
  }
  .hometitle {
    font-size: 2rem;
  }
  .widthheighticon {
    width: 35px;
    height: 35px;
    margin-top: 0rem;
  }
  #homescrollerpara {
    height: 7rem;
  }
  .paraverti {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  .ml {
    margin-left: 0.7rem;
    font-size: 1.2rem;
  }
  .aboutcontentscrol {
    height: 8rem;
  }
  .desktopview {
    display: none;
  }
  .mobilepara {
    font-size: 0.9rem;
  }
  .Reviewscroller {
    height: 40%;
  }
  .mobileview {
    display: block !important;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.2rem;
    font-size: 0.5rem;
    margin-inline: auto;
  }
  .diviv {
    margin-inline: 34px;
  }
  .divider {
    height: 35px;
  }
  .subpagestitle,
  .blogs-title {
    font-size: 5.5vw;
  }
}

@media (max-width: 675px) and (min-width: 500px) {
  .blogs-title {
    width: 100%;
    text-align: center;
  }
  .blogcard {
    margin-inline: auto;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.2rem;
    font-size: 0.4rem;
    margin-inline: auto;
  }
}

/*mobile responsiveness*/
#profilepicround svg {
  font-size: 8vw;
}
@media (max-width: 1050px) and (min-width: 385px) {
  .swiperfaqbgt{
    width:3.9rem !important;
  }

  .btnsergrid a, .btncatgrid a {
    width: 4.1rem !important;
    height: 3.8rem !important;
    /* padding: 0.3rem 0.4rem; */
}

.btnsergrid{
  height:4.2rem !important;
}

  #servicemenuciricon {
    /* font-size: rem; */
    width: 50px !important;
    height: 45px !important;
}
  .earth-desktopview{
    display: none !important;
  }
  .service-hometitle-mob {
    font-size: 1.1rem !important;
  }
  .servicecard-mob {
    width: 23rem !important;
  }
  .blogs-title {
    width: 100%;
    text-align: center;
  }
  .home-title-mob {
    font-size: 1.8rem !important;
  }
  .moveleftcontent {
    margin-left: 1rem;
  }
  #removermob {
    display: none !important;
  }
  .fixedcontainer1 {
    bottom: 8%;
    width: 100% !important;
  }
  #profilepicround svg {
    font-size: 4rem;
  }
  .d-flexbtn {
    position: fixed;
    /* flex-wrap: wrap; */
    justify-content: center;
    width: 100%;
    position: relative;
  }
  .hometitle {
    font-size: 2rem;
  }
  .widthheighticon {
    width: 45px;
    height: 45px;
    margin-top: 0rem;
  }
  .paraverti {
    font-size: 1.8rem;
    margin-top: 1.8rem;
    margin-bottom: 1rem;
  }
  .largecimg {
    width: 150px !important;
    height: 90px !important;
  }
  .ml {
    margin-inline: 0.5rem;
    font-size: 1.5rem;
  }
  .paragraphmargin {
    padding-inline: 0.1rem !important;
    font-size: 1rem;
    /* height: 4.5rem !important; */
    height: 3rem !important;
    margin-bottom: 1rem !important;
  }
  .parase {
    font-size: 1rem;
    margin-inline: 0.5rem;
  }
  .desktopview {
    display: none;
  }
  .aboutcontentscrol {
    height: 12rem;
  }
  .mobilepara {
    font-size: 0.8rem;
    margin-bottom: 0rem;
  }
  #profilepicround {
    width: 4rem;
    height: 4rem;
  }
  .Reviewscroller {
    height: 29%;
  }
  .mobileview {
    display: block !important;
  }
  .blogcard {
    margin-inline: auto;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.2rem;
    font-size: 0.4rem;
  }
  .leftboxearth,
  .fixedcontainer {
    width: 100%;
  }
  .fixedcontainer {
    position: fixed;
    bottom: 6%;
  }
  .diviv {
    margin-inline: 34px;
  }
  .divider {
    height: 32px;
    margin-inline: 10px;
  }
  .subpagestitle,
  .blogs-title {
    font-size: 8.5vw !important;
  }
  .verifyinput {
    padding: 6.2vw;
  }
  .aboutmcenter {
    justify-content: end !important;
    margin-left: 0 !important;
  }
  .privicon {
    display: none;
    width: 100px !important;
    height: 100px !important;
    margin-left: 4vw;
  }
  @media (max-height: 600px) {
    #homescrollerpara {
      min-height: 8rem;
    }
    .aboutcontentscrol {
      height: 6rem;
    }
  }

  @media (min-height: 601px) and (max-height: 700px) {
    #homescrollerpara {
      height: calc(100vh - 500px);
    }
    .aboutcontentscrol {
      height: 16rem;
    }
  }
  @media (min-height: 701px) and (max-height: 800px) {
    #homescrollerpara {
      height: calc(100vh - 552px);
    }
    .aboutcontentscrol {
      height: 16rem;
    }
  }
  @media (min-height: 801px) and (max-height: 900px) {
    #homescrollerpara {
      height: calc(100vh - 575px);
    }
    .widthheighticon {
      width: 45px;
      height: 45px;
      margin-top: 0rem;
    }
    .hometitle {
      font-size: 7.7vw;
      margin-bottom: 0.2rem;
      margin-right: 0.3rem !important;
      margin-top: 0.3rem;
    }
    .aboutcontentscrol {
      height: calc(100vh - 505px);
    }
    .bghb {
      height: 21rem !important;
    }
    #eightheight {
      font-size: 1.8rem !important;
      line-height: 33px !important;
    }
    .divider {
      height: 43px;
      margin-inline: 12px;
    }
  }
  .mml {
    margin-left: 1.2rem !important;
  }
  @media (min-height: 901px) and (max-height: 1200px) {
    #homescrollerpara {
      height: calc(100vh - 560px);
    }
    .aboutcontentscrol {
      height: 33rem;
    }
  }

  @media (max-height: 514px) {
    #homescrollerpara {
      height: calc(100vh - 81vh);
    }
    .aboutcontentscrol {
      height: 6rem;
    }
  }
}

/* Add this to your navbar.css file */
@media screen and (max-width: 400px) {
  .navbar-login-signup {
    display: none !important;
  }
}



@media (max-width: 385px) and (min-width: 321px) {
  .moveleftcontent{

    height: 21rem !important;

  }
  .service-hometitle-mob {
    font-size: 1.1rem !important;
  }
  .servicecard-mob {
    width: 23rem !important;
  }
  .home-title-mob {
    font-size: 1.7rem !important;
  }
  .blogs-title {
    width: 100%;
    text-align: center;
  }
  .moveleftcontent {
    margin-left: 1rem;
  }
  #removermob {
    display: none !important;
  }
  #profilepicround svg {
    font-size: 4rem;
  }
  .d-flexbtn {
    /* flex-wrap: wrap !important; */
    justify-content: center;
    width: 100%;
    position: relative;
  }
  .fixedcontainer1 {
    bottom: 10% !important;
    width: 100% !important;
  }
  .hometitle {
    font-size: 1.7rem;
  }
  .widthheighticon {
    width: 35px;
    height: 35px;
    margin-top: 0rem;
  }
  .paraverti {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .largecimg {
    width: 100px !important;
    height: 70px !important;
  }
  #homescrollerpara {
    min-height: 5rem;
  }
  .ml {
    margin-inline: 0.5rem;
    font-size: 1.2rem;
  }
  .paragraphmargin {
    padding-inline: 0.1rem !important;
    font-size: 0.8rem;
    height: 2.5rem !important;
    /* height: 3rem !important; */
  }
  .parase {
    font-size: 0.8rem;
  }
  .desktopview {
    display: none;
  }
  .aboutcontentscrol {
    height: 12rem;
  }
  .mobilepara {
    font-size: 0.8rem;
    margin-bottom: 0rem;
  }
  #profilepicround {
    width: 4rem;
    height: 4rem;
  }
  .Reviewscroller {
    height: 29%;
  }
  .mobileview {
    display: block !important;
  }
  .blogcard {
    margin-inline: auto;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.2rem;
    font-size: 0.4rem;
  }
  .leftboxearth,
  .fixedcontainer {
    width: 100%;
  }
  .fixedcontainer {
    position: fixed;
    bottom: 8%;
  }
  #homescrollerpara {
    min-height: 7rem;
  }
  .diviv {
    margin-inline: 34px;
  }
  .divider {
    height: 32px;
    margin-inline: 10px;
  }
  .subpagestitle,
  .blogs-title {
    font-size: 8.5vw !important;
  }
  .verifyinput {
    padding: 3.2vw;
  }
  .mml {
    margin-left: 1rem !important;
  }
  .aboutmcenter {
    justify-content: end !important;
    margin-left: 0 !important;
  }
  .privicon {
    display: none;
    width: 100px !important;
    height: 100px !important;
    margin-left: 4vw;
  }
  @media (max-height: 580px) {
    #homescrollerpara {
      min-height: 6rem;
    }
    .aboutcontentscrol {
      height: 7rem;
    }
  }

  @media (min-height: 601px) and (max-height: 700px) {
    #homescrollerpara {
      min-height: 7rem;
    }
    .aboutcontentscrol {
      height: 15.8rem;
    }
  }

  @media (min-height: 701px) and (max-height: 800px) {
    #homescrollerpara {
      min-height: calc(100vh - 505px);
    }
    .fixedcontainer {
      bottom: 8%;
    }
  }
  @media (min-height: 801px) and (max-height: 900px) {
    #homescrollerpara {
      min-height: calc(100vh - 554px);
    }
    .fixedcontainer {
      bottom: 8%;
    }
  }
  @media (min-height: 901px) and (max-height: 1200px) {
    #homescrollerpara {
      height: calc(100vh - 567px);
    }
    .aboutcontentscrol {
      height: 33.8rem;
    }
  }
}

@media (max-width: 320px) {
  .service-hometitle-mob {
    font-size: 1.1rem !important;
  }
  .servicecard-mob {
    width: 23rem !important;
  }
  .blogs-title {
    width: 100%;
    text-align: center;
  }
  .moveleftcontent {
    margin-left: 1rem;
  }
  #removermob {
    display: none !important;
  }
  #profilepicround svg {
    font-size: 4rem;
  }
  .privicon {
    display: none;
    width: 100px !important;
    height: 120px !important;
    margin-left: 4vw;
  }
  .aboutmcenter {
    justify-content: end !important;
    margin-left: 0 !important;
  }
  .d-flexbtn {
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  .fixedcontainer1 {
    bottom: 9%;
  }
  .leftboxearth,
  .fixedcontainer {
    width: 100%;
  }
  .hometitlegrid {
    display: grid;
    grid-template-columns: auto;
  }
  .hometitle {
    font-size: 1.5rem;
  }
  .widthheighticon {
    width: 25px;
    height: 25px;
    margin-top: 0rem;
  }
  .ml {
    margin-left: 0.5rem;
    font-size: 1rem;
  }
  .paraverti {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .largecimg {
    width: 100px !important;
    height: 50px !important;
  }
  .paragraphmargin {
    padding-inline: 0.1rem !important;
    font-size: 0.8rem;
    height: 2.5rem !important;
  }
  .parase {
    font-size: 0.8rem;
  }
  .desktopview {
    display: none;
  }
  .aboutcontentscrol {
    height: 8.8rem;
  }
  .blogcategorybtn {
    padding: 0.2rem 0.2rem;
    font-size: 0.4rem;
  }
  .blogcard {
    margin-inline: auto;
  }
  .mobilepara {
    font-size: 0.8rem;
    margin-bottom: 0rem;
  }
  #profilepicround {
    width: 4rem;
    height: 4rem;
  }
  .Reviewscroller {
    height: 34%;
  }
  .mobileview {
    display: block !important;
  }
  .fixedcontainer {
    position: fixed;
    bottom: 9%;
  }
  .diviv {
    margin-inline: 16px !important;
  }
  .divider {
    height: 32px;
    margin-inline: 12px;
  }
  .subpagestitle,
  .blogs-title {
    font-size: 11.5vw !important;
  }

  @media (max-height: 500px) {
    #homescrollerpara {
      height: calc(100vh - 86vh);
    }
    .aboutcontentscrol {
      height: 4.8rem;
    }
  }

  @media (max-width: 550px) {
    .servicecard-mob {
      width: 23rem !important;
    }
    .home-title-mob {
      font-size: 1.7rem !important;
    }
  }

  @media (max-width: 475px) {
    .service-hometitle-mob {
      font-size: 1.1rem !important;
    }
    .servicecard-mob {
      width: 23rem !important;
    }
    .home-title-mob {
      font-size: 1.7rem !important;
    }
  }
  @media (min-height: 501px) and (max-height: 900px) {
    #homescrollerpara {
      height: calc(100vh - 485px);
    }
    .aboutcontentscrol {
      height: 13.8rem;
    }
  }

  @media (min-height: 901px) and (max-height: 1200px) {
    #homescrollerpara {
      height: 43vh;
    }
    .aboutcontentscrol {
      height: 29.8rem;
    }
  }
}

@media (max-width: 475px) and (min-width: 376px) {
  .service-hometitle-mob {
    font-size: 1.1rem !important;
  }
}

@media (max-width: 375px) and (min-width: 200px) {
  #aboutrightimm{
    visibility: hidden !important;
  


  }

  .mobile-view-about{
    display: none !important;
  }
  #rightimageabout{
    background-image: none !important;
  }
}




@media (min-height: 1200px) {
  #homescrollerpara {
    min-height: 15vh;
  }
}
.mobileview {
  display: none;
}
.d-flexbtn {
  display: flex;
  /* flex-wrap: wrap; */
}

.paragraphmargin {
  padding-inline: 10rem;
  height: 3rem;
  overflow: auto;
  text-align: center;
}
.paragraphmargin::-webkit-scrollbar {
  width: 0em;
}

.paragraphmargin::-webkit-scrollbar-thumb {
  background-color: transparent;
}

p {
  font-family: Source Sans\3, sans-serif;
}
