.faqboxcontainer {
  padding: 0.5rem 1rem;
  width: 100%;
  height: 25rem;
  overflow-y: auto;
  outline: none;
}
.faqboxcontainer1,
.faqboxcontainer2,
.faqboxcontainer21 {
  padding: 0.5rem 1rem;
  width: 100%;
  height: 20rem;
  overflow-y: auto;
  outline: none;
}

.service-leftcontent{
  padding: 0.5rem 1rem;
  width: 100%;
  height: calc(100vh - 39%) ;
  overflow-y: auto;
  outline: none;
}

.fixedcontainerfaq {
  position: fixed;
  bottom: 6%;
  right: 0;
  width: 42%;
  z-index: 1000; /* Adjust the z-index as needed */
  background-color: #c5303000; /* Set the background color if necessary */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a subtle effect */
}


.faqboxcontainer::-webkit-scrollbar,
.faqboxcontainer1::-webkit-scrollbar,
.faqboxcontainer2::-webkit-scrollbar,
.service-leftcontent::-webkit-scrollbar,
.faqboxcontainer21::-webkit-scrollbar {
  width: 0em;
}

.faqboxcontainer::-webkit-scrollbar-thumb,
.faqboxcontainer1::-webkit-scrollbar-thumb,
.faqboxcontainer2::-webkit-scrollbar-thumb,
.service-leftcontent::-webkit-scrollbar,
.faqboxcontainer21::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.faqboxq {
  margin: 0.2rem;
}
#faqimageright {
  width: 100%;
  height: 22.5rem;
  object-fit: contain;
}
#faqimageright img {
  width: 100%;
  height: 100%;
}
.faqboxf {
  display: flex;
  justify-content: space-between;
  background-color: rgba(209, 210, 211, 0.39);
}
.faqboxf h2 {
  font-size: 1.1rem;
  margin: 0.8rem 0.5rem;
}
.parafaq {
  background-color: rgb(233, 220, 201);
}
.parafaq p {
  padding: 0.5rem;
  color: black;
}
.faqboxf button {
  background-color: transparent;
  border: none;
  outline: none;
}
.faqboxf svg {
  font-size: 1rem;
  margin: 1rem;
  color: #fff;
}
.swiperfaqbgt {
  background-color: #3333334d;
  border-radius: 50%;
  height: 3rem;
  width: 3rem !important;
  padding: 0.45rem;
}
@media screen and (min-width: 4591px) {
  .faqboxcontainer2,
  .faqboxcontainer1,
  .faqboxcontainer21 {
    height: 74%;
  }
  .faqboxcontainer2 p,
  .faqboxcontainer1 p,
  .faqboxcontainer21 p {
    font-size: 2.5rem;
  }
  .faqboxcontainer {
    height: 134rem;
  }
  #faqimageright {
    height: 90%;
  }
  #faqiconmain {
    width: 160px !important;
    height: 130px !important;
  }
}
@media (max-width: 4590px) and (min-width: 2591px) {
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 42%;
  }
  .faqboxcontainer2 p,
  .faqboxcontainer1 p,
  .faqboxcontainer21 {
    font-size: 2rem;
  }
  .faqboxcontainer {
    height: 62rem;
  }
  #faqimageright {
    height: 56rem;
  }
  .faqboxcontainer1 {
    height: 58rem;
  }
  #faqiconmain {
    width: 60px !important;
    height: 60px !important;
  }
}
@media (max-width: 2590px) and (min-width: 1989px) {
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 39rem;
  }
  .faqboxcontainer2 p,
  .faqboxcontainer1 p,
  .faqboxcontainer21 p {
    font-size: 1.2rem;
  }
  .faqboxcontainer {
    height: 43rem;
  }
  #faqimageright {
    height: 39rem;
  }
  .faqboxcontainer1 {
    height: 40rem;
  }
  #faqiconmain {
    width: 50px !important;
    height: 50px !important;
  }
}
@media (max-width: 1990px) and (min-width: 1789px) {
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 31rem;
  }
  .faqboxcontainer2 p,
  .faqboxcontainer1 p,
  .faqboxcontainer21 p {
    font-size: 1.2rem;
  }
  .faqboxcontainer {
    height: 36rem;
  }
  #faqimageright {
    height: 32rem;
  }
  .faqboxcontainer1 {
    height: 34rem;
  }
  #faqiconmain {
    width: 40px !important;
    height: 40px !important;
  }
}
@media (max-width: 1790px) and (min-width: 1589px) {
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 28rem;
  }
  .faqboxcontainer {
    height: 33rem;
  }
  #faqimageright {
    height: 29rem;
  }
  .faqboxcontainer1 {
    height: 31rem;
  }
  #faqiconmain {
    width: 40px !important;
    height: 40px !important;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) {

  .faq-icon-desk{
    display: none !important;
  }
  .faq-icon-desk{
    display: none !important;
  }
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 23.5rem;
  }
  .faqboxcontainer {
    height: 24rem;
  }
  #faqimageright,
  .faqboxcontainer1 {
    height: 25.5rem;
  }
  #faqiconmain {
    width: 35px !important;
    height: 35px !important;
  }
  .btnsergridcontainer-faq{
    margin-top: 0rem !important;
  }
}

@media (max-width: 1400px) and (min-width: 1300px) {


.faq-icon-desk{
  display: none !important;
}

.faq-box-desk{
  height: 21rem !important;
}

}
@media (max-width: 1300px) and (min-width: 1200px) {


  .faq-icon-desk{
    display: none !important;
  }
  
  .btnsergridcontainer-faq{
    margin-top: 0rem !important;
  }
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 19rem;
  }
  .faqboxcontainer {
    height: 22rem;
  }
  #faqimageright {
    height: 18.5rem;
  }
  .faqboxcontainer1 {
    height: 17rem;
  }
}
@media (max-width: 1199px) and (min-width: 1000px) {
  .faq-icon-desk{
    display: none !important;
  }
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 20rem;
  }
  .faqboxcontainer {
    height: 19rem;
  }
  #faqimageright {
    height: 14.5rem;
  }
  .faqboxcontainer1 {
    height: 13rem;
  }
}

@media (max-width: 775px) and (min-width: 676px) {
  .faqboxcontainer1,
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 19rem;
  }
  .faqboxcontainer {
    height: 20rem;
  }
  #faqimageright {
    height: 15.5rem;
  }
}
@media (max-width: 675px)  {
  .desktopview-faq{
    display: none !important;
  }
}
@media (max-width: 675px) and (min-width: 476px) {
  .desktopview-faq{
    display: none !important;
  }
  .faqboxcontainer1,
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 16rem;
  }
  .faqboxcontainer {
    height: 20rem;
  }
}

/*mobile responsiveness*/

@media (max-width: 475px) and (min-width: 376px) {

  .swiperfaqbgt {
    background-color: #3333334d;
    border-radius: 50%;
    height: 3rem;
    width: 3.9rem !important;
    padding: 0.45rem;
}
  .desktopview-faq{
    display: none !important;
  }

  .faq-box-mobile{
    height: calc(100vh - 410px) !important;
  }
  .service-leftcontent{
    /* padding: 0.5rem 1rem;
    width: 100%; */
    height: calc(100vh - 40%) ;
    /* overflow-y: auto;
    outline: none; */
  }

  .marketplace-class{
    height: calc(100vh - 25%) !important;
  }

  .live-chat-class{
    height: calc(100vh - 45%) !important;
  }

  .eCommerce-system{
    height: calc(100vh - 28%) !important;
  }
  
  .faq-title-mob{
    margin-top:0.9rem !important;
    line-height:31px !important;
    }
  .fixedcontainerfaq{
    margin-bottom: 1.5rem !important;
    gap: 1rem !important;
  }


  /* .fixedcontainerfaq a{
   width: 210px !important;
  } */

  .faq-mob-buttons{
    margin-bottom: 1.5rem !important;
  }
  .faqboxcontainer1,
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 14rem;
    width: 100% !important; 
  }
  .faqboxcontainer {
    height: 20rem;
  }
  @media (max-height: 1200px) and (min-height: 901px) {
    .faqboxcontainer {
      height: 43rem;
    }
  }
  @media (max-height: 900px) and (min-height: 801px) {
    .faqboxcontainer {
      height: calc(100vh - 285px);
    }
    .faqboxcontainer21 {
      height: 32rem;
    }
  }
  @media (max-height: 800px) and (min-height: 701px) {
    .faqboxcontainer {
      height: 25rem;
    }
    .faqboxcontainer21 {
      height: 24rem;
    }
  }
  @media (max-height: 700px) and (min-height: 601px) {
    .faqboxcontainer {
      height: 25rem;
    }
    .faqboxcontainer21 {
      height: 19rem;
    }
  }
  @media (max-height: 600px) {
    .faqboxcontainer {
      height: 16rem;
    }
  }
}

@media (max-width: 375px) and (min-width: 321px) {
  .faq-mob-buttons{
    margin-bottom: 1.5rem !important;
  }
  .faqboxcontainer1 {
    height: 14rem;
  }
  .faqboxcontainer2 {
    height: 15rem;
    width: 100% !important; 
  }
  .faqboxcontainer {
    height: 20rem;
  }
  .faqboxcontainer21 {
    height: 26rem;
  }
  @media (max-height: 1200px) and (min-height: 901px) {
    .faqboxcontainer {
      height: 43rem;
    }
    .faqboxcontainer21 {
      height: 36rem;
    }
  }
  @media (max-height: 900px) and (min-height: 801px) {
    .faqboxcontainer {
      height: 25rem;
    }
    .faqboxcontainer21 {
      height: 30rem;
    }
  }
  @media (max-height: 800px) and (min-height: 701px) {
    .faqboxcontainer {
      height: 25rem;
    }
    .faqboxcontainer21 {
      height: 25rem !important;
    }
  }

  @media (max-height: 700px) and (min-height: 601px) {
    .faqboxcontainer {
      height: 25rem;
    }
    .faqboxcontainer21 {
      height: 20rem !important;
    }
  }
  @media (max-height: 600px) {
    .faqboxcontainer {
      height: 16rem;
    }
    .faqboxcontainer21 {
      height: 14rem;
    }
  }
}

@media (max-width: 320px) and (min-width: 200px) {
  .faq-mob-buttons{
    margin-bottom: 1.5rem !important;
  }
  .faqboxcontainer1,
  .faqboxcontainer2,
  .faqboxcontainer21 {
    height: 14rem;
    width: 100% !important; 
  }
  .faqboxf svg,
  .faqboxf h2 {
    font-size: 0.8rem;
  }
  .faqboxcontainer {
    height: 20rem;
  }
  @media (max-height: 1200px) and (min-height: 901px) {
    .faqboxcontainer {
      height: 43rem;
    }
    .faqboxcontainer21 {
      height: 38rem;
    }
  }
  @media (max-height: 900px) and (min-height: 801px) {
    .faqboxcontainer {
      height: 25rem;
    }
    .faqboxcontainer21 {
      height: 28rem;
    }
  }
  @media (max-height: 800px) and (min-height: 701px) {
    .faqboxcontainer {
      height: 25rem;
    }
    .faqboxcontainer21 {
      height: 26rem;
    }
  }
  @media (max-height: 700px) and (min-height: 601px) {
    .faqboxcontainer {
      height: 25rem;
    }
    .faqboxcontainer21 {
      height: 19rem;
    }
  }
  @media (max-height: 600px) {
    .faqboxcontainer {
      height: 16rem;
    }
  }
}
