.applicationcontainer{
    width: 20rem;
    height: 24.5rem;
    background-color: rgba(19,19,20,.7);
    border-color: #333;
    border-radius: 12px;
    box-shadow: 0 4px 20px -5px rgba(8,52,82,.12), 0 20px 30px rgba(8,52,82,.08);
    transition: width 0.3s, height 0.3s;
    overflow: hidden;
            position: relative;
            margin-inline: auto;
            transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.soltns-button{
    margin-top: 2.7rem;
}

/* .service-leftcontent{
    height: calc(100vh - 45%) !important;
} */

.slick-track{
    
    padding-top:18.5px;
}
.slick-slider, .slider-container {
    transition: opacity .15s linear;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.slick-slider:not(.slick-vertical) .slick-track, .slider-container:not(.slick-vertical) .slick-track {
    display: flex !important;
}
.slick-slider:not(.logos-carousel):not(.stack-slider):not(.fade-transition) .slick-slide, .slider-container:not(.logos-carousel):not(.stack-slider):not(.fade-transition) .slick-slide {
    transition: box-shadow .1s ease-in-out;
    transition: transform .5s cubic-bezier(.165, .84, .44, 1);
}
.slick-slider:not(.slick-vertical) .slick-slide, .slider-container:not(.slick-vertical) .slick-slide {
    height: inherit !important;
    margin: 0;
}
.has-payoneer-dark-black-background-color [class*=border]:not(.has-border-color) {
    border-color: #333 !important;
}
.is-style-blurred-bg.has-payoneer-black-background-color {
    background: rgba(19, 19, 20, .7) !important;
    /* -webkit-backdrop-filter: blur(8px) !important;
    backdrop-filter: blur(8px) !important; */
    border: 0.03rem solid #535454;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
}
.card {
    border-radius: 12px;
    width: 93% !important;
    transition: width 0.3s, height 0.3s;
    overflow: hidden;
    padding-top: 20px !important;
            position: relative;
            margin-inline: auto;
            transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.card:hover{
    box-shadow: 0 4px 20px -5px rgba(8,52,82,.12), 0 20px 30px rgba(8,52,82,.08);
    transform: scale(1.1); 
}
.is-style-blurred-bg {
    background: rgba(255, 255, 255, .86);
    box-shadow: 0 4px 20px -5px rgba(8, 52, 82, .12), 0 20px 30px rgba(8, 52, 82, .08);
    /* -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px); */
}
.p-4 {
    padding: 1.5rem !important;
}
.me-3 {
    margin-right: 1rem !important;
}
.border-2 {
    --bs-border-width: 2px;
}
.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: rgba(0, 0, 0, .175);
    --bs-card-border-radius: 0.375rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, .03);
    --bs-card-cap-color: ;
    --bs-card-height:19.5rem;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}
.wp-block-heading{
    font-size: 1.55rem;
}
/*.swiper-scrollbar {
    height: 4px;
    position: absolute;
    left:6rem;
    bottom:1rem;
    width: calc(100% - 6rem);
  }
  .swiper-progress-bar {
    height: 100%;
    background:rgb(128, 49, 49);
  }*/
  .slider-buttons .custom-slick-arrow {
    color: #ffffff;
    background: #000;
    cursor: pointer;
    transition: background .2s ease-in-out;
    padding: 5px;
}
.slider-buttons .custom-slick-arrow:hover{
    background: #ff6600;
}
.slider-buttons .custom-slick-arrow.previous svg {
    position: relative;
    right: 1px;
    font-size: 40px;
}
.slider-buttons .custom-slick-arrow.next svg {
    position: relative;
    left: 1px;
    font-size: 40px;
}
.has-payoneer-dark-black-background-color .slick-progress, .theme-dark .slick-progress {
    background-color: #2c2c2c;
    background-image: linear-gradient(90deg, #d054ce 0, #0092f4 50%, #15c474 100%);
}
.slick-progress {
    position: relative;
    /* z-index: 9999999; */
    display: block;
    width: 100%;
    height: 6px;
    overflow: hidden;
    padding-top: 20px;
    background-color: #535454;
    background-image: linear-gradient(90deg, #d054ce 0, #0092f4 50%, #15c474 100%);
    background-repeat: no-repeat;
    background-size: 0 100%;
    transition: background-size .4s ease-in-out;
    border-radius: 60px;
}
.slick-progress .slider__label {
    display: none;
}
.slider-controls-wrapper{
margin-inline: 3rem;
}
.swiper-nav-btns{
    width: 8rem;
}
.swiper-nav-btns button{
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: rgb(247, 134, 48);
  border:none;
  outline: none;
  margin-inline: 0.3rem;
  margin-top: 0.5rem;
  color: #fff;
}
/*.swiper-button-prev, .swiper-button-next {
 top:98% !important;
 width:2rem !important;
 height: 2rem !important;
 color: rgb(247, 134, 48) !important;
 background-color: #333;
 border-radius: 50%;
 font-size: 12px !important;
}
.swiper-button-next:after{
    font-size: 12px;
}*/

.btnsgrid-serv-det{
    justify-content: flex-start !important;

}
.applicationcontainerbox{
    width: 19rem;
    height: 10.8rem;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding: 0.5rem;
}
.thumbnail-image {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    border-radius: 12px;
}
.applicationcontainer:hover .zoom-icon {
    display: block;
}
.zoom-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
}
.large-image-container-card{
    background: rgba(27, 27, 27, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
}
.large-image-container-card .swiper-button-prev{
    z-index: 1200;
}
.large-image-container-card h1{
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: capitalize;
    text-align: center;
}
.large-image-container {
    max-width: 100vw;
    max-height:100vh;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .large-image {
    max-width: 100%;
    max-height: 80%;
  }
  .large-image1 {
    max-width: 50%;
    max-height: 50%;
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
.card-content h1{
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0.1rem 0.5rem;
    text-align: left;
}
.card-content p{
    font-size: 0.8rem;
    margin: 0.1rem 0.5rem;
    text-align: left;
    color: #fff;
}
.card-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.applicationcontainer:hover{
    box-shadow: 0 4px 20px -5px rgba(8,52,82,.12), 0 20px 30px rgba(8,52,82,.08);
    transform: scale(1.1);
    color: #fff;
}
.launchbtn{
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 223, 255) 100%);
    border: none;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset, rgba(23, 0, 102, 0.2) 0px 20px 40px, rgba(0, 0, 0, 0.1) 0px 1px 3px;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    width: 220px;
    height: 50px;
    padding: 0px;
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    display: flex;
    margin: 0.3rem 0.5rem;
    border-radius: 25px;
}
.launchbtncus{
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 223, 255) 100%);
    border: none;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset, rgba(23, 0, 102, 0.2) 0px 20px 40px, rgba(0, 0, 0, 0.1) 0px 1px 3px;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    width: 180px;
    height: 50px;
    padding: 0px;
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    display: flex;
    margin: 0.3rem 0.5rem;
    border-radius: 25px;
}
.launchbtnwrapper {
    height: 45px;
    display: grid;
    grid-template-columns: 25px auto;
    padding: 12px;
    justify-items: start;
}
.launchbtnwrapper1 {
    height: 45px;
    display: grid;
    grid-template-columns: 25px auto;
    padding: 12px;
    justify-items: start;
}
.gvzymi svg:not(:root) {
    overflow: hidden;
}
#hhhhhheight{
    width: 38px;
    height: 38px;
}
.gvzymi {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 6px;
    left: 14px;
    transition: transform 0.3s ease-in-out;
}
.kRiXyW {
    position: absolute;
    width: 28px;
    height: 28px;
    background: linear-gradient(200.44deg,#000000 13.57%, #000000 98.38%);
    box-shadow: rgba(182, 153, 255, 0.3) 0px 10px 20px;
    border-radius: 50px;
    padding: 2px;
    margin: auto;
    top: 11px;
    left: 20px;
    color: #fff;
    font-size: 1rem;
}
.dJmyZy {
    margin: auto;
}
.cbypVx {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 4px;
    margin: auto auto auto 16px;
    text-align: left;
    text-transform:capitalize;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.3rem;
    line-height: 1.4;
}
.launchbtn:hover, .launchbtn1:hover,.launchbtncus:hover{
    box-shadow: rgba(250, 142, 0, 0.25) 0px 54px 55px, rgba(255, 102, 0, 0.12) 0px -12px 30px, rgba(255, 123, 0, 0.12) 0px 4px 6px, rgba(255, 102, 0, 0.17) 0px 12px 13px, rgba(255, 115, 0, 0.09) 0px -3px 5px;
    transform: scale(1.1)
}
.launchbtn:hover .gvzymi,.launchbtncus:hover .gvzymi{
    transform: scale(1.1) rotate(50deg);
}
.launchbtn1:hover .gvzymi1{
    transform: scale(1.04) rotate(10deg);
}
.launchbtn1:hover  .kRiXyW1,.launchbtn:hover  .kRiXyW{
    background: linear-gradient(200.44deg,#000000 13.57%,#000000 98.38%);
    color: black;
    font-size: 1.1rem;
}
.launchbtn1{
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 223, 255) 100%);
    border: none;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset, rgba(23, 0, 102, 0.2) 0px 20px 40px, rgba(0, 0, 0, 0.1) 0px 1px 3px;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    width: 130px;
    height: 35px;
    padding: 0px;
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    display: flex;
    margin: 0.3rem 0.5rem;
}
.launchbtnwrapper3 {
    height: 47px;
    display: grid;
    grid-template-columns: 20px auto;
    padding: 8px;
    justify-items: start;
}
.launchbtnwrapper2 {
    height: 47px;
    display: grid;
    grid-template-columns: 18px auto;
    padding: 8px;
    justify-items: start;
}
.gvzymi1 {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 3px;
    left: 10px;
    transition: transform 0.3s ease-in-out;
}
.kRiXyW1 {
    position: absolute;
    width: 22px;
    height: 22px;
    background: linear-gradient(200.44deg,#000000 13.57%, #000000 98.38%);
    box-shadow: rgba(182, 153, 255, 0.3) 0px 10px 20px;
    border-radius: 50px;
    padding: 0px;
    margin: auto;
    top: 7px;
    left: 14px;
    color: #fff;
}
.dJmyZy1 {
    margin: auto;
    font-size: 0.3rem;
}
.cbypVx1 {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 4px;
    margin: auto auto auto 14px;
    text-align: left;
    text-transform:capitalize;
    font-weight: 800;
    font-size: 10px;
    letter-spacing: 2px;
}
#smallcirll{
    width: 30px;
    height: 30px;
}
.wp-block-groupbox{
    height: 9rem;
}
.has-small-font-size{
    font-size: 0.9rem;
}

/* .marketplace-class{
    height:calc(100vh - 40.5%) !important;
} */

.mkplace-class{
    height:calc(100vh - 35.5%) !important;
}

@media  screen and (min-width:4591px) {
    .service-leftcontent{
        height: calc(100vh - 45%) !important;
    }
    .marketplace-class{
        height:calc(100vh - 40.5%) !important;
    }
      .applicationcontainerbox{
        width: 48rem;
        height: 44.8rem;
    }
    .card {
        height: 84.5rem;
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
    .wp-block-groupbox{
        height: 54.5rem;
    }
    .wp-block-heading{
        font-size: 8rem;
    }
    .has-small-font-size{
        font-size: 4rem;
    }
    .thumbnail-image{
        width: 220px !important;
        height: 220px !important;
    }
    .card-content h1{
        font-size: 2.9rem;
    }
    .card-content p{
        font-size: 1.6rem;
    }
    .applicationcontainer{
        width: 60rem;
        height: 74.5rem;
        margin: 3rem;
    }
    .swiper-scrollbar {
        bottom:0.8rem;
      }
      .swiper-nav-btns button{
        margin-top: 1.2rem;
      }
}
@media  (max-width:4590px) and (min-width:2591px) {
    .service-leftcontent{
        height: calc(100vh - 45%) !important;
    }
    .marketplace-class{
        height:calc(100vh - 40.5%) !important;
    }
    .applicationcontainerbox{
        width: 38rem;
        height: 34.8rem;
    }
    .card {
        height: 54.5rem;
        margin-top: 3rem !important;
        margin-bottom: 2rem !important;
    }
    .wp-block-groupbox{
        height: 34.5rem;
    }
    .wp-block-heading{
        font-size: 4rem;
    }
    .has-small-font-size{
        font-size: 2rem;
    }
    .thumbnail-image{
        width: 120px !important;
        height: 120px !important;
    }
    .card-content h1{
        font-size: 2.4rem;
    }
    .card-content p{
        font-size: 1.3rem;
    }
    .applicationcontainer{
        width: 40rem;
        height: 54.5rem;
        margin: 3rem;
    }
    .swiper-scrollbar {
        bottom:0.8rem;
      }
      .swiper-nav-btns button{
        margin-top: 1.2rem;
      }
}
@media  (max-width:2590px) and (min-width:1989px) {
    .marketplace-class{
        height:calc(100vh - 40.5%) !important;
    }
    .applicationcontainerbox{
        width: 26rem;
        height: 22.8rem;
    }
    .card {
        height: 36.5rem;
        margin-top: 3rem !important;
        margin-bottom: 2rem !important;
    }
    .wp-block-groupbox{
        height: 24.5rem;
    }
    .wp-block-heading{
        font-size: 2.4rem;
    }
    .has-small-font-size{
        font-size: 1.5rem;
    }
    .thumbnail-image{
        width: 90px !important;
        height: 90px !important;
    }
    .card-content h1{
        font-size: 1.4rem;
    }
    .card-content p{
        font-size: 1.1rem;
    }
    .applicationcontainer{
        width: 28rem;
        height: 36.5rem;
        margin: 2.5rem;
    }
    .swiper-scrollbar {
        bottom:0.8rem;
      }
      .swiper-nav-btns button{
        margin-top: 1.2rem;
      }
}
@media  (max-width:1990px) and (min-width:1789px) {
    .marketplace-class{
        height:calc(100vh - 40.5%) !important;
    }
    .applicationcontainerbox{
        width: 24rem;
        height: 18.8rem;
    }
    .card {
        height: 30rem;
        margin-top: 3rem !important;
        margin-bottom: 2rem !important;
    }
    .wp-block-groupbox{
        height: 18.5rem;
    }
    .wp-block-heading{
        font-size: 1.95rem;
    }
    .has-small-font-size{
        font-size: 1.3rem;
    }
    .thumbnail-image{
        width: 80px !important;
        height: 80px !important;
    }
    .applicationcontainer{
        width: 26rem;
        height: 30.5rem;
        margin: 1.5rem;
    }
    .swiper-scrollbar {
        bottom:0.8rem;
      }
      .swiper-nav-btns button{
        margin-top: 1.2rem;
      }
}
@media  (max-width:1790px) and (min-width:1589px) { 
    .marketplace-class{
        height:calc(100vh - 40.5%) !important;
    }   
    .applicationcontainerbox{
        width: 22rem;
        height: 16.8rem;
    }
    .card {
        height: 29.5rem;
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    .wp-block-groupbox{
        height: 18.5rem;
    }
    .wp-block-heading{
        font-size: 1.85rem;
    }
    .has-small-font-size{
        font-size: 1.2rem;
    }
    .thumbnail-image{
        width: 80px !important;
        height: 80px !important;
    }
    .applicationcontainer{
        width: 24rem;
        height: 28.5rem;
        margin: 1.5rem;
    }
    .swiper-scrollbar {
        bottom:0.8rem;
      }
      .swiper-nav-btns button{
        margin-top: 1.2rem;
      }
}


@media  (max-width:1590px) and (min-width:1400px) and (max-height: 759px) and (min-height: 560px)  {
    
    
    .marketplace-class{
        height:calc(100vh - 40.5%) !important;
    }
    .card {
        height: 22rem !important;
        margin-top: 2rem !important;
    }

    .slick-slide{
          min-height: 20px !important;
    }
     .wp-block-groupbox{
        height: 12.5rem !important;
    }

    .slick-track {
        padding-top:0px !important;
    }

    .btnsergrid{
        margin-bottom: 0px !important;
        margin-top: 10px !important;
    }
    
}
@media  (max-width:1590px) and (min-width:1400px) {
    .slick-slide{
        min-height: 0px !important;
  }

    .solution-box-desk{
        height: 9.5rem !important;
    }
    .marketplace-class{
        height:calc(100vh - 40.5%) !important;
    }
    .applicationcontainerbox{
        width: 20rem;
        height: 15.8rem;
    }
    .card {
        height: 21.5rem;
    }
    .wp-block-groupbox{
        height: 13rem;
    }
    .wp-block-heading{
        font-size: 1.35rem;
    }
    .has-small-font-size{
        font-size: 1.1rem;
    }
    .applicationcontainer{
        width: 22rem;
        height: 26.5rem;
    }
    .swiper-scrollbar {
        bottom:0.8rem;
      }
      .swiper-nav-btns button{
        margin-top: 1.2rem;
      }
}
@media  (max-width:1400px) and (min-width:1200px) {
    .slick-slide{
        min-height: 0px !important;
  }
    .solution-box-desk{
        height: 9.5rem !important;
    }
    .soltn-detail-desktop{
        margin-bottom: 0rem !important;
        margin-top: 0.5rem !important;
    }
    .marketplace-class{
        height:calc(100vh - 40.5%) !important;
    }
   .applicationcontainerbox{
        width: 16rem;
        height: 10.8rem;
    }
    .card {
        height: 18.5rem;
    }
    .wp-block-groupbox{
        height: 11rem;
    }
    .wp-block-heading{
        font-size: 1.35rem !important;
    }
    .has-small-font-size{
        font-size: 0.8rem;
    }
    .applicationcontainer{
        width: 18rem;
        height: 20.5rem;
    }
    .swiper-scrollbar {
        bottom:0.8rem;
      }
      .swiper-nav-btns button{
        margin-top: 1.2rem;
      }
         .card-content h1{
        font-size: 0.9rem;
        margin: 0.1rem 0.3rem;
    }
    .card-content p{
        font-size: 0.6rem;
        margin: 0.1rem 0.3rem;
    }
    .launchbtn1 {
        width: 120px;
        height: 35px;
        margin: 0.3rem;}
}
@media  (max-width:1199px) and (min-width:1000px) {
    .marketplace-class{
        height:calc(100vh - 40.5%) !important;
    }
    .applicationcontainerbox{
        width: 14rem;
        height: 6.8rem;
    }
    .card {
        height: 16.5rem;
    }
    .applicationcontainer{
        width: 16rem;
        height: 16.5rem;
    }
         .card-content h1{
        font-size: 0.9rem;
        margin: 0.1rem 0.2rem;
    }
    .card-content p{
        font-size: 0.6rem;
        margin: 0.1rem 0.2rem;
    }
    .launchbtn1 {
        width: 100px;
        height: 35px;
        margin: 0.3rem;}
    }
@media (max-width: 930px) and (min-width:810px) {
    /* .launchbtn,.launchbtncus{
        height: 40px;
    } */
    /* .launchbtn{
        width: 120px;
    } */
    /* .launchbtncus{
        width: 95px;
    } */
    .launchbtnwrapper {
        height: 40px;
        padding: 10px;
    }
    .gvzymi {
        width: 30px;
        height: 30px;
    }
    .kRiXyW {
        width: 26px;
        height: 26px;
        top:8px;
        left: 16px;
        padding: 0px
    }
    .cbypVx {
        margin: auto 2px;
    }
    #hhhhhheight{
        width: 30px;
        height: 30px;
    }
    .launchbtn1{
        width: 90px;
        height: 25px;
        margin: 0.2rem 0.3rem;
    }
    .launchbtnwrapper2{
        height: 25px;
        padding: 9px;
    }
    .gvzymi1 {
        width: 12px;
        height: 12px;
        top: 1px;
        left: 7px;
    }
    #smallcirll{
        width: 20px;
        height: 20px;
    }
    .kRiXyW1 {
        width: 14px;
        height: 14px;
        top: 7px;
        left: 10px;
    }
    .kRiXyW1 svg{
        margin-bottom: 0.5rem;
    }
    .cbypVx1{
        margin: auto auto auto 2px;
        font-size: 9px;
    }
    .applicationcontainer{
        width: 14rem;
        height: 10rem;
    }
    .card {
        height: 10.8rem;
    }
    .applicationcontainerbox{
        width: 13rem;
        height: 4rem;
    }
    .card-content h1 {
        font-size: 0.6rem;
        font-weight: 700;
    }
    .card-content p {
        font-size: 0.5rem;
    }
}
@media (max-width:859px) and (min-width:768px) {
    .launchbtn1{
        width: 90px;
        height: 25px;
        margin: 0.2rem 0.3rem;
    }
    .launchbtnwrapper2{
        height: 25px;
        padding: 9px;
    }
    .gvzymi1 {
        width: 12px;
        height: 12px;
        top: 1px;
        left: 7px;
    }
    #smallcirll{
        width: 20px;
        height: 20px;
    }
    .kRiXyW1 {
        width: 14px;
        height: 14px;
        top: 7px;
        left: 10px;
    }
    .kRiXyW1 svg{
        margin-bottom: 0.5rem;
    }
    .cbypVx1{
        margin: auto auto auto 2px;
        font-size: 9px;
    }
    .applicationcontainer{
        width: 12rem;
        height: 13rem;
    }
    .card {
        height: 13rem;
    }
    .applicationcontainerbox{
        width: 11rem;
        height: 4rem;
    }
    .card-content h1 {
        font-size: 0.6rem;
        font-weight: 700;
    }
    .card-content p {
        font-size: 0.5rem;
    }
}
@media (max-width:767px) and (min-width:668px) {
    .launchbtn1{
        width: 90px;
        height: 25px;
        margin: 0.2rem 0.3rem;
    }
    .launchbtnwrapper2{
        height: 25px;
        padding: 9px;
    }
    .gvzymi1 {
        width: 12px;
        height: 12px;
        top: 1px;
        left: 7px;
    }
    #smallcirll{
        width: 20px;
        height: 20px;
    }
    .kRiXyW1 {
        width: 14px;
        height: 14px;
        top: 7px;
        left: 10px;
    }
    .kRiXyW1 svg{
        margin-bottom: 0.5rem;
    }
    .cbypVx1{
        margin: auto auto auto 2px;
        font-size: 9px;
    }
    .applicationcontainer{
        width: 11rem;
        height: 12rem;
    }
    .card {
        height: 12rem;
    }
    .applicationcontainerbox{
        width: 10.5rem;
        height: 3.5rem;
    }
    .card-content h1 {
        font-size: 0.6rem;
        font-weight: 700;
    }
    .card-content p {
        font-size: 0.5rem;
    }
}


@media (max-width:550px) and (min-width:476px){

    .eCommerce-system{
        height: calc(100vh - 32%) !important;
    }

    


}
/*mobile responsiveness*/
#sol_detail_mob_visible{
    visibility: hidden;
}

@media (max-width:500px){
    /* .service-leftcontent{
        height: calc(100vh - 45.5%) !important;
    } */

    .soltns-button-container{
        margin-top: 1rem !important;
    }
    /* .marketplace-class{
        height: calc(100vh - 22%)!important;
    } */
    .marketplace-class{
        height: calc(100vh -30%)!important;
    }
}
@media  (max-width:475px) and (min-width:200px) {

    .marketplace-class{
        height: calc(100vh -30%)!important;
    }
    .mob-para-soltns{
        font-size: 1rem !important;
    }
    .slider-solution-btn{
        margin-top: 0rem !important;
    }

    .solution-mobile-heading{
        font-size: 1.7rem !important;
    }
    #hhhhhheight {
        width: 35px;
        height: 35px;
    }
    .kRiXyW {
        width: 26px;
        height: 26px;
        left: 21px;
        padding: 0px;
    }    
    .launchbtncus,.launchbtn {
        height: 45px;
    }
.ggggg{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
}
#sol_detail_mob_hide{
    display: none;
}
#sol_detail_mob_visible{
    visibility: visible;
}
}
.ggggg{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
}

@media (max-width:475px) and (min-width:375px){
    .marketplace-class{
        height: calc(100vh -30%)!important;
    }
    .buttons-service-detail{
        flex-wrap: nowrap !important;
        margin-bottom: 2.5rem !important;
    }
    .solution-mobile-heading{
        font-size: 1.7rem !important;
    }
    .applicationcontainerbox {
        width: 22rem;
        height: 7.8rem;
    }
    .applicationcontainer {
        width: 22rem;
        height: 19.5rem;
        border: 1px solid #fff;
    }
    .launchbtn1{
        width: 147px;
        height: 45px;  
        margin: 0.3rem auto;
    }
    .launchbtnwrapper2 {
        height: 50px;
        grid-template-columns: 25px auto;
        padding: 8px;
    }
    .gvzymi1 {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 7px;
        left: 0px;
        transition: transform 0.3s ease-in-out;
    }
    .kRiXyW1 {
        position: absolute;
        width: 24px;
        height: 24px;
        top:10px;
    }
    .cbypVx1 {
        margin: auto auto auto 11px;
        font-weight: 800;
        font-size: 14px;
    }
    .mob701down{
      margin-top: 0.5rem;
    }
    @media (max-height: 900px) and (min-height:801px){
        .applicationcontainerbox {
            width: 22.5rem;
            height: 14.8rem;
        }
        .applicationcontainer {
            width: 22.5rem;
            height: 32.5rem;
            border: 1px solid #fff;
        }
        #applicationpadding{
            padding: 2rem !important;
        } 
        .card-content p {
            font-size: 1rem;
            margin: 0.1rem 0.8rem;
            text-align: left;
        }
        .card-content h1 {
            font-size: 1.5rem;
            margin: 0.1rem 0.8rem;
            text-align: left;
        }
        .mobilepara {
            font-size: 1.2rem;
            margin-bottom: 0rem;
            margin-inline: 0.5rem;
        }
        #faqiconmain{
            margin-left: 22vw;
            height: 50px !important;
            width: 50px !important;
        }
        .portgi{
            margin-left: 15vw !important;
        }
    }
    @media (max-height: 800px) and (min-height:701px){
        .applicationcontainerbox {
            width: 22.5rem;
            height: 9.8rem;
        }
        .applicationcontainer {
            width: 22.5rem;
            height: 26.5rem;
            border: 1px solid #fff;
        } 
        .card-content p {
            font-size: 1rem;
            margin: 0.1rem 0.8rem;
            text-align: left;
        }
        .card-content h1 {
            font-size: 1.5rem;
            margin: 0.1rem 0.8rem;
            text-align: left;
        }
        .mobilepara {
            font-size: 1.2rem;
            margin-bottom: 0rem;
            margin-inline: 0.5rem;
        }
        #faqiconmain{
            margin-left: 22vw;
            height: 50px !important;
            width: 50px !important;
        }
        .portgi{
            margin-left: 15vw !important;
        }
    }
}

@media (max-width:376px) and (min-width:320px){
    .faq-buttons-mobile{
        bottom: 9% !important;

    }
    .marketplace-class{
        height: calc(100vh -30%)!important;
    }
    .solution-mobile-heading{
        font-size: 1.7rem !important;
    }
    .applicationcontainerbox {
        width: 19rem;
        height: 6.8rem;
    }
    .applicationcontainer {
        width: 19rem;
        height: 18.5rem;
        border: 1px solid #fff;
    }
}
@media (min-height: 901px) and (max-height: 1200px) and (max-width:375px) and (min-width:321px) {
    .applicationcontainerbox {
        width: 22rem;
        height: 12.8rem;
    }
    .applicationcontainer {
        width: 22rem;
        height: 30.5rem;
    }
    .card-content p {
        font-size: 1.2rem;
        margin: 0.1rem 0.8rem;
        text-align: left;
    }
    #faqiconmain{
        margin-left: 18vw;
    }
    .portgi{
        margin-left: 13vw !important;
    }
}
@media (min-height: 801px) and (max-height: 900px) and (max-width:375px) and (min-width:321px) {
    .applicationcontainerbox {
        width: 22rem;
        height: 10.8rem;
    }
    .applicationcontainer {
        width: 22rem;
        height: 28.5rem;
        border: 1px solid #fff;
    }
    .card-content p {
        font-size: 1rem;
        margin: 0.1rem 0.8rem;
        text-align: left;
    }
    .card-content h1 {
        margin: 0.1rem 0.8rem;
    }
    #faqiconmain{
        margin-left: 18vw;
    }
    .portgi{
        margin-left: 13vw !important;
    }
}
@media (min-height: 701px) and (max-height: 800px) and (max-width:375px) and (min-width:321px) {
    .applicationcontainerbox {
        width: 22rem;
        height: 8.8rem;
    }
    .applicationcontainer {
        width: 22rem;
        height: 24.5rem;
        border: 1px solid #fff;
    }
    .card-content p {
        font-size: 1rem;
        margin: 0.1rem 0.7rem;
        text-align: left;
    }
    .card-content h1{
        margin: 0.1rem 0.7rem;
    }
    #faqiconmain{
        margin-left: 18vw;
    }
    .portgi{
        margin-left: 13vw !important;
    }
}
@media (min-height: 601px) and (max-height: 700px) and (max-width:375px) and (min-width:321px) {
    .applicationcontainerbox {
        width: 22rem;
        height: 7.8rem;
    }
    .applicationcontainer {
        width: 22rem;
        height: 22.5rem;
    }
    .card-content p {
        font-size: 1rem;
        margin: 0.1rem 0.5rem;
        text-align: left;
    }
    #faqiconmain{
        margin-left: 18vw;
    }
    .portgi{
        margin-left: 13vw !important;
    }
}

@media (max-width: 371px) and (min-width:320px) {
    .solution-mobile-heading{
        font-size: 1.7rem !important;
    }
    .mob-701{
        font-size: 1.5rem !important;
    }
}
@media (max-width:321px) and (min-width:200px){
    .solution-mobile-heading{
        font-size: 1.7rem !important;
    }
    .applicationcontainerbox {
        width: 18.5rem;
        height: 6.8rem;
    }
    .applicationcontainer {
        width: 18.5rem;
        height: 22.5rem;
        border: 1px solid #fff;
        padding: 1rem;
    }
    .card-content p {
        font-size: 0.8rem;
        margin: 0.1rem 1.2rem;
        text-align: left;
    }
    .card-content h1 {
        margin: 0.1rem 1.2rem;
    }
    #faqiconmain{
        margin-left: 17vw;
    }
    .portgi{
        margin-left: 13vw !important;
    }
}