.sidebarcontainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3rem;
}
.background {
  position: absolute;
  top: -11px;
  right: -16px;
  bottom: 0;
  width: 70px;
}
.MenuTogglebtn {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: -10px;
  width: 50px !important;
  height: 58px;
  left: 0px;
  /* border-radius: 50%; */
  /* background-color: transparent; */
  background-color: black;

  /* transform: skew(-19deg);
  text-transform: uppercase; */
  background-color: #000;
  color: #ffffff;
  border: none;
  font-size: 1.2rem;
  margin: 0;
  border-radius: 0px;
  /* width: 8.5rem; */
  text-align: center;
  padding: 0.5rem;
  padding-top: 15px;
}

/* .btn {
  transform: skew(-19deg);
  text-transform: uppercase;
  background-color: #000;
  color: #ffffff;
  border: none;
  font-size: 1.2rem;
  margin: 0;
  border-radius: 0px;
  width: 8.5rem;
  text-align: center;
  padding: 0.5rem;
} */


.hiddbttn {
  stroke: #fff;
}
.disbttn {
  stroke: #fff;
}
.open{
  visibility: hidden;
}
.close{
  visibility: visible;
}
.bgblacknone,
.bgblack,
li {
  margin: 0;
  padding: 0;
}


.bgblack{
  /* overflow: none !important; */
  /* height: fit-content !important; */
  height: 95vh !important;
  overflow: auto;
  padding-bottom: 4rem !important;
}
.bgblacknone {
  padding: 16px;
  position: absolute;
  top: 57px;
  width: 250px;
  border-radius: 10px 10px;
  background-color: aqua;
  display: none;
  /*height: 85.3vh;*/
  height: calc(100vh - 88px);
  overflow: auto;
  z-index: 3500;
}
.bgblack {
  background-color: black;
  padding: 16px;
  position: absolute;
  top: 59px;
  width: 20vw;
  display: block;
  /*height: 85.3vh;*/
  height: calc(100vh - 88px);
  overflow: auto;
  z-index: 9999;
}

.bgblacknone li,
.bgblack li {
  list-style: none;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bgblacknone li .icon-placeholder,
.bgblack li .icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 10px;
  padding: 2px 4px;
  font-size: 1.2rem;
}

.bgblacknone li .text-placeholder,
.bgblack li .text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 40px;
  flex: 1;
  padding: 7px 10px;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bgblack::-webkit-scrollbar,
.bgblacknone::-webkit-scrollbar {
  width: 0em;
}
.bgblack::-webkit-scrollbar-thumb,
.bgblacknone::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@media screen and (min-width: 4591px) {
  .sidebarcontainer {
    width: 238px;
  }
  .bgblack,
  .bgblacknone {
    width: 725px;
    padding: 20px 16px;
    top: 198px;
    height: calc(100vh - 10vh);
  }
  .bgblack li {
    margin-bottom: 20px;
  }
  .background {
    position: absolute;
    top: 45px;
    right: 100px;
    bottom: 0;
    width: 87px;
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 223, 255) 100%);
    clip-path: circle(43px at 40px 40px) !important;
  }
  .MenuTogglebtn {
    top: 63px;
    right: 124px;
  }
  .MenuTogglebtn svg {
    width: 50px !important;
    height: 50px !important;
  }
  .bgblack li .text-placeholder {
    border-radius: 5px;
    width: 200px;
    height: 150px;
    flex: 1 1;
    padding: 40px 10px;
    color: rgb(255, 255, 255);
    font-size: 2.9rem;
  }
  .bgblack li .icon-placeholder {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    flex: 70px 0;
    margin-right: 10px;
    padding: 3px 4px;
    font-size: 1.8rem;
  }
  #icon-placeholdersvg {
    width: 50px !important;
    height: 50px !important;
  }
}

@media (max-width: 4590px) and (min-width: 2591px) {
  .sidebarcontainer {
    width: 48px;
  }
  .bgblack,
  .bgblacknone {
    width: 355px;
    padding: 20px 16px;
  }
  .bgblack li {
    margin-bottom: 20px;
  }
}

@media (max-width: 2590px) and (min-width: 1989px) {
  .sidebarcontainer {
    width: 48px;
  }
  .bgblack,
  .bgblacknone {
    width: 355px;
    padding: 20px 16px;
  }
  .bgblack li {
    margin-bottom: 20px;
  }
}

@media (max-width: 1990px) and (min-width: 1789px) {
  .sidebarcontainer {
    width: 48px;
  }
  .bgblack,
  .bgblacknone {
    width: 351px;
    padding: 20px 16px;
  }
  .bgblack li {
    margin-bottom: 20px;
  }
}

@media (max-width: 1790px) and (min-width: 1589px) {
  .sidebarcontainer {
    width: 45px;
  }
  .bgblack,
  .bgblacknone {
    width: 20.6vw;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) {
  .sidebarcontainer {
    right: 18%;
  }
  .bgblack,
  .bgblacknone {
    height: 36.7rem;
    width: 18vw;
  }
}
@media (max-width: 1300px) and (min-width: 1200px) {
  .sidebarcontainer {
    right: 22%;
  }
  .bgblack,
  .bgblacknone {
    width: 22vw;
  }
}
@media (max-width: 1199px) and (min-width: 1000px) {
  .sidebarcontainer {
    right: 25%;
  }
  .bgblack,
  .bgblacknone {
    width: 25vw;
  }
  .mobiledisplay {
    display: flex;
    background-image: -webkit-linear-gradient(
      55deg,
      #2b2b2b00 50%,
      #000000 50%
    );
    margin: 0rem;
    height: 3.5rem;
  }
}
@media (max-width: 1199px) and (min-height: 671px) {
  .bgblack,
  .bgblacknone {
    height: 87.1vh;
    width: 25vw;
  }
}
@media (max-width: 999px) and (min-width: 800px) {
  .sidebarcontainer {
    right: 18%;
  }
  .bgblack,
  .bgblacknone {
    height: 19.4rem;
    width: 28vw;
  }

  .mobiledisplay {
    display: flex;
    background-image: none;
    margin: 0rem;
    height: 3.5rem;
  }
  .navbar-collapse {
    display: none !important;
}
}

/*tablet responsiveness*/
@media (max-width: 775px) and (min-width: 676px) {
  .sidebarcontainer {
    top: -3px;
  }
  .bgblack {
    top: 57px;
    height: 78.5vh;
    width: 29vw;
  }
  .bgblack li .text-placeholder {
    font-size: 0.8rem;
  }
}
@media (max-width: 675px) and (min-width: 576px) {
  .sidebarcontainer {
    top: -3px;
  }
  .bgblack {
    top: 54px;
    height: 81.5vh;
    width: 29vw;
  }
}
@media (max-width: 575px) and (min-width: 476px) {
  .sidebarcontainer {
    top: -3px;
  }
  .bgblack {
    top: 54px;
    height: 81.5vh;
    width: 29vw;
  }
}
/*mobile responsiveness*/
@media (max-width: 475px) and (min-width: 376px) {
  .MenuTogglebtn {
  width: 40px !important;
  height: 58px !important;
  padding-top: 10px !important;
}


  .MenuTogglebtn svg {
    width: 25px !important;
    height: 45px !important;
  }

  .sidebarcontainer {
    top: 0px;
    width: 2rem;
  }
  .bgblack {
    top: 60px;
    height: calc(100vh - 121px);
    width: 56vw;
  }
  .background {
    clip-path: circle(16px at 40px 40px) !important;
  }
  .menutogglehideonmob {
    visibility: hidden;
  }
  /* .MenuTogglebtn {
    top: 10px;
    right: -12px;
    width: 40px;
    height: 40px;
  } */
}
@media (max-height: 1200px) and (min-height: 901px) and (max-width: 475px) and (min-width: 376px) {
  .bgblack {
    top: 60px;
    height: calc(100vh - 121px);
    width: 76vw;
  }
  .bgblack li .icon-placeholder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    flex: 50px 0;
    margin-right: 10px;
    padding: 7px 9px;
    font-size: 1.2rem;
  }
  .bgblack li .text-placeholder {
    border-radius: 5px;
    width: 217px;
    height: 50px;
    padding: 9px 10px;
    font-size: 1rem !important;
  }
  .bgblack li {
    margin-bottom: 27px;
  }
}
@media (max-height: 800px) and (min-height: 601px) and (max-width: 475px) and (min-width: 376px) {
  .bgblack {
    top: 60px;
    height: calc(100vh - 121px);
    width: 64vw;
  }
  .text-placeholder{
    font-size: 1rem !important;
  }
}
@media (max-height: 900px) and (min-height: 801px) and (max-width: 475px) and (min-width: 376px) {
  .text-placeholder{
    font-size: 1rem !important;
  }
  .bgblack {
    top: 60px;
    height: calc(100vh - 114px);
    width: 71vw;
  }
  .sidebarcontainer {
    top: 0px;
  }
  .bgblack li .icon-placeholder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    flex: 50px 0;
    margin-right: 10px;
    padding: 7px 9px;
    font-size: 1.2rem;
  }
  .bgblack li .text-placeholder {
    border-radius: 5px;
    width: 217px;
    height: 50px;
    padding: 9px 10px;
    /* font-size: 1.2rem; */
  }
  .bgblack li {
    margin-bottom: 24px;
  }
}

@media (max-height: 600px) and (max-width: 475px) and (min-width: 376px) {
  .bgblack {
    top: 60px;
    height: calc(100vh - 122px);
    width: 64vw;
  }
  .text-placeholder{
    font-size: 1rem !important;
  }


}
@media (max-width: 375px) and (min-width: 321px) {
  .sidebarcontainer {
    top: 0px;
    width: 2rem;
  }
  .bgblack {
    top: 53px;
    height: calc(100vh - 23vh);
    width: 64vw;
  }
  .text-placeholder{
    font-size: 1rem !important;
  }
  .bgblacknone li .text-placeholder,
  .bgblack li .text-placeholder {
    font-size: 1rem;
  }
  .bgblack li {
    margin-bottom: 20px;
  }
  .background {
    clip-path: circle(15px at 40px 40px) !important;
  }
  /* .MenuTogglebtn {
    top: 10px;
    right: -12px;
    width: 40px;
    height: 40px;
  } */






}
@media (max-height: 1200px) and (min-height: 901px) and (max-width: 375px) and (min-width: 321px) {
  .bgblack {
    top: 60px;
    width: 76vw;
    height: calc(100vh - 122px);
  }
  .bgblack li .icon-placeholder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    flex: 50px 0;
    margin-right: 10px;
    padding: 7px 9px;
    font-size: 1.2rem;
  }
  .bgblack li .text-placeholder {
    border-radius: 5px;
    width: 217px;
    height: 50px;
    padding: 9px 10px;
    /* font-size: 1.1rem; */
    font-size: 1rem !important;
  }
  .bgblack li {
    margin-bottom: 27px;
  }
}
@media (max-height: 900px) and (min-height: 601px) and (max-width: 375px) and (min-width: 321px) {
  .bgblack {
    top: 60px;
    width: 70vw;
    height: calc(100vh - 122px);
  }
}
@media (max-height: 600px) and (max-width: 375px) and (min-width: 321px) {
  .bgblack {
    top: 60px;
    width: 72vw;
    height: calc(100vh - 165px);
  }
}
@media (max-width: 320px) and (min-width: 200px) {
  .sidebarcontainer {
    top: -6px;
    width: 2rem;
  }
  .bgblack {
    top: 60px;
    height: calc(100vh - 109px);
    width: 76vw;
  }
  .background {
    clip-path: circle(15px at 40px 40px) !important;
  }
  .MenuTogglebtn {
    top: 10px;
    right: -12px;
    width: 40px;
    height: 40px;
  }
}
@media (max-height: 1200px) and (min-height: 901px) and (max-width: 320px) and (min-width: 200px) {
  .bgblack {
    top: 53px;
    height: calc(100vh - 109px);
    width: 76vw;
  }
}
@media (max-height: 900px) and (min-height: 601px) and (max-width: 320px) and (min-width: 200px) {
  .bgblack {
    top: 53px;
    height: calc(100vh - 109px);
    width: 76vw;
  }
}
@media (max-height: 600px) and (max-width: 320px) and (min-width: 200px) {
  .bgblack {
    top: 53px;
    height: calc(100vh - 109px);
    width: 76vw;
  }
}
