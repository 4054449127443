
/* .policy-content{
  height: calc(100vh - 40%) !important;
} */
/* .career-btns-div{

  bottom: 8%  !important;

} */

@media (max-width: 1880px){
  .policy-content {
    height: 27rem !important;
  }

 
}


@media (max-width: 1400px) and (min-width: 1200px) {
  @media (max-height:800px ) and (min-height:600px){

    .policy-content {
      height: 22rem !important;
    }
  }
  
}

@media(max-width :600px){
  .policy-content {
    height: 24rem !important;
  }
}

@media (max-width: 486px) and (min-width: 376px) {

  .priv-policy-heading-mob{
    margin-left: 2rem !important;
  }
    .policy-content {
      width: 90% !important;
      height: 21rem !important;
    }
  
    .mobilepara-privacy {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }


@media (max-width: 375px) and (min-width: 321px) {
  .policy-content {
    width: 90% !important;
  }

  .mobilepara-privacy {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 320px) and (min-width: 200px) {
  .policy-content {
    width: 90% !important;
  }

  .mobilepara-privacy {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
