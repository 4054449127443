.navbar-nav {
    display: flex;
    align-items: center;
    height: auto; /* Adjust the height as needed */
  }
  
  .nav-item {
    position: relative;
    padding: 0 2rem;
  }
  
  .nav-link {
    color: rgb(212, 214, 214) !important;
    position: relative;
    font-size: 2rem;
    line-height: 2.6rem;
  }
  .navbar{
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  
  .nav-link.active {
    color: #ff6600 !important; /* Set the color for the active state */
  }
  .navbar-brand{
    padding-top: 0rem;
    padding-bottom: 0rem;
  } 
  .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200%; /* Adjust the width as needed */
    height: 3px;
    background-color: #ff6600;
    border-radius: 25px;
  }
  
  .nav-item:hover .nav-link svg {
    filter: drop-shadow(0 0 10px #ff6600); /* Adjust the shadow color and size as needed */
  }
  
  /* .bg-glass {
    background-color:transparent;
    border-bottom: 2px solid rgba(249, 252, 253, 0.23);
  } */

  .bg-glass {
  background-color: transparent;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to right, rgba(249, 252, 253, 0.23), transparent) 1;
}


  #dots {
    font-size: 1.4rem;
    color: rgb(24, 24, 24);
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    border:none;
    outline: none;
    background: #F5F6F7;
    margin-top: 12px;
  }
  #dots:hover{
    background-color: #CCD0D5;
  }
  #dotsactive{
    font-size: 1.4rem;
    color: #000;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    border:none;
    outline: none;
    background: #000;
    margin-top: 12px;
  }
  .logo-container {
    position: relative;
    /* Ensure proper stacking context */
    z-index: 1;
    /* Prevent any transform glitches */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 240px; /* Set the width and height as needed */
    height: 50px;
    margin-left: 3.6rem;
    display: flex;
  }
  .logo-container img{
    /* Prevent Safari's image rendering quirks */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    /* Ensure image doesn't create new stacking context */
    position: relative;
    z-index: 2;
    /* Prevent any potential ghosting */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    object-fit: contain;
    transition: background-image 0.3s; 
    width: 3.2rem;
    height: 3.2rem;/* Add a smooth transition effect     display: none; */
  }
  /* .logo-container h3{
    display: none;
    position: relative;
    font-size: 2.2rem;
    color: #fff;
    line-height: 0.5 !important;
    margin-top: 0.2rem;
    letter-spacing: 0.25rem;
    overflow: hidden;
    background: linear-gradient(90deg,#0000, #ffffff44, #0000003a);
    background-repeat: no-repeat;
    animation: animate 0.8s linear infinite;
    background-size: 80%;
    padding-top: 0.35rem;
    margin-bottom: 0;
    font-weight:900;
    font-family: Source Sans\ 3, sans-serif;
  }
  @keyframes animate {
    0%{
      background-position: -500%;
    }
    100%{
      background-position: 500%;
    }

  }
  .logo-container:hover h3{
    display: block;
  } */


  .logo-container h3 {
    display: none;
    position: relative;
    font-size: 2.2rem;
    color: #fff;
    line-height: 0.5 !important;
    margin-top: 0.2rem;
    letter-spacing: 0.25rem;
    overflow: hidden;
    background: linear-gradient(90deg, #0000, #ffffff44, #0000003a);
    background-repeat: no-repeat;
    animation: animate 0.8s linear infinite;
    background-size: 80%;
    padding-top: 0.35rem;
    margin-bottom: 0;
    font-weight: 900;
    font-family: Source Sans\ 3, sans-serif;
    transition: opacity 0.5s ease-in-out, filter 0.5s ease-in-out; /* Fade-in/out transition */
    opacity: 0;
    filter: blur(10px); /* Initially blurred */
  }
  
  @keyframes animate {
    0% {
      background-position: -500%;
    }
    100% {
      background-position: 500%;
    }
  }
  
  .logo-container:hover h3 {
    display: block;
    opacity: 1; /* Fade in */
    filter: blur(0); /* Remove blur on hover */
  }
  
  .logo-container h3::before,
  .logo-container h3::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%; /* Adjust width for blur effect area */
    background: rgba(0, 0, 0, 0.5); /* Transparent black for blur effect */
    transition: opacity 0.5s ease-in-out, filter 0.5s ease-in-out; /* Fade and blur transition */
    filter: blur(10px); /* Apply blur */
    opacity: 0; /* Initially hidden */
  }
  
  .logo-container:hover h3::before,
  .logo-container:hover h3::after {
    opacity: 1; /* Fade in blur on hover */
  }
  
  .logo-container h3::before {
    left: 0;
    clip-path: inset(0 90% 0 0); /* Blur on the left */
  }
  
  .logo-container h3::after {
    right: 0;
    clip-path: inset(0 0 0 90%); /* Blur on the right */
  }
  
  
 
  
 

.logo-container h3 span {
    font-size: 0.45rem;
    letter-spacing: 0.1rem;
}
  /*.logo-container h3::before{
     content: "";
     position: absolute;
     width: 2.2rem;
     height: 3.2rem;
     background: #f3f3f342;
     box-shadow: 0 0 10px #ffffff49;
     filter: blur(1px);
     opacity: 0.9;
     top: -21px;
     transition: 0.7s;
     transform: rotate(-20deg) translateX(-60px);
     overflow: hidden;
  }
  .logo-container h3:hover:before{
    transform: rotate(-20deg) translate(140px,70px);
  }*/
  /*.logo-container h3:after{
   content: "DA TECH";
   position: absolute;
   top: 0;
   left: 0;
   background: linear-gradient(-45deg, transparent 40%,white 50%,transparent 10%);
   -webkit-background-clip: text;
   color: transparent;
   text-shadow: none;
   background-size: 200%;
   background-position: 150%;
   transition: .5s;
  }
  .logo-container h3:hover:after{
    background-position: -10%;
  }
  .logo-container:hover h3{
    font-size: 2.2rem;
    color: #fff;
    line-height: 0.5 !important;
    display: block;
    font-weight: bolder;
    margin-top: 0.7rem;
    letter-spacing: 0.25rem;
  }
  .logo-container h3 span{
    font-size: 0.45rem;
    letter-spacing: 0.1rem;
  }*/
  /* Change the background image on hover */
  /*.logo-container:hover {
    background-image: url('../../../public/whitelogo.png'); /* Replace with your hover image }*/
  .menubg{
    width: 17%;
    background-color: #ECF3FF;
    float: right;
    border-radius: 10px;
   position: relative;
   z-index: 1500;
  }
  .menusoption{
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    list-style: none;
    scroll-behavior: smooth;
    overflow: auto;
    height: 25.5rem;
  }
  .menusoption li{
    padding: 0.3rem;
    display: flex;
  }
  .menusoption li a{
    display: flex;
    text-decoration: none;
    color: #ff6600;
  }
  .menusoption li a h6{
    font-size: 0.8rem;
    margin-top: 0.7rem !important;
  }
  .menusoption li:hover, .menusoption li a:active{
    background-color: rgb(255, 94, 0.1);
    border-radius: 25px;
  }
  .icons{
    width: 2.5rem;
    height: 2.5rem;
    background-color: #e4e6eb;
    border-radius: 50%;
    font-size: 1.4rem;
  }
  #iconwidth {
    width: 100%;
    height: 3rem;
  }
  /*topcontainer*/
  .topcontainer{
    display: flex;
      justify-content: space-between;
      height: 1.8rem;
  }
  .email_icon {
    margin-top:10px;
    width: 0px;
    height: 20px;
    border-top: 20px solid white;
    /* 104 = 120 * 0.866 */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    background-color:orange;
    transition:.3s;
    padding:0;
    margin:0;
    position:relative;
  }
  .email_icon:before {
    padding:0;
    margin:0;
    content:"";
    position:absolute;
    top:-21px;
    left:-10px;
    border-top:19px solid orange;
    /* 104 = 120 * 0.866 */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    
    transition: .1s;
  }
  
  .email_icon:hover {
     background-color:orange;
     }
  
  .email_icon:hover:before {
    position:absolute;
    top:-41px;
    left:-10px;
    border-bottom: 20px solid orange;
    /* 104 = 120 * 0.866 */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top:0px;
    transition: .1s;
  }
  .facebook-icon:hover {
    /* Add your hover effect styles here */
    transform: scale(1.1); /* Example: Enlarge the icon on hover */
    cursor: pointer; /* Change the cursor on hover */
    /* Add any other hover styles you want #828282*/
  }
  .btn{
    transform: skew(-19deg);
    text-transform:uppercase;
    background-color: #000;
    color: #ffffff;
    border: none;
    font-size: 1.2rem;
    margin: 0;
    border-radius: 0px;
    width: 8.5rem;
    text-align: center;
    padding: 0.5rem;
  }

  .logo-container{

    width: 257px !important;

  }
  /* .btn {
    position: relative;
    text-transform: uppercase;
    background-color: #000;
    color: #ffffff;
    border: none;
    font-size: 1.2rem;
    margin: 0;
    border-radius: 0;
    width: 8.5rem;
    text-align: center;
    padding: 0.5rem;
    clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
  } */
  .btn:hover{
    color: #fff;
  }
  .btn div{
    transform: skew(21deg);
  }
  .btn div svg{
    transform: skew(-20deg) !important;
  }
  .mobilenone{
    position: relative;
    display: flex;
    background-image: -webkit-linear-gradient(-24deg, #2b2b2b00 30%, #000 70%);
    margin: 0rem;
    height: 3.6rem;
    width: 15.5rem;
  }
  #loginiconsize svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .fornav{
    display: flex;
    justify-content: center;
    list-style: none;
    margin-bottom: 0rem;
    font-size: 1rem;
    text-align: center;
    align-items: center;
    cursor: pointer;
  }
  .fornav li a{
    text-decoration: none;
    color: #fff;
  }
  .bottomNav-container{
    display: none;
  }
  
  @media  screen and (min-width:4591px) {
    .logo-container, .logo-container:hover {
      width: 750px;
      height: 180px;
      margin-left: 8.6rem;
    } 
    .logo-container img{
      object-fit: contain;
      transition: background-image 0.3s; 
      width: 12rem;
      height: 12rem;/* Add a smooth transition effect */
    }
    .logo-container:hover h3{
      font-size: 6.5rem;
      line-height: 5.5rem;
    }
    .logo-container h3 span{
      font-size: 1.5rem;
    }
    #screen33{
      width: 170px !important;
      height:170px !important;
    }
    .nav-item {
      position: relative;
      padding: 0 10rem;
  }
  .btn{
    height: 12rem;
    width: 15rem;
    font-size: 2.5rem;
    padding: 3.5rem 1rem !important;
  }
  .mobilenone {
    height: 12rem;
  }
  .topcontainer {
    height: 3.8rem;
  }
  .topcontainer a svg,.topcontainer a i{
    width: 36px !important;
    height: 36px !important;
    font-size: 36px !important;
  }
  .fornav {
    font-size: 2.8rem;
  }
  .fornav li{
    padding-inline: 5.5rem !important;
  }
  }
  @media  (max-width:1590px) and (min-width:1400px) and (max-height: 759px) and (min-height: 560px)  {
     .fornav{
      font-size:1rem;
     }
  }
  @media  (max-width:1300px) and (min-width:1200px) {
    .menubg{
      width: 16%;
    }
    .menusoption{
      height: 20.5rem;
    }
    .menusoption li a h6{
      font-size: 0.6rem;
      margin: 0.6rem 0.5rem!important;
    }
    .icons{
      font-size: 1rem;
      width: 2rem;
      height: 2rem;
    }
    .menusoption li{
      padding: 0.2rem;
    }
    .topcontainer{
        height: 1.6rem;
    }
  }
  @media  (max-width:1199px) and (min-width:1000px) {
    .menubg{
      width: 18%;
    }
    .menusoption{
      height: 18rem;
    }
    .menusoption li a h6{
      font-size: 0.6rem;
      margin: 0.6rem 0.5rem!important;
    }
    .icons{
      font-size: 1rem;
      width: 2rem;
      height: 2rem;
    }
    .menusoption li{
      padding: 0.2rem;
    }
    .topcontainer{
      height: 1.6rem;
  }
  }
  @media screen and (max-width: 1000px) {
    .mobilenone{
      display: none;
    }
    #iconwidth {
      width: 100%;
      height: 2rem; /* Adjust the height as needed */
    }
    .navbar-nav {
      height: 11rem;
  }
  .menubg {
    width: 26%;
    top:5%;
  }
  .menusoption{
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    list-style: none;
    scroll-behavior: smooth;
    overflow: auto;
    height: 14rem;
  }
  .icons{
    width: 1.8rem;
    height: 1.8rem;
    background-color: #e4e6eb;
    border-radius: 50%;
    font-size: 0.8rem;
  }
  h6{
    font-size: 0.5rem;
  }
  .menubg h3{
    font-size: 1rem;
  }
  }
  @media (max-width: 768px) and (min-width:320px) {
    /* .mobilenone{
      display: flex;
    } */
    .menubg h3{
      font-size: 0.8rem;
    }
    .menusoption li{
      padding: 0.1rem;
      display: flex;
    }
    h6{
      font-size: 0.4rem;
    }
    .menusoption{
      height: 8rem;
    }
    .btn {
      line-height: 1 !important;
      padding: 0.6rem;
    }
  }
  @media (max-width:1000px) and (min-width:200px) {
    .navbar-collapse{
    display:none !important;
   }
    .footer-container{
      display: none;
    }
    .bottomNav-container{
      display: block;
      position:fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 2.8rem;
      background-color: rgba(249, 252, 253, 0.23);
      z-index: 1200;
    }
    .bottomNav-containernavul{
      list-style: none;
      display: flex;
      justify-content: center;
      left: 0;
      bottom: 0;
      margin: 0rem;
    }
   .navbar-collapse{
    display:none !;
   }
  }
    /*tablet responsiveness*/
    @media  (max-width:775px) and (min-width:476px) {
      .logo-container {
        width: 265px !important;
        height: 50px;
        margin-left: 2.6rem;
      }
        .navbar-collapse{
    display:none !important;
   }
      .btn {
        font-size: 1rem;
        padding: 0.7rem 1rem;
    }
    .mobiledisplay{
      /* display: flex;
      background-image: -webkit-linear-gradient(11deg, #2b2b2b00 50%, #000 50%);
      margin: 0rem;
      height: 3rem; */

      position: relative;
    display: flex;
    background-image: -webkit-linear-gradient(-24deg, #2b2b2b00 30%, #000 70%);
    margin: 0rem;
    height: 3.6rem;
    width: 17rem;
    }
    .bottomNav-containernavul .nav-item {
      position: relative;
      padding: 0 0.1rem;
  }
  .btn:hover{
    background-color: #000;
  }
  .bottomNav-container {
    display: block;
  }
    }
  /*mobile responsiveness*/
  @media  (max-width:475px) and (min-width:386px) {
   
      .navbar-collapse{
    display:none !important;
   }
    .logo-container h3 {
      font-size: 2.1rem;
      line-height: 0.6 !important;
    }
    .logo-container img{
      width: 3.5rem;
      height: 3.5rem;
    }
    .logo-container {
      width: 50px !important;
      height: 50px;
      margin-left: 2.6rem;
    }
  .mobiledisplay{
    display: flex;
    background-image: -webkit-linear-gradient(-24deg, #2b2b2b00 50%, #000 50%);
    margin: 0rem;
    height: 3.6rem;
    font-size: 1.2rem;
  }
  .btn {
    font-size: 1.1rem;
    padding: 0.6rem;
    font-weight: 900;
}
.btn p{
  margin-top: 0.6rem !important;
}
.btn:hover{
  background-color: #000;
}

  .bottomNav-container {
    height: 3.8rem;
    }
    .bottomNav-containernavul {
    padding-left: 0rem;
    display: flex;
    gap: 2.5rem;
    }
    .bottomNav-containernavul .nav-item {
    position: relative;
    padding: 0rem;
    height: 3.5rem;
    width: 3.5rem;
    }
    .nav-link{
    padding: 0.4rem 0;
    margin-inline: 0.2rem;
    }
.nav-link svg{
  width: 50px !important;
  height: 50px !important;
}
.nav-link.active::after {
  width: 0;
}
.bottomNav-containernavul .nav-item .active{
  border-bottom: 3px solid #000;
  height: 3.8rem;
}
  }
  @media (max-height: 900px) and (min-height:801px) and (max-width:475px) and (min-width:376px) {
      .navbar-collapse{
    display:none !important;
   }
    .logo-container h3 {
      font-size: 2.2rem;
      line-height: 0.6 !important;
      font-weight: 900;
    }
    .logo-container img{
      width: 3.2rem;
      height: 3.2rem;
    }
    .logo-container {
      width: 50px !important;
      /* width: 206px; */
      height: 51px;
      margin-left: 2.6rem;
    }
  .mobiledisplay{
    display: flex;
    background-image: -webkit-linear-gradient(-24deg, #2b2b2b00 60%, #000 50%) !important;
    /* background-image: -webkit-linear-gradient(-24deg, #2b2b2b00 50%, #000 50%) !important; */
    margin: 0rem;
    height: 3.6rem;
  }
  .btn {
    font-size: 0.85rem;
    padding: 0.5rem;
    width: 7rem;
    font-weight: 900;
}
.btn p{
  margin-top: 1rem !important;
}
}
  @media  (max-width:385px) and (min-width:353px) {
      .navbar-collapse{
    display:none !important;
      }
    .logo-container {
      width: 50px !important;
      /* width: 157px; */
      height: 46px;
      margin-left: 2.6rem;
    }
    .btn {
      font-size: 0.9rem;
      padding: 0.4rem;
      width: 83%;
      font-weight: 900;
  }
  .btn p{
    margin-top: 0.6rem !important;
  }
.btn:hover{
  background-color: #000;
}
.logo-container img{
  width: 3rem;
  height: 3rem;
}
.mobiledisplay{
display: flex;
background-image: -webkit-linear-gradient(-24deg, #2b2b2b00 50%, #000 50%);
margin: 0rem;
height: 3.6rem;
}
.btn {
font-size: 0.95rem;
padding: 0.7rem;
width: 92%;
}
.btn:hover{
background-color: #000;
}
.logo-container h3{
font-size: 1.4rem;
line-height: 0.65 !important;
}
.bottomNav-container {
height: 3.8rem;
}
.bottomNav-containernavul {
padding-left: 0rem;
display: flex;
gap: 1.5rem;
}
.bottomNav-containernavul .nav-item {
position: relative;
padding: 0rem;
height: 3.5rem;
width: 3.5rem;
}
.nav-link{
padding: 0.4rem 0;
margin-inline: 0.2rem;
}
.nav-link svg{
width: 50px !important;
height: 50px !important;
}
.logo-container h3 span{
  font-size: 0.35rem;
}
.nav-link.active::after {
  width: 0;
}
.bottomNav-containernavul .nav-item .active{
  border-bottom: 3px solid #000;
  height: 3.8rem;
}
  }
  @media  (max-width:352px) and (min-width:200px) {
      .navbar-collapse{
    display:none !important;
      }
    .logo-container {
      width: 50px !important;
      height: 28px !important;
      margin-left: 2.6rem;
    }
    .logo-container img{
      width: 2.2rem;
      height: 2.2rem;
    }
    .logo-container h3{
      font-size: 1rem;
    }
    .logo-container h3 span {
      font-size: 0.24rem;
      letter-spacing: 0.05rem;
  }
    .btn {
      font-size: 0.9rem;
      padding: 0.2rem 0.5rem;
      width: 88%;
      font-style: 900;
  }
  .btn p{
    margin-top: 0.7rem !important;
  }
  .mobiledisplay{
    display: flex;
    background-image: -webkit-linear-gradient(11deg, #2b2b2b00 50%, #000 50%);
    margin: 0rem;
    height: 2.8rem;
  }
.bottomNav-container {
  height: 3.8rem;
  }
  .bottomNav-containernavul {
  padding-left: 0rem;
  display: flex;
  gap: 1rem;
  }
  .bottomNav-containernavul .nav-item {
  position: relative;
  padding: 0rem;
  height: 3.5rem;
  width: 3.5rem;
  }
  .nav-link{
  padding: 0.4rem 0;
  margin-inline: 0.2rem;
  }
  .nav-link svg{
  width: 50px !important;
  height: 50px !important;
  }
  .nav-link.active::after {
    width: 0;
  }
  .bottomNav-containernavul .nav-item .active{
    border-bottom: 3px solid #000;
    height: 3.8rem;
  }
.btn:hover{
  background-color: #000;
}
  }
 
/* Safari-specific fixes */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .logo-container img {
      /* Additional Safari-only fixes */
      transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);
    }
  }
}
 