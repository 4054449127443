.servicemargin {
  margin-top: 0.5rem;
}

/* .gallery-container{
    height: 85%;
} */
/*carousel start*/
.image-carousel-container {
  height: 430px; /* Adjust height as per your need */
  overflow: hidden;
  position: relative;
}

.image-carousel {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite; /* Adjust timing */
  height: 100%;
}
.image-carousel2 {
  display: flex;
  flex-direction: column;
  animation: scrollDown 15s linear infinite; /* Adjust timing */
  height: 100%;
}
.carousel-image-wrapper {
  margin-bottom: 10px;
}

.services-height {
  height: calc(100vh - 22%) !important;
}
.ads-mgmt {
  height: calc(100vh - 17%) !important;
}

.social-media-mkt {
  height: calc(100vh - 18%) !important;
}

.ecommerce-mgmt {
  height: calc(100vh - 27%) !important;
}

.swiper-slide{
  /* height:15rem !important; */
}

@keyframes scrollUp {
  0% {
    transform: translateY(0); /* Start from bottom */
  }
  100% {
    transform: translateY(-100%); /* Move to top */
  }
}
@keyframes scrollDown {
  0% {
    transform: translateY(-50%); /* Start at top of the second set of images */
  }
  100% {
    transform: translateY(0); /* End at the bottom of the first set of images */
  }
}
.image-carousel:hover,
.image-carousel2:hover {
  animation-play-state: paused;
  cursor: pointer;
}

/* Image size */
.image-standard-size {
  width: 100px; /* Adjust as per need */
  height: auto;
}

/*carousel end*/

.image-containerservice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-imgservice {
  width: 100px; /* Enough width to show images properly */
  height: 400px; /* Ensure height can fit 4 slides */
  overflow: hidden;
  margin-bottom: 40px;
}
.small-imgservice .SwiperSlidewrapperservie {
  width: 100%;
  height: 100px !important;
}

.large-imgservice {
  text-align: center;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 400px;
}

.blur-container1 {
  position: relative;
  overflow: hidden;
}

.blur-effect1 {
  /* Create a mask that has a blur effect on the top and bottom */
  mask-image: linear-gradient(
    to bottom,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
}

.blur-effect-para {
  /* Mask only the bottom with a blur effect */
  mask-image: linear-gradient(to bottom, white 80%, transparent);
  -webkit-mask-image: linear-gradient(to bottom, white 80%, transparent);
}

.image-standard-size {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Use contain or cover as per your requirement */
}

.Servicecard {
  width: 20rem;
  height: 10.9rem;
  margin: 0.5rem;
  background-color: #333;
  box-shadow: 0 4px 20px -5px rgba(8, 52, 82, 0.12),
    0 20px 30px rgba(8, 52, 82, 0.08);
  padding: 0.2rem 0.5rem;
  border-radius: 12px;
  cursor: pointer;
  font-family: "CFNotche-Bold", sans-serif;
  /* transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1), border 0.8s cubic-bezier(0.075, 0.82, 0.165, 1); */
  /* Add border transition */
}
.Servicecard:hover {
  background-image: -webkit-linear-gradient(35deg, #000000 65%, #1b1811 35%);
  border: 1px solid #fff;
  transform: scale(1.1);
}

.Servicecard:hover p {
  color: #fff;
}
.Servicecard h5 {
  font-size: 1rem;
  text-align: left;
  font-weight: 800;
  color: #fff;
}
.Servicecard p {
  height: 5rem;
  font-size: 0.8rem;
  text-align: left;
  color: #878787;
}
.Servicecard1 {
  width: 19.3rem;
  height: 4.5rem;
  margin: 0.5rem;
  background-color: #333;
  box-shadow: 0 4px 20px -5px rgba(8, 52, 82, 0.12),
    0 20px 30px rgba(8, 52, 82, 0.08);
  padding: 0.6rem 0.2rem;
  border-radius: 12px;
  cursor: pointer;
  font-family: "CFNotche-Bold", sans-serif;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.Servicecard1fronticon {
  width: 100%;
  height: 100%;
}
.Servicecard1fronticon svg {
  width: 30px !important;
  height: 30px !important;
}
.Servicecard1 .Servicecard1back {
  display: none;
}
.Servicecard1:hover {
  transform: scale(1.1);
  background-color: #1b1811;
  /* background-image: url('/public/wavesNegative.svg'); */
  background-image: -webkit-linear-gradient(35deg, #000000 65%, #1b1811 35%);
  background-size: 100%;
}
.Servicecard1:hover .Servicecard1front {
  display: none;
}
.Servicecard1:hover .Servicecard1back {
  display: block;
}

.Servicecard button {
  border: none;
  background-color: #ffffff;
  margin-right: auto;
  color: rgb(247, 134, 48);
  margin-left: auto;
}
.Servicecard1 h5 {
  font-size: 1.7rem;
  font-weight: 700;
}

.Servicecard1 p {
  font-size: 0.75rem;
  text-align: center;
  color: #ffffff;
  padding-inline: 1rem;
}
.fixedcontainer1{
  bottom: 4% !important;
}

.career-btns-div{

  bottom: 6%  !important;

}

.btnsgrid-container-services{
  margin-bottom: 0rem !important;
}
@keyframes wavy {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

.Servicecard1.wavy-animation {
  animation: wavy 0.5s infinite;
}
.btnsergridcontainer {
  display: flex;
}

/* .home-para{
    height:calc(100vh - 55%) !important;
} */
.btnsergrid,
.btncatgrid {
  width: 50%;
  height: 3rem;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  gap: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.btnsergrid a,
.btncatgrid a {
  width: 3rem;
  height: 3rem;
  background-color: #3333334d;
  padding: 0.5rem 0.6rem;
  border-radius: 50%;
  z-index: 1200;
}
#backbtn {
  width: 3rem;
  height: 3rem;
  background-color: #3333334d;
  font-size: 1.5rem;
  padding: 0.1rem 0.7rem;
  border-radius: 50%;
  margin-top: 0.5rem;
}
.imgboxgif {
  width: 100%;
  height: 70%; /*26.5rem*/
  display: flex;
}

.imgboxgif img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.googleadlinkin {
  width: 3rem;
  height: 2.2rem;
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}
.googleadlinkin1 {
  width: 2rem;
  height: 0.9rem;
  display: flex;
  margin: 0.8rem -0.2rem;
}
.googleadlinkin img {
  width: 100%;
  height: 100%;
}
.marginRightforhowtos {
  margin-right: 0.3rem;
}
#servicemenuciricon {
  width: 35px;
  height: 35px;
}
.practicebox {
  width: 100%;
  height: 100%;
  position: relative;
}
.practicebox img {
  object-fit: contain;
  width: 100%; /* 80% of the container's width */
  height: 100%; /* 80% of the container's height */
  position: absolute;
  top: 0;
  left: 0;
}
.practicebox img:hover {
  opacity: 0.25;
}
.practicebox canvas {
  width: 50rem; /* 50% of the container's width */
  height: 30rem; /* 40% of the container's height */
  position: absolute;
  top: 0;
  left: 0;
}

.service-box-container {
  height: calc(100vh - 28%) !important;
}

.about-para {
  height: calc(100vh - 43%) !important;
}
@media screen and (min-width: 4591px) {
  .Servicecard {
    width: 74rem;
    height: 53rem;
    padding: 2rem;
    margin: 4rem;
    border-radius: 3rem;
  }
  .Servicecard h5 {
    padding-inline: 1.5rem;
    font-size: 5rem !important;
    margin-top: 5rem;
  }
  .Servicecard svg {
    margin-inline: 1rem;
    width: 120px !important;
    height: 120px !important;
  }
  .Servicecard1 {
    width: 76rem;
    height: 22rem;
    padding: 1.8rem;
    border-radius: 3rem;
  }
  .Servicecard1 p,
  .Servicecard p {
    font-size: 3.5rem;
    padding-inline: 1.5rem;
  }
  .Servicecard1 h5 {
    font-size: 6rem !important;
    margin-top: 0.8rem !important;
  }
  .Servicecard1front {
    margin-top: 6rem !important;
  }
  .Servicecard1fronticon svg {
    width: 120px !important;
    height: 120px !important;
  }
  .parase {
    font-size: 4.5rem;
    margin-top: 4rem;
    margin-bottom: 2rem !important;
  }
  .largetit {
    margin-top: 9rem !important;
    line-height: 40px !important;
    font-size: 7.5rem !important;
  }
  .marginRightforhowtos {
    margin-right: 0rem;
    margin-left: 5rem !important;
  }
  .imgboxgif {
    width: 100%;
    height: 80%;
    align-items: center;
  }
  .servicemargin {
    margin-top: 8rem !important;
  }
  .subpagestitle {
    margin-left: 3vw;
    font-size: 7rem;
  }
  .paragraphmargin {
    font-size: 4rem;
    height: 13rem;
  }
  .btnsergrid a {
    width: 6rem;
    height: 6rem;
    padding: 0.9rem;
  }
  #backbtnport {
    width: 4rem !important;
    height: 4rem !important;
  }
  #servicemenuciricon {
    width: 2rem !important;
    height: 2rem !important;
  }
}
@media (max-width: 4590px) and (min-width: 2591px) {
  .Servicecard {
    width: 38rem;
    height: 25rem;
    margin: 2rem;
  }
  .Servicecard h5 {
    padding-inline: 1rem;
    font-size: 2.2rem !important;
  }
  .Servicecard svg,
  .Servicecard1 svg {
    margin-inline: 1rem;
    font-size: 3.8rem !important;
  }
  .Servicecard1 {
    width: 96%;
    height: 12rem;
    margin-inline: auto;
  }
  .Servicecard1 p,
  .Servicecard p {
    font-size: 1.8rem;
    padding-inline: 1rem;
  }
  .Servicecard1 h5 {
    font-size: 3rem !important;
  }
  .Servicecard1front {
    margin-top: 2.8rem !important;
  }
  .Servicecard1fronticon svg {
    width: 50px !important;
    height: 50px !important;
  }
  .parase {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .largetit {
    margin-top: 4.5rem !important;
    line-height: 25px !important;
    font-size: 5rem !important;
  }
  .marginRightforhowtos {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .imgboxgif {
    width: 100%;
    height: 50%;
    align-items: center;
  }
}
@media (max-width: 2590px) and (min-width: 1989px) {
  .Servicecard {
    width: 30rem;
    height: 21rem;
  }
  .Servicecard h5 {
    padding-inline: 1rem;
    font-size: 1.8rem !important;
  }
  .Servicecard svg,
  .Servicecard1 svg {
    margin-inline: 1rem;
    font-size: 3.4rem !important;
  }
  .Servicecard1 {
    width: 29.5rem;
    height: 9.5rem;
  }
  .Servicecard1 p,
  .Servicecard p {
    font-size: 1.5rem;
    padding-inline: 1rem;
  }
  .Servicecard1 h5 {
    font-size: 2.6rem !important;
  }
  .Servicecard1front {
    margin-top: 1.5rem !important;
  }
  .Servicecard1fronticon svg {
    width: 45px !important;
    height: 45px !important;
  }
  .parase {
    font-size: 1.3rem;
    margin-top: 0.8rem;
  }
  .marginRightforhowtos {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .imgboxgif {
    width: 100%;
    height: 60%;
    align-items: center;
  }
}
@media (max-width: 1990px) and (min-width: 1789px) {
  .Servicecard {
    width: 26rem;
    height: 17rem;
  }
  .Servicecard h5 {
    padding-inline: 1rem;
    font-size: 1.6rem !important;
  }
  .Servicecard svg {
    margin-inline: 1rem;
    font-size: 3rem !important;
  }
  .Servicecard1 {
    width: 26rem;
    height: 7.8rem;
  }
  .Servicecard1 p,
  .Servicecard p {
    font-size: 1.2rem;
    padding-inline: 1rem;
  }
  .Servicecard1 h5 {
    font-size: 2.2rem !important;
  }
  .Servicecard1front {
    margin-top: 1.2rem !important;
  }
  .Servicecard1fronticon svg {
    width: 40px !important;
    height: 40px !important;
  }
  .parase {
    font-size: 1.3rem;
    margin-top: 0.6rem;
  }
  .marginRightforhowtos {
    margin-right: 0.1rem;
    margin-left: 0.1rem;
  }
  .imgboxgif {
    width: 100%;
    height: 60%;
    align-items: center;
  }
}
@media (max-width: 1790px) and (min-width: 1589px) {
  .Servicecard {
    width: 25rem;
    height: 16rem;
  }
  .Servicecard h5 {
    padding-inline: 1rem;
    font-size: 1.5rem !important;
  }
  .Servicecard svg {
    margin-inline: 1rem;
    font-size: 3rem !important;
  }
  .Servicecard1 {
    width: 24rem;
    height: 6rem;
  }
  .Servicecard1 p,
  .Servicecard p {
    font-size: 1rem;
    padding-inline: 0.6rem;
  }
  .Servicecard p {
    font-size: 1.1rem;
    padding-inline: 1rem;
  }
  .Servicecard1 h5 {
    font-size: 2rem;
    font-weight: 700;
  }
  .Servicecard1front {
    margin-top: 1rem !important;
  }
  .Servicecard1fronticon svg {
    width: 40px !important;
    height: 40px !important;
  }
  .marginRightforhowtos {
    margin-right: 0rem;
  }
  .Servicecard1front .rtre {
    margin-top: 0.5rem !important;
  }
  .imgboxgif {
    width: 100%;
    height: 60%;
    align-items: center;
  }
  .small-imgservice {
    width: 100px;
    height: 700px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .image-carousel-container {
    height: 540px;
    overflow: hidden;
    position: relative;
  }
}

@media (max-width: 1500px) {
  .about-para {
    height: calc(100vh - 40%) !important;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) and (max-height: 559px) and (min-height: 400px) {
  .about-para {
    height: calc(100vh - 40%) !important;
  }
  .imgboxgif {
    height: 70% !important;
  }
  .eVerificationText {
    font-size: 1.1rem;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) and (max-height: 759px) and (min-height: 560px) {
  .service-service-card{
    height: 25rem !important;
  }
  .btnsergridcontainer{
    margin-bottom: 0px !important;
  }
  .about-para {
    height: calc(100vh - 40%) !important;
  }
  .imgboxgif {
    height: 80vh !important;
  }
  .eVerificationText {
    font-size: 1.3rem;
  }
  .Reviewscroller {
    height: 38%;
  }
  .faqboxcontainer2 {
    height: 60vh !important;
  }
  .small-imgservice {
    width: 100px;
    height: 490px !important;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .image-carousel-container {
    height: 490px;
  }
  .Servicecard {
    width: 21rem;
    /* height: 15.9rem !important;
     */
     /* height: fit-content; */
  }
  .Servicecard p {
    font-size: 1.3rem;
    padding-inline: 0.8rem;
  }
  .Servicecard h5 {
    font-size: 1rem;
  }
}
.col-1 {
  flex: 0 0 auto;
  width: 4.333333% !important;
}
@media (max-width: 1590px) and (min-width: 1400px) and (max-height: 959px) and (min-height: 760px) {
  .imgboxgif {
    height: 100vh !important;
  }
  .eVerificationText {
    font-size: 1.2rem;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) and (max-height: 1059px) and (min-height: 960px) {
  .imgboxgif {
    height: 100vh !important;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) and (max-height: 1259px) and (min-height: 1060px) {
  .imgboxgif {
    height: 100vh !important;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) {

  .service-service-card{
    height: 13.2rem !important;
  }
  .Servicecard {
    width: 21rem;
    /* height: 12.9rem; */
    /* height:fit-content !important; */
  }
  .Servicecard h5 {
    padding-inline: 1rem;
    font-size: 1rem !important; 
  }
  .Servicecard svg {
    margin-inline: 1rem;
  }
  .Servicecard1front .rtre {
    margin-top: 1rem !important;
  }
  .Servicecard1 {
    width: 21rem;
    height: 5.8rem;
  }
  .Servicecard1 p,
  .Servicecard p {
    font-size: 0.9rem;
    padding-inline: 0.8rem;
  }
  .marginRightforhowtos {
    margin-right: 0rem;
  }
  .imgboxgif {
    width: 100%;
    height: 90%;
    align-items: center;
  }
  .small-imgservice {
    width: 100px;
    height: 450px;
    overflow: hidden;
    margin-bottom: 20px;
  }
}

@media (max-width: 1400px) {
  .about-para {
    height: calc(100vh - 61%) !important;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  @media (max-height:800px ) and (min-height:600px){

    .about-para {
      height: calc(100vh - 52.5%) !important;
  }
  }
  
}
@media (max-width: 1300px) and (min-width: 1200px) {
  /* .home-para{
        height:calc(100vh - 60%) !important;
    } */
  .Servicecard {
    width: 18rem;
    height: 9.2rem;
  }
  .Servicecard h5 {
    padding-inline: 0.5rem;
    font-size: 0.8rem;
  }
  .Servicecard svg {
    margin-inline: 0.5rem;
  }
  .Servicecard1 {
    width: 17rem;
    height: 4rem;
  }
  .Servicecard1 h5 {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .Servicecard1 p,
  .Servicecard p {
    font-size: 0.7rem;
    padding-inline: 0.5rem;
  }
  .Servicecard1fronticon svg {
    width: 25px !important;
    height: 25px !important;
  }
  .Servicecard1front {
    margin-top: 0rem !important;
  }
  .subpagestitle {
    font-size: 1.5rem;
    font-weight: 900;
  }
  .btnsergrid {
    width: 50%;
    height: 2.5rem;
  }
  @media (max-height: 799px) and (min-height: 600px) {
    .image-carousel-container {
      height: 440px;
      overflow: hidden;
      position: relative;
    }
  }
}
@media (max-width: 1199px) and (min-width: 1000px) {
  .Servicecard {
    width: 16.5rem;
    height: 8.5rem;
  }
  .Servicecard h5 {
    padding-inline: 0.3rem;
    font-size: 0.8rem;
  }
  .Servicecard svg {
    margin-inline: 0.3rem;
  }
  .Servicecard1 svg {
    font-size: 1.5rem !important;
  }
  .Servicecard1 h5 {
    padding-inline: 0.3rem;
    font-size: 1.3rem;
    margin-bottom: 0.2rem;
  }
  .Servicecard1 {
    width: 15rem;
    height: 3.7rem;
    margin-top: 0rem;
  }
  .Servicecard1 p,
  .Servicecard p {
    font-size: 0.6rem;
    padding-inline: 0.3rem;
  }
  .btnsergrid {
    width: 50%;
    height: 2rem;
  }
  .subpagestitle {
    font-size: 1.5rem;
    font-weight: 900;
  }
  .btnsergrid a {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.3rem;
    z-index: 1200;
  }
  .paragraphmargin {
    height: 1.2rem;
  }
  .Servicecard1front {
    margin-top: 0rem !important;
    margin-bottom: 1.5rem !important;
  }
  .Servicecard1fronticon svg {
    width: 24px !important;
    height: 24px !important;
  }
}
@media (max-width: 999px) and (min-width: 800px) {
  .Servicecard1 h5 {
    padding-inline: 0.3rem;
    font-size: 1.3rem;
    margin-bottom: 0.2rem;
  }
  .startSD h5 {
    font-size: 1.2rem;
  }
  .Servicecard1 {
    width: 15rem;
    height: 3.7rem;
    margin-top: 0rem;
  }
  .startSD {
    width: 14rem;
  }
  .Servicecard1 p,
  .Servicecard p {
    font-size: 0.6rem;
    padding-inline: 0.3rem;
  }
}

@media (max-width: 800px) {
  /* .home-para{
        height:calc(100vh - 32%) !important;
    } */
}
@media (max-width: 800px) and (min-width: 768px) {
  /* .home-para{
        height:calc(100vh - 25%) !important;
    } */
  .mySwiper {
    display: grid;
    grid-template-columns: auto auto;
  }
  .startSD {
    width: 12rem;
  }
  .startSD h5 {
    font-size: 1.2rem;
  }
  .startSD p {
    font-size: 0.6rem;
    padding-inline: 0.3rem;
  }
}
#mobilecenter {
  width: 60%;
}
@media (max-width: 675px) and (min-width: 476px) {
  /* .home-para{
        height:calc(100vh - 25%) !important;
    } */
  #mobilecenter {
    width: 100%;
  }
  .btnsergrid,
  .btncatgrid {
    height: 2.8rem;
    overflow-y: hidden;
    width: 90%;
  }
  .btnsergrid a,
  .btncatgrid a {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.4rem;
  }
  .btnsergrid::-webkit-scrollbar,
  .btncatgrid::-webkit-scrollbar {
    display: none;
  }
  .btnsergrid::-webkit-scrollbar-thumb,
  .btncatgrid::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .Servicecard1 {
    width: 16rem;
  }
  .startSD {
    width: 14rem;
  }
  .Servicecard1 h5 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .Servicecard1 p {
    font-size: 0.68rem;
  }
}
@media (max-width: 639px) and (min-width: 475px) {
  .headingGetStarted {
    bottom: 5% !important;
  }
  .startSD {
    width: 28rem;
    margin-inline: auto;
  }
}
/*mobile responsiveness*/

@media (max-width: 800px) {
  /* .home-para{
        height:calc(100vh - 25%) !important;
    } */
}

@media (max-width: 550px) {
  .desktopview-service {
    display: none !important;
  }
}

@media (max-width: 475px) and (min-width: 376px) {
  .swiper-menu-services {
    overflow-x: scroll !important;
  }

  .swiper-menu-services::-webkit-scrollbar {
    display: none;
  }
  .headingGetStarted {
    bottom: 7% !important;
  }
  .about-para {
    height: calc(100vh - 48%) !important;
  }
  .emp-verification-mob-heading {
    font-size: 1.6rem !important;
  }

  .fixedcontainer1mobile {
    display: flex;
    flex-wrap: wrap !important;
  }
  .mobileparrr1,
  .mobileparrr {
    width: 100% !important;
  }
  #mobilecenter {
    width: 100%;
  }
  .subpagestitle {
    font-size: 1.7rem;
    text-align: center;
  }

  .Servicecard1 {
    width: 22rem;
    height: 4.2rem;
  }
  .btnsergrid,
  .btncatgrid {
    height: 2.8rem;
    overflow-y: hidden;
    width: 100%;
  }
  .btnsergrid a,
  .btncatgrid a {
    width: 3rem;
    height: 3rem;
    /* padding: 0.3rem 0.4rem; */
  }
  .btnsergrid::-webkit-scrollbar,
  .btncatgrid::-webkit-scrollbar {
    display: none;
  }
  .btnsergrid::-webkit-scrollbar-thumb,
  .btncatgrid::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .btnsergridcontainer {
    margin-inline: 0.5rem;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .practicebox img #logo1 {
    display: none;
  }

  .practicebox:hover img #logo1 {
    display: block;
  }

  .practicebox:hover img #sampleImage {
    display: none;
  }
  @media (max-height: 1250px) and (min-height: 1101px) {
    .emp-verification-mob-heading {
      font-size: 1.6rem !important;
    }
    .Servicecard {
      height: 33.4vh;
      width: 24rem;
    }
    .subpagestitle {
      font-size: 1.8rem !important;
      line-height: 40px !important;
    }
    .Servicecard h5 {
      font-size: 5.6vw;
    }
    .Servicecard p {
      height: 8rem;
      font-size: 2.2vh;
    }
    .paragraphmargin {
      height: 5rem !important;
    }
    .btnsergridcontainer {
      margin-top: 0.8rem;
      margin-inline: 1rem;
    }
    .Servicecard1 {
      width: 24rem;
      height: 6.2rem;
    }
    .Servicecard1 p {
      font-size: 1.1rem;
      padding-inline: 0.5rem;
    }
    .Servicecard1front {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .faqboxcontainer2 {
      height: 35rem !important;
    }
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
  }
  @media (max-height: 1100px) and (min-height: 901px) {
    .emp-verification-mob-heading {
      font-size: 1.6rem !important;
    }
    .mobileparrr,
    .mobileparrr1 {
      width: 100% !important;
    }
    .Servicecard {
      height: 30.4vh;
      width: 24rem;
    }
    .subpagestitle {
      font-size: 1.8rem !important;
      line-height: 40px !important;
    }
    .Servicecard h5 {
      font-size: 5.6vw;
    }
    .Servicecard p {
      height: 8rem;
      font-size: 2.2vh;
    }
    .paragraphmargin {
      height: 5rem !important;
    }
    .btnsergridcontainer {
      margin-top: 0.8rem;
      margin-inline: 1rem;
    }
    .Servicecard1 {
      width: 24rem;
      height: 6.2rem;
    }
    .Servicecard1 p {
      font-size: 1.1rem;
      padding-inline: 0.5rem;
    }
    .Servicecard1front {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .faqboxcontainer2 {
      height: 35rem !important;
    }
  }
  @media (max-height: 900px) and (min-height: 801px) {
    .mobileparrr,
    .mobileparrr1 {
      width: 100% !important;
    }
    .Servicecard {
      height: 36.4vh;
      width: 23rem;
    }
    .subpagestitle {
      font-size: 1.5rem !important;
      line-height: 42px !important;
    }
    .Servicecard p {
      height: 8rem;
      font-size: 1.25rem;
    }
    .Servicecard h5 {
      font-size: 1.5rem;
    }
    .fixedcontainer1 {
      bottom: 9%;
    }
    .faqboxcontainer2 {
      height: 25rem !important;
    }
    .servicecardbox_icon {
      height: 60px !important;
      width: 60px !important;
    }
  }
  @media (max-height: 800px) and (min-height: 701px) {
    .Servicecard {
      height: 31vh;
      width: 21.5rem;
    }
    .subpagestitle {
      font-size: 1.5rem !important;
      line-height: 29px !important;
    }
    .Servicecard p {
      height: 8rem;
      font-size: 1rem;
    }
    .Servicecard h5 {
      font-size: 1.2rem;
    }
    .fixedcontainer1 {
      bottom: 9%;
    }
    .faqboxcontainer2 {
      height: 20rem !important;
    }
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
  }
  @media (max-height: 700px) and (min-height: 601px) {
    .Servicecard {
      height: 30.4vh;
      width: 22rem;
    }
    .subpagestitle {
      font-size: 1.8rem !important;
      line-height: 29px !important;
    }
    .Servicecard p {
      height: 8rem;
      font-size: 0.9rem;
    }
    .Servicecard h5 {
      font-size: 1.2rem;
    }
    .fixedcontainer1 {
      bottom: 9%;
    }
    .faqboxcontainer2 {
      height: 20rem !important;
    }
    .paragraphmargin {
      height: 3rem !important;
      padding-inline: 1rem !important;
      font-size: 1rem;
      margin-bottom: 0.5rem !important;
    }
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
  }
}
@media (max-width: 486px) and (min-width: 385px) {
  .headingGetStarted {
    bottom: 7% !important;
  }
  .emp-verification-mob-heading {
    font-size: 1.6rem !important;
  }
  .service-detail-buttons {
    margin-bottom: 2.1rem;
    gap: 1.5rem !important;
  }

  .button-service-question {
    width: 210px;
  }
  .blur-container {
    margin-bottom: 1.3rem !important;
  }
  .mobileparrr,
  .mobileparrr1 {
    width: 100% !important;
  }
  .fixedcontainer1mobile {
    display: flex;
    flex-wrap: wrap !important;
  }
}

.mobileparrr {
  background-color: rgba(209, 210, 211, 0.39);
  color: #fff;
  width: 24rem;
  margin-inline: auto;
  padding: 10px 5px;
  border-radius: 12px;
}
.mobileparrr1 {
  background-color: rgba(209, 210, 211, 0.39);
  color: #fff;
  width: 20rem;
  margin-inline: auto;
  padding: 10px 5px;
  border-radius: 12px;
}

@media (max-width: 375px) and (min-width: 321px) {

  .service-detail-buttons {
   bottom: 9% !important;
  }

  .headingGetStarted {
    bottom: 9% !important;
  }
  .emp-verification-mob-heading {
    font-size: 1.6rem !important;
  }
  .fixedcontainer1mobile {
    display: flex;
    flex-wrap: wrap !important;
  }
  .mobileparrr1,
  .mobileparrr {
    width: 100% !important;
  }
  #mobilecenter {
    width: 100%;
  }
  .subpagestitle {
    font-size: 1.8rem;
    text-align: center;
  }
  .Servicecard {
    height: 9rem;
  }
  .Servicecard p {
    font-size: 0.75rem;
  }
  .Servicecard1 {
    width: 20rem;
    height: 4.2rem;
  }
  .startSD {
    width: 18rem !important;
  }
  .btnsergrid {
    height: 2.8rem;
    overflow-y: hidden;
    width: 100%;
  }
  .btncatgrid {
    height: 4rem;
    overflow-y: hidden;
    grid-template-columns: auto auto auto auto auto;
  }
  .btnsergrid a,
  .btncatgrid a {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.4rem;
  }
  .btnsergrid::-webkit-scrollbar,
  .btncatgrid::-webkit-scrollbar {
    display: none;
  }
  .btnsergrid::-webkit-scrollbar-thumb,
  .btncatgrid::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .btnsergridcontainer {
    margin-inline: 0.5rem;
  }
  @media (max-height: 1200px) and (min-height: 901px) {
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
    .Servicecard {
      height: 35.4vh;
    }
    .subpagestitle {
      font-size: 1.8rem !important;
      line-height: 40px !important;
    }
    .Servicecard h5 {
      font-size: 1.6rem;
    }
    .Servicecard p {
      height: 8rem;
      font-size: 1.3rem;
    }
    .paragraphmargin {
      height: 5rem !important;
    }
    .btnsergridcontainer {
      margin-top: 1rem;
    }
    .faqboxcontainer2 {
      height: 35rem !important;
    }
  }
  @media (max-height: 900px) and (min-height: 801px) {
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
    .Servicecard {
      height: 35.4vh;
    }
    .subpagestitle {
      font-size: 1.8rem !important;
      line-height: 29px !important;
      text-align: center;
    }
    .Servicecard h5 {
      font-size: 1.4rem;
    }
    .Servicecard p {
      height: 8rem;
      font-size: 1rem;
    }
    #mobilecenter {
      display: flex;
      flex-wrap: wrap;
    }
    .fixedcontainer1 {
      bottom: 7%;
    }
    .faqboxcontainer2 {
      height: 19rem !important;
    }
  }
  @media (max-height: 800px) and (min-height: 701px) {
    .emp-verification-mob-heading {
      font-size: 1.6rem !important;
    }
    .Servicecard {
      height: 35.4vh;
    }
    .subpagestitle {
      font-size: 1.7rem !important;
      line-height: 29px !important;
    }
    .Servicecard h5 {
      font-size: 1.4rem;
    }
    .Servicecard p {
      height: 8rem;
      font-size: 1rem;
    }
    #mobilecenter {
      display: flex;
      flex-wrap: wrap;
    }
    .fixedcontainer1 {
      bottom: 7%;
    }
    .faqboxcontainer2 {
      height: calc(100vh - 387px) !important;
    }
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
  }
  @media (min-height: 601px) and (max-height: 700px) {
    .Servicecard {
      height: 29vh;
    }
    .Servicecard p {
      font-size: 0.85rem;
    }
    .faqboxcontainer2 {
      height: 16rem !important;
    }
    .startSD {
      width: 18rem !important;
    }
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
  }
  @media (max-height: 600px) {
    .subpagestitle {
      font-size: 1.8rem !important;
    }
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
  }
}
@media (width: 375px) and (height: 667px) {
  .moveleftcontent{

    height: 21rem !important;

  }

  /* .about-para {
    height: calc(100vh - 48%) !important;
  } */
  .mobileparrr,
  .mobileparrr1 {
    width: 100% !important;
  }

}
@media (max-width: 320px) and (min-width: 200px) {
  .headingGetStarted {
    bottom: 5% !important;
  }
  .fixedcontainer1mobile {
    display: flex;
    flex-wrap: wrap !important;
  }
  #mobilecenter {
    width: 100%;
  }
  .subpagestitle {
    font-size: 1.5rem;
  }
  .Servicecard {
    width: 18rem;
    height: 8.4rem;
  }
  .Servicecard h5 {
    font-size: 0.9rem;
  }
  .Servicecard p {
    height: 5rem;
    font-size: 0.65rem;
  }
  .Servicecard1 {
    width: 18rem;
    height: 4rem;
    margin: 0rem;
  }
  .Servicecard1back p {
    font-size: 0.7rem;
  }
  .btnsergrid {
    height: 2.8rem;
    overflow-y: hidden;
    width: 100%;
  }
  .btncatgrid {
    height: 6rem;
    overflow-y: hidden;
    grid-template-columns: auto auto auto auto auto;
  }
  .btnsergrid a,
  .btncatgrid a {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.4rem;
  }
  .btnsergrid::-webkit-scrollbar,
  .btncatgrid::-webkit-scrollbar {
    display: none;
  }
  .btnsergrid::-webkit-scrollbar-thumb,
  .btncatgrid::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .mobileparrr1,
  .mobileparrr {
    width: 100% !important;
  }
  @media (max-height: 1200px) and (min-height: 901px) {
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
    .subpagestitle {
      font-size: 1.5rem !important;
      line-height: 34px !important;
    }
    .Servicecard {
      width: 18rem;
      height: 30.4vh;
    }
    .Servicecard h5 {
      font-size: 1.4rem;
    }
    .Servicecard p {
      height: 6rem;
      font-size: 1rem;
    }
    .fixedcontainer1 {
      position: fixed;
      bottom: 8%;
      width: 100% !important;
    }
    .btnsergridcontainer {
      margin-top: 0.9rem;
    }
    .paragraphmargin {
      height: 5rem !important;
    }
    .Servicecard1 {
      width: 18rem;
      height: 8rem;
      margin: 0rem;
    }
    .Servicecard1back p {
      font-size: 1rem;
    }
    .Servicecard1front {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    #mobilecenter {
      display: flex;
      flex-wrap: wrap;
    }
    .faqboxcontainer1,
    .faqboxcontainer2 {
      height: 31rem !important;
    }
  }
  @media (max-height: 900px) and (min-height: 601px) {
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
    .subpagestitle {
      font-size: 1.5rem !important;
      line-height: 30px !important;
      text-align: center;
    }
    .Servicecard {
      width: 18rem;
      height: 30.4vh;
    }
    .Servicecard h5 {
      font-size: 1.2rem;
    }
    .Servicecard p {
      height: 6rem;
      font-size: 0.8rem;
    }
    .fixedcontainer1 {
      position: fixed;
      bottom: 9%;
      width: 100% !important;
    }
    .btnsergridcontainer {
      margin-top: 0.9rem;
    }
    #mobilecenter {
      display: flex;
      flex-wrap: wrap;
    }
  }
  @media (max-height: 600px) {
    .subpagestitle {
      font-size: 1.2rem !important;
    }
    .Servicecard {
      width: 18rem;
      height: 9rem;
    }
    .fixedcontainer1 {
      position: fixed;
      bottom: 10%;
      width: 100% !important;
    }
    .mobileparrr1,
    .mobileparrr {
      width: 100% !important;
    }
  }
}

.service-title {
  content: "Strategic Social Media Marketing and Management";
}

@media screen and (max-width: 550px) {
  .service-title {
    content: "Social Media Marketing and Management";
  }
}
