.fullscreen-video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 19999;
  }
  
  .fullscreen-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }