.contactcontainer {
  width: 100%;
}
.contactinput {
  width: 100%;
  height: 2.5rem;
  outline: none;
  border: 1px solid #fff;
  padding: 12px;
  border-radius: 25px;
  background-color: #2b2b2b00;
  color: #fff;
  margin: 0.5rem 0rem;
}
.contactinput::placeholder,
textarea::placeholder {
  color: #fff; /* Placeholder color */
  opacity: 1; /* Ensure the placeholder is fully opaque */
}
.css-m6dlzq-InputComponent::placeholder {
  color: #fff !important;
}

#sucessimagemodelbox {
  width: 8rem;
  height: 8rem;
}
#contacttextarea {
  width: 100%;
  height: 3.5rem;
  outline: none;
  border: 1px solid #fff;
  padding: 12px;
  border-radius: 10px;
  background-color: #2b2b2b00;
  color: #fff;
  margin: 0.5rem 0rem;
}
.contactDropdown {
  height: auto;
  outline: none !important;
  border: 1px solid #fff !important;
  padding: 12px !important;
  border-radius: 25px !important;
  background: #2b2b2b00 !important;
  color: #fff !important;
  margin: 0.5rem 0rem !important;
}
.contactDropdown option {
  background: #000 !important;
}
.react-dropdown-select-dropdown {
  background: rgb(0, 0, 0) !important;
  text-align: left !important;
}
.react-dropdown-select-item-selected,
.react-dropdown-select-option {
  background: #ff6600 !important;
  border: none !important;
}
.react-dropdown-select-item {
  border: none !important;
}
/* .css-wmy1p7-ReactDropdownSelect,.css-41mudl-DropDown{
    height: 2.5rem;
    outline:  none !important;
    border:1px solid #fff !important;
    padding: 12px !important;
    border-radius: 25px !important;
    background:#2b2b2b00 !important;
    color: #fff !important;
    margin: 0.5rem 0rem !important;
}
.css-z2hyoe-ItemComponent.react-dropdown-select-item-selected{
    background:#ff6600 !important;
}
.css-z2hyoe-ItemComponent,.css-8ghj7u-DropDown{
    background:#ffffff0b !important;
    color: #fff;
}
.css-z2hyoe-ItemComponent:hover{
    background: rgba(0, 0, 0, 0.1) !important;
} */
.contactscrollerpara {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0.5rem 1rem;
  height: 23rem;
  outline: none;
  /* Optional: Smooth scrolling experience */
  scroll-behavior: smooth;
  position: relative;
  z-index: 4;
}
.fixedcontainer1 {
  position: fixed;
  bottom: 8%;
  left: 0;
  width: 60%;
  z-index: 1000;
  background-color: #ffffff00;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.contactscrollerpara h4 {
  color: rgba(254, 136, 0, 255);
  font-weight: 700;
}
.contactscrollerpara::-webkit-scrollbar {
  width: 0em;
}

.contactscrollerpara::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.btnctngrid {
  width: 100%;
  height: 6rem;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
  justify-content: left;
  align-items: left;
  margin-inline: auto;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.btnctngrid a,
.bbcc {
  width: 3rem;
  height: 3rem;
  background-color: #3333334d;
  padding: 0.5rem 0.6rem;
  border-radius: 50%;
}
.w-md-50 {
  width: 50%;
}
.moveleftcontent {
  margin-left: 4rem;
}
@media screen and (min-width: 4591px) {
  .contactscrollerpara {
    height: 100.5rem;
  }
  .contactscrollerpara p {
    font-size: 3.5rem;
  }
  .mailiconwidth {
    width: 90px !important;
    height: 90px !important;
    margin-top: -0.5rem !important;
  }
  .contactinput {
    height: 6.5rem;
    margin: 1.5rem 0rem;
  }
  #contacttextarea {
    width: 100%;
    height: 10.5rem;
    margin: 1.5rem 0rem;
  }
  .subpagestitle {
    font-size: 4rem;
  }
}
@media (max-width: 4590px) and (min-width: 2591px) {
  .contactscrollerpara {
    height: 56.5rem;
  }
  .contactscrollerpara p {
    font-size: 2.2rem;
  }
  .mailiconwidth {
    width: 50px !important;
    height: 50px !important;
    margin-top: -0.5rem !important;
  }
}
@media (max-width: 2590px) and (min-width: 1989px) {
  .contactscrollerpara {
    height: 39.5rem;
  }
  .contactscrollerpara p {
    font-size: 1.5rem;
  }
  .mailiconwidth {
    width: 50px !important;
    height: 50px !important;
    margin-top: -0.5rem !important;
  }
}
@media (max-width: 1990px) and (min-width: 1789px) {
  .contactscrollerpara {
    height: 34.5rem;
  }
}
@media (max-width: 1790px) and (min-width: 1589px) {
  .contactscrollerpara {
    height: 32.5rem;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) and (max-height: 759px) and (min-height: 560px) {
  .btnctngrid {
    height: 6rem;
  }
  .contactscrollerpara {
    height: 31rem !important;
  }
}
@media (max-width: 1590px) and (min-width: 1400px) {
  .contactscrollerpara {
    height: 33rem;
  }
}
@media (max-width: 1300px) and (min-width: 1200px) {
  .contactscrollerpara {
    height: 20.9rem;
  }
  .mailiconwidth {
    margin-top: -0.3rem;
  }
}
@media (max-width: 1199px) and (min-width: 1000px) {
  .portfolioboxcontainer {
    height: 12.5rem;
    padding-inline: 2rem;
  }
}
@media (max-width: 1260px) and (min-width: 1160px) {
  .btnctngrid {
    width: 100%;
    height: 3rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    gap: 0.7rem;
    margin-top: 0.5rem;
  }
  .btnctngrid a,
  .bbcc {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #3333334d;
    padding: 0.3rem 0.35rem;
    border-radius: 50%;
  }
  .contactscrollerpara {
    height: 20.5rem;
  }
  .mailiconwidth {
    margin-top: -0.3rem !important;
  }
}
@media (max-width: 1159px) and (min-width: 956px) {
  .btnctngrid {
    width: 100%;
    height: 5.5rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  .btnctngrid a {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #3333334d;
    padding: 0.3rem 0.35rem;
    border-radius: 50%;
  }
  .contactscrollerpara {
    height: 16.9rem;
  }
  .mailiconwidth {
    margin-top: -0.3rem !important;
  }
}
@media (max-width: 958px) and (min-width: 856px) {
  .btnctngrid {
    width: 100%;
    height: 5.5rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
  }
  .btnctngrid a {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #3333334d;
    padding: 0.3rem 0.35rem;
    border-radius: 50%;
  }
}
@media (max-width: 858px) and (min-width: 756px) {
  .btnctngrid {
    width: 100%;
    height: 5.5rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
  }
  .btnctngrid a {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #3333334d;
    padding: 0.3rem 0.35rem;
    border-radius: 50%;
  }
}
@media (max-width: 775px) and (min-width: 676px) {
  .contactscrollerpara {
    height: 16.5rem;
  }
  .btnctngrid {
    height: 2.8rem;
    width: 90%;
  }
  .btnctngrid a {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.4rem;
  }
  .btnctngrid::-webkit-scrollbar {
    display: none;
  }
  .btnctngrid::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .contactscrollerpara {
    height: 17.5rem;
  }
}
@media (max-width: 675px) and (min-width: 476px) {
  .moveleftcontent {
    margin-left: 0rem;
    padding: 1rem;
  }
  .contactscrollerpara {
    height: 25.5rem;
  }
  .btnctngrid {
    height: 2.8rem;
    overflow-y: hidden;
    width: 90%;
  }
  .btnctngrid a {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.4rem;
  }
  .btnctngrid::-webkit-scrollbar {
    display: none;
  }
  .btnctngrid::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .contactscrollerpara {
    height: 27.5rem;
  }
}

@media screen and (max-width: 550px) {
  .desktopview,
  .contact-desktopview {
    display: none !important;
  }
}
@media (max-width: 475px) and (min-width: 376px) {
  .desktopview-contact {
    display: none !important;
  }
  .services-list-conatct-mobile {
    /* width: 19.6rem !important; */
    width: 100%;
  }
  .contact-services {
    font-size: 4.3vw !important;
  }
  .moveleftcontent {
    margin-left: 0rem;
    padding: 1rem;
  }
  .btnctngrid {
    height: 3rem;
    overflow-y: hidden;
    width: 95%;
  }
  .btnctngrid a {
    width: 3rem;
    height: 3rem;
    padding: 0.3rem 0.4rem;
  }
  .btnctngrid::-webkit-scrollbar {
    display: none;
  }
  .btnctngrid::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .contactscrollerpara {
    height: 29.5rem !important;
  }
  .w-md-50 {
    width: 100%;
  }
  .mailiconwidth {
    margin-left: 27vw !important;
  }
}

@media (max-width: 475px) {
  .contact-icon-mob {
    /* display: flex;
        align-items:center; */
    /* position: absolute; */
    height: 35px !important;
    /* bottom: 7%; */
  }

  .contact-icon-mob span small {
    position: relative;
    height: 40px !important;
    display: flex;
    align-items: center;
  }

  .contactscrollerpara {
    height: 30.5rem !important;
  }

  .aboutmcenter {
    margin-top: 0rem !important;
  }
  .about-btns {
    gap: 1rem !important;
  }
}
@media (max-height: 1200px) and (min-height: 901px) and (max-width: 475px) and (min-width: 376px) {
  .contactscrollerpara {
    height: 41.5rem;
  }
}
@media (max-height: 900px) and (min-height: 801px) and (max-width: 475px) and (min-width: 376px) {
  .contactscrollerpara {
    height: 33rem;
  }
}
@media (max-height: 800px) and (min-height: 601px) and (max-width: 475px) and (min-width: 376px) {
  .contactscrollerpara {
    height: 24rem;
  }
}
@media (max-height: 600px) and (max-width: 475px) and (min-width: 376px) {
  .contactscrollerpara {
    height: 16.5rem;
  }
}

@media (max-width: 375px) and (min-width: 321px) {
  .moveleftcontent {
    margin-left: 0rem;
    padding: 1rem;
  }
  .btnctngrid {
    height: 2.8rem;
    overflow-y: hidden;
    width: 90%;
  }
  .btnctngrid a {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.4rem;
  }
  .btnctngrid::-webkit-scrollbar {
    display: none;
  }
  .btnctngrid::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .contactscrollerpara {
    height: 22.5rem;
  }
  .w-md-50 {
    width: 100%;
  }
}
@media (max-height: 1200px) and (min-height: 901px) and (max-width: 375px) and (min-width: 321px) {
  .contactscrollerpara {
    height: 38.5rem;
  }
}
@media (max-height: 900px) and (min-height: 601px) and (max-width: 375px) and (min-width: 321px) {
  .contactscrollerpara {
    height: 27rem !important;
  }
}
@media (max-height: 600px) and (max-width: 375px) and (min-width: 321px) {
  
  .contactscrollerpara {
    height: 16.5rem;
  }

  .moveleftcontent{

    height: 21rem !important;

  }
}

@media(max-width: 375px) and (min-width: 321px) {
  
  .contactscrollerpara {
    height: 16.5rem;
  }

  .moveleftcontent{

    height: 15rem !important;

  }

  #aboutrightimm img{
    display: none !important;

  }
}


@media (max-width: 320px) and (min-width: 200px) {
  .btnctngrid {
    height: 2.8rem;
    overflow-y: hidden;
    width: 90%;
  }
  .btnctngrid a {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.4rem;
  }
  .btnctngrid::-webkit-scrollbar {
    display: none;
  }
  .btnctngrid::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .contactscrollerpara {
    height: 21.5rem;
  }
  .w-md-50 {
    width: 100%;
  }
  .moveleftcontent{

    height: 21rem !important;

  }
}
@media (max-height: 1200px) and (min-height: 901px) and (max-width: 320px) and (min-width: 200px) {
  .contactscrollerpara {
    height: 34.5rem;
  }
}
@media (max-height: 900px) and (min-height: 601px) and (max-width: 320px) and (min-width: 200px) {
  .contactscrollerpara {
    height: 20rem;
  }
}
@media (max-height: 600px) and (max-width: 320px) and (min-width: 200px) {
  .contactscrollerpara {
    height: 12.5rem;
  }
  .moveleftcontent{

    height: 21rem !important;

  }
}
