.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.modal-content {
  position: relative;
  background-color: #333;
  border-radius: 12px;
  padding: 20px;
  max-width: 50%;
  max-height: 70vh;
  overflow-y: auto;
  animation: slideUp 0.3s ease;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
}

.modal-close:hover {
  color: #fd7e14;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.form-control[readonly]{
    background-color: transparent !important;

}

/* Scrollbar styling */
.modal-content::-webkit-scrollbar {
  width: 6px;
}

.modal-content::-webkit-scrollbar-track {
  background: transparent;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #fd7e14;
  border-radius: 3px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .modal-content {
    max-width: 80% !important;
    padding: 15px;
  }
}

@media (max-width: 550px) {

    .modal-content {
        max-width: 95% !important;
        padding: 15px;
      }

}
